// The number of tokens to display on the table on each page
export const MAX_AMOUNT_OF_TOKENS_FOR_PAGE_DISPLAY = 10;

// The default (max) pagination limit for /getWalletsTokenBalancesWithPrices
export const DEFAULT_PAGINATION_LIMIT_WALLET_TOKEN_BALANCES = 300;

// The usd value threshold after which the token usd value should be displayed in compact format
export const USD_VALUE_COMPACT_FORMAT_THRESHOLD = 10_000_000_000;

// Refresh interval in ms for SWR data fetching (1 minute)
export const SWR_DATA_REFRESH_INTERVAL = 1_000 * 60;

// Data cached in localstorage cleanup interval in ms (10 minutes)
export const CACHE_DATA_TEN_MINUTES_TTL = 1_000 * 60 * 10;

// Data cached in localstorage cleanup interval in ms (1 day)
export const CACHE_DATA_ONE_DAY_TTL = 1_000 * 60 * 60 * 24;

// The default (max) pagination limit for portfolio data
export const DEFAULT_PORTFOLIO_PAGINATION_LIMIT = 25;

// A timeout in ms that defines a slow API requests
export const API_REQUEST_SLOW_TIMEOUT = 50;
