import { INotificationData } from '@/services/index';

export const findNotificationData = (protocolType: string, notificationData?: INotificationData[]) => {
  return notificationData?.find((notification) => notification.type === protocolType)?.data;
};

export const normalizeTelegramUsername = (rawUsername?: string) => {
  if (!rawUsername) return;
  return rawUsername.substring(rawUsername.indexOf('_') + 1);
};
