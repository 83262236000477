import useSWR from 'swr';
import { GetWalletsNetWorthResponseDto } from '@/services/index';
import { api } from '@/utils/api';
import { logApp } from '@/utils/logApp';
import { CHAIN_ID_MAP } from '@/utils/supportedChains';
import { chainOptions } from '../utils/chainSelectorOptions';

const log = logApp.create('useWalletNetWorth');

const fetchSearchQuery = async ([, params]: [string, useWalletNetWorthProps]) => {
  if (!params.address || !params.chainIds) {
    return undefined;
  }
  const chainIds = chainOptions
    .filter((chain) => chain.id !== CHAIN_ID_MAP.pulse && chain.id !== CHAIN_ID_MAP.solana)
    .map((chain) => chain.id);
  const addressesOrNames = [params.address];
  try {
    return (await api.web3.web3ControllerGetWalletsNetWorth({
      chainIds,
      addressesOrNames,
    })) as unknown as GetWalletsNetWorthResponseDto;
  } catch (error) {
    log.warnError('[fetchSearchQuery] Error fetching wallets net worth', error);
    return {
      total_networth_usd: '0',
      chains: [],
      wallets: [],
    } as GetWalletsNetWorthResponseDto;
  }
};

interface useWalletNetWorthProps {
  address?: string;
  chainIds?: string[];
}

export const useWalletNetWorth = (params: useWalletNetWorthProps) => {
  const { data, isValidating, isLoading } = useSWR(['wallet-net-worth', params], fetchSearchQuery, {
    revalidateOnFocus: false,
  });
  return { data, isFetching: isLoading || isValidating };
};
