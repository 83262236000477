/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Plan } from '../models/Plan';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class PlansService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @returns Plan
   * @throws ApiError
   */
  public planControllerListfilterPlans(): CancelablePromise<Plan> {
    return this.httpRequest.request({
      method: 'GET',
      url: 'billingCoordinator/plans',
    });
  }
}
