/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ElasticBody } from '../models/ElasticBody';
import type { GetSearchCoins } from '../models/GetSearchCoins';
import type { GetSearchCoinsResponseDto } from '../models/GetSearchCoinsResponseDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class CoinSearchService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @deprecated
   * Search coins
   * @param query
   * @param chainId
   * @returns ElasticBody
   * @throws ApiError
   */
  public getV1SearchCoins(query: string, chainId?: string): CancelablePromise<ElasticBody> {
    return this.httpRequest.request({
      method: 'GET',
      url: 'insightsRepoSearchService/coin-search/v1/search',
      query: {
        query: query,
        chainId: chainId,
      },
    });
  }

  /**
   * Search coins
   * @param query
   * @param chainId
   * @returns GetSearchCoinsResponseDto
   * @throws ApiError
   */
  public getSearchCoins(query: string, chainId?: string): CancelablePromise<GetSearchCoinsResponseDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: 'insightsRepoSearchService/coin-search/search',
      query: {
        query: query,
        chainId: chainId,
      },
    });
  }

  /**
   * Search coins
   * @param requestBody
   * @returns GetSearchCoinsResponseDto
   * @throws ApiError
   */
  public getSearchCoins1(requestBody: GetSearchCoins): CancelablePromise<GetSearchCoinsResponseDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: 'insightsRepoSearchService/coin-search/search',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Query coins
   * Used for debugging and comparing with the scored query
   * can be removed later
   * @param query
   * @returns any
   * @throws ApiError
   */
  public getQueryUnscored(query: string): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: 'insightsRepoSearchService/coin-search/search-unscored',
      query: {
        query: query,
      },
    });
  }
}
