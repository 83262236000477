/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type CreateReviewRequestDto = {
  chainId: string;
  address?: string;
  name?: string;
  symbol?: string;
  type: CreateReviewRequestDto.type;
  rating: number;
};

export namespace CreateReviewRequestDto {
  export enum type {
    TOKEN = 'token',
    CHAIN = 'chain',
  }
}
