import { ERoutePath } from '@/utils/ERoutePath';
import { EPortfolioView } from './types';

const ROOT = ERoutePath.portfolio;
const getPath = (address: string, tabId: EPortfolioView) => [ROOT, address, tabId].join('/');
export const portfolioURL = {
  root: ROOT,
  defaultPath: (address?: string) => (!address ? ROOT : portfolioURL.tokens(address)),
  tokens: (address: string) => getPath(address, EPortfolioView.TOKENS),
  history: (address: string) => getPath(address, EPortfolioView.HISTORY),
  nfts: (address: string) => getPath(address, EPortfolioView.NFTS),
  nftCollections: (address: string, opts?: Array<string>) => {
    const path = getPath(address, EPortfolioView.NFTS_COLLECTIONS);
    return opts && opts.length ? `${path}/${opts.join('/')}` : path;
  },
  nftDetails: (address: string, chain: string, nft: string, tokenId: string) =>
    getPath(address, EPortfolioView.NFT).concat([ERoutePath.nftDetails, chain, nft, tokenId].join('/')),
  byView: (address: string, tabId: EPortfolioView) =>
    getPath(address, tabId === EPortfolioView.NFT_DETAILS ? EPortfolioView.NFTS : tabId),
};

const getAggregatePath = (list: string, tabId: EPortfolioView) =>
  [ROOT, EPortfolioView.AGGREGATE, list, tabId].join('/');
export const portfolioAggregateURL = {
  byView: (list: string, tabId: EPortfolioView) => getAggregatePath(list, tabId),
  tokens: (list: string) => getAggregatePath(list, EPortfolioView.TOKENS),
  nftCollections: (list: string) => getAggregatePath(list, EPortfolioView.NFTS_COLLECTIONS),
};
