import { ButtonBase } from '@/components/core/Button/ButtonBase';
import styles from './styles.module.scss';
import typography from '@/styles/scss/typography.module.scss';

interface IAlertTypeCardProps {
  description: string;
  disabled?: boolean;
  icon: React.ReactNode;
  name: string;
  onClick: () => void;
}

const AlertTypeCard = ({ icon, name, description, onClick, disabled }: IAlertTypeCardProps) => {
  return (
    <ButtonBase className={styles.wrapper} disabled={disabled} onClick={onClick}>
      {icon}
      <div className={styles.text}>
        <h5 className={typography.h5}>{name}</h5>
        <h5 className={styles.description}>{description}</h5>
      </div>
    </ButtonBase>
  );
};

export default AlertTypeCard;
