import { logApp } from '@/utils/logApp';
import { ApolloClient, ApolloLink, from, HttpLink, InMemoryCache } from '@apollo/client';

let client: ApolloClient<unknown> | undefined;

const graphqlEndpoint = 'https://r2d2content.moralis.com/graphql';

const log = logApp.create('ApolloClient', { endpoint: graphqlEndpoint });

function createApolloClient(authToken?: string): ApolloClient<unknown> {
  const httpLink = new HttpLink({ uri: graphqlEndpoint });

  const authMiddleware = new ApolloLink((operation, forward) => {
    if (authToken) {
      operation.setContext({
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
    }

    return forward(operation).map((response) => {
      if (response.errors) {
        response.errors.forEach((error) => {
          log.error('[authMiddleware]', error, { operation });
        });
      }
      return response;
    });
  });

  const link = from([authMiddleware, httpLink]);

  return new ApolloClient({
    link,
    cache: new InMemoryCache(),
    ssrMode: true,
  });
}

export function getApolloClient(authToken?: string): ApolloClient<unknown> {
  if (!client || authToken !== undefined) {
    client = createApolloClient(authToken);
  }
  return client;
}
