import { useAlertModal } from '@/helpers/hooks/useAlertModal';
import useUserStore from '@/stores/userStore';
import { faBarsFilter, faHexagon, faWallet } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AlertTypeCard } from '../../atoms';
import { language } from './values';
import styles from './styles.module.scss';

const SelectAlertTypeCards = () => {
  const allowedAlertTypes = useUserStore((state) => state?.stage?.allowedAlertTypes);
  const { open } = useAlertModal();

  return (
    <div className={styles.wrapper}>
      <AlertTypeCard
        description={language.en.strategy.description}
        disabled={!allowedAlertTypes?.strategy}
        icon={<FontAwesomeIcon className={styles.strategy_icon} icon={faBarsFilter} style={{ height: '40px' }} />}
        name={language.en.strategy.title}
        onClick={() => open.strategy()}
      />
      <AlertTypeCard
        description={language.en.token.description}
        disabled={!allowedAlertTypes?.token}
        icon={<FontAwesomeIcon className={styles.token_icon} icon={faHexagon} style={{ height: '40px' }} />}
        name={language.en.token.title}
        onClick={() => open.token()}
      />
      <AlertTypeCard
        description={language.en.bundle.description}
        disabled={!allowedAlertTypes?.bundle}
        icon={<FontAwesomeIcon className={styles.wallet_icon} icon={faWallet} style={{ height: '40px' }} />}
        name={language.en.bundle.title}
        onClick={() => open.bundle()}
      />
    </div>
  );
};

export default SelectAlertTypeCards;
