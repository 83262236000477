import { useState } from 'react';

export enum IntervalFilterType {
  Buyer = 'buyerValue',
  BuyersVolume = 'buyersVolumeValue',
  ExpBuyer = 'expBuyerValue',
  ExpNetBuyer = 'ExpNetBuyerValue',
  ExpSeller = 'expSellerValue',
  Holder = 'holderValue',
  Liquidity = 'liquidityValue',
  NetBuyer = 'netBuyerValue',
  NetVolume = 'netVolumeValue',
  PricePercent = 'pricePercentValue',
  Seller = 'sellerValue',
  SellersVolume = 'sellersVolumeValue',
  Volume = 'volumeValue',
}

export enum TimeInterval {
  FourDays = 'FourDays',
  FourHours = 'FourHours',
  OneDay = 'OneDay',
  OneHour = 'OneHour',
  OneMonth = 'OneMonth',
  OneQuarter = 'OneQuarter',
  OneWeek = 'OneWeek',
  TenMinutes = 'TenMinutes',
  ThirtyMinutes = 'ThirtyMinutes',
  ThreeWeeks = 'ThreeWeeks',
  TwelveHours = 'TwelveHours',
  TwoDays = 'TwoDays',
  TwoWeeks = 'TwoWeeks',
}

type AtLeastOne<T, U = { [K in keyof T]: Pick<T, K> }> = Partial<T> & U[keyof U];

export type IntervalConfig = AtLeastOne<{
  [IntervalFilterType.Buyer]?: TimeInterval;
  [IntervalFilterType.ExpBuyer]?: TimeInterval;
  [IntervalFilterType.ExpNetBuyer]?: TimeInterval;
  [IntervalFilterType.ExpSeller]?: TimeInterval;
  [IntervalFilterType.Holder]?: TimeInterval;
  [IntervalFilterType.Liquidity]?: TimeInterval;
  [IntervalFilterType.NetBuyer]?: TimeInterval;
  [IntervalFilterType.Seller]?: TimeInterval;
  [IntervalFilterType.Volume]?: TimeInterval;
  [IntervalFilterType.PricePercent]?: TimeInterval;
  [IntervalFilterType.BuyersVolume]?: TimeInterval;
  [IntervalFilterType.SellersVolume]?: TimeInterval;
  [IntervalFilterType.NetVolume]?: TimeInterval;
}>;

export const useFilterInterval = (initialConfig: IntervalConfig) => {
  const [filterIntervalValue, setFilterIntervalValue] = useState<IntervalConfig>(initialConfig);

  const setValue = (type: IntervalFilterType, value: TimeInterval) => {
    setFilterIntervalValue((s) => {
      return { ...s, [type]: value };
    });
  };

  const handlers = {
    [IntervalFilterType.Buyer]: (value: TimeInterval) => {
      return setValue(IntervalFilterType.Buyer, value);
    },
    [IntervalFilterType.ExpBuyer]: (value: TimeInterval) => {
      return setValue(IntervalFilterType.ExpBuyer, value);
    },
    [IntervalFilterType.ExpNetBuyer]: (value: TimeInterval) => {
      return setValue(IntervalFilterType.ExpNetBuyer, value);
    },
    [IntervalFilterType.ExpSeller]: (value: TimeInterval) => {
      return setValue(IntervalFilterType.ExpSeller, value);
    },
    [IntervalFilterType.Holder]: (value: TimeInterval) => {
      return setValue(IntervalFilterType.Holder, value);
    },
    [IntervalFilterType.Liquidity]: (value: TimeInterval) => {
      return setValue(IntervalFilterType.Liquidity, value);
    },
    [IntervalFilterType.NetBuyer]: (value: TimeInterval) => {
      return setValue(IntervalFilterType.NetBuyer, value);
    },
    [IntervalFilterType.Seller]: (value: TimeInterval) => {
      return setValue(IntervalFilterType.Seller, value);
    },
    [IntervalFilterType.PricePercent]: (value: TimeInterval) => {
      return setValue(IntervalFilterType.PricePercent, value);
    },
    [IntervalFilterType.Volume]: (value: TimeInterval) => {
      return setValue(IntervalFilterType.Volume, value);
    },
    [IntervalFilterType.BuyersVolume]: (value: TimeInterval) => {
      return setValue(IntervalFilterType.BuyersVolume, value);
    },
    [IntervalFilterType.SellersVolume]: (value: TimeInterval) => {
      return setValue(IntervalFilterType.SellersVolume, value);
    },
    [IntervalFilterType.NetVolume]: (value: TimeInterval) => {
      return setValue(IntervalFilterType.NetVolume, value);
    },
  };

  return { filterIntervalValue, handlers };
};
