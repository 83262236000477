import { popMessage } from 'moralis-ui';
import { ApiError, GetTokenExplorerResponseDto, PutQueryRequestDto } from '@/services/index';
import useAuthStore from '@/stores/authStore';
import useQueryStore from '@/stores/queryStore';
import { api } from '@/utils/api';

const useTokenQueryAPI = () => {
  const authToken = useAuthStore((state) => {
    return state.authToken;
  });
  const setQueries = useQueryStore((state) => {
    return state.setQueries;
  });
  const updateQuery = useQueryStore((state) => {
    return state.updateQuery;
  });

  const deleteSavedQuery = async (strategyId: string) => {
    if (!authToken) return;
    try {
      await api.user.userControllerDeleteStrategyAlert1(strategyId);
      popMessage({
        variant: 'success',
        title: 'Deletion Successful',
        message: 'Strategy has been deleted',
      });
      return;
    } catch {
      popMessage({
        variant: 'error',
        title: 'Strategy was not deleted',
        message: 'We were unable to delete this saved strategy. Please try again',
      });
      return;
    }
  };

  const getSavedQueries = async () => {
    if (!authToken) return [] as GetTokenExplorerResponseDto[];
    try {
      const SavedQueries = await api.user.userControllerGetStrategy1();
      setQueries(SavedQueries);
      return SavedQueries;
    } catch {
      popMessage({
        variant: 'error',
        title: 'Oh dear',
        message: 'We were unable to get your saved filters.',
      });
      return [] as GetTokenExplorerResponseDto[];
    }
  };

  const updateSavedQuery = async (queryParams: PutQueryRequestDto, isDelete?: boolean) => {
    if (!authToken) return;
    try {
      const updateResult = await api.user.userControllerUpdateStrategyAlert1(queryParams);
      popMessage({
        variant: 'success',
        title: `${queryParams.name} Updated`,
        message: isDelete
          ? `You will no longer receive email notifications`
          : `You have updated your query notifications`,
      });
      updateQuery(updateResult);
      return updateResult;
    } catch (error) {
      const err: ApiError = error as ApiError;
      // Display custom message if webhook server is not reachable
      if (err.body.message === 'MMBE00089') {
        popMessage({
          variant: 'error',
          title: 'Unable to update strategy',
          message: err.body.cause,
        });
        return;
      }
      if (err.body.message === 'MMBE00090') {
        popMessage({
          variant: 'error',
          title: 'Unable to create strategy alert',
          message: err.body.cause,
        });
        return;
      }
      popMessage({
        variant: 'error',
        title: 'Unable to update strategy',
        message: 'Please try again or contact our amazing support team',
      });
      return;
    }
  };

  return { deleteSavedQuery, getSavedQueries, updateSavedQuery };
};

export default useTokenQueryAPI;
