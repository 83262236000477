export type TAuthToken = string | undefined;
export type TAuthStep = 'connect' | 'verify' | 'complete';

export type TAuthHeaders = {
  address?: string;
  chain?: number;
  domain?: string;
  uri?: string;
};

export type TAuthMethod = 'magic' | 'rainbow' | 'anonymous' | EOAuth;

export enum EOAuth {
  DISCORD = 'discord',
  GITHUB = 'github',
  GOOGLE = 'google',
  TWITTER = 'twitter',
}
