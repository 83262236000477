/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type AddEmailDataRequestDto = {
  /**
   * The type of protocol
   */
  type: AddEmailDataRequestDto.type;
  /**
   * The data associated with the protocol
   */
  data: Array<string>;
};

export namespace AddEmailDataRequestDto {
  /**
   * The type of protocol
   */
  export enum type {
    EMAIL = 'email',
    WEBHOOK = 'webhook',
    TELEGRAM = 'telegram',
  }
}
