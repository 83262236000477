/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddEmailDataRequestDto } from '../models/AddEmailDataRequestDto';
import type { AuthDto } from '../models/AuthDto';
import type { CreateReviewRequestDto } from '../models/CreateReviewRequestDto';
import type { CreateReviewResponseDto } from '../models/CreateReviewResponseDto';
import type { CreateTokenAlertRequestDto } from '../models/CreateTokenAlertRequestDto';
import type { EditUserWalletListRequestDto } from '../models/EditUserWalletListRequestDto';
import type { EnableOrDisableStrategyAlertDto } from '../models/EnableOrDisableStrategyAlertDto';
import type { FollowCuratedWalletListRequest } from '../models/FollowCuratedWalletListRequest';
import type { GetAllCuratedWalletListResponse } from '../models/GetAllCuratedWalletListResponse';
import type { GetBundlesFollowedResponse } from '../models/GetBundlesFollowedResponse';
import type { GetCuratedWalletListResponse } from '../models/GetCuratedWalletListResponse';
import type { GetGasPricesResponseDto } from '../models/GetGasPricesResponseDto';
import type { GetMoneyLineAccessRequestDto } from '../models/GetMoneyLineAccessRequestDto';
import type { GetPublicQuestsResponseDto } from '../models/GetPublicQuestsResponseDto';
import type { GetReviewResponseDto } from '../models/GetReviewResponseDto';
import type { GetTokenExplorerResponseDto } from '../models/GetTokenExplorerResponseDto';
import type { GetUserWalletListResponse } from '../models/GetUserWalletListResponse';
import type { IGetStrategyAlertCoinsResponse } from '../models/IGetStrategyAlertCoinsResponse';
import type { NotificationHistoryResponseDto } from '../models/NotificationHistoryResponseDto';
import type { PlanResponseDto } from '../models/PlanResponseDto';
import type { PlanStageDto } from '../models/PlanStageDto';
import type { PostTokenExplorerRequestDto } from '../models/PostTokenExplorerRequestDto';
import type { PostTokenExplorerResponseDto } from '../models/PostTokenExplorerResponseDto';
import type { PutQueryRequestDto } from '../models/PutQueryRequestDto';
import type { PutQueryResponseDto } from '../models/PutQueryResponseDto';
import type { SaveAddressRequestDto } from '../models/SaveAddressRequestDto';
import type { SaveSmartTradingDataRequestDto } from '../models/SaveSmartTradingDataRequestDto';
import type { SetAddressDescriptionRequestDto } from '../models/SetAddressDescriptionRequestDto';
import type { SmartTradingDataResponseDto } from '../models/SmartTradingDataResponseDto';
import type { TokenAlertResponseDto } from '../models/TokenAlertResponseDto';
import type { UnFollowCuratedWalletListRequest } from '../models/UnFollowCuratedWalletListRequest';
import type { UnSaveAddressRequestDto } from '../models/UnSaveAddressRequestDto';
import type { UpdateQuestItemRequestDto } from '../models/UpdateQuestItemRequestDto';
import type { UpdateReviewRequestDto } from '../models/UpdateReviewRequestDto';
import type { UpdateReviewResponseDto } from '../models/UpdateReviewResponseDto';
import type { UpdateTokenAlertRequestDto } from '../models/UpdateTokenAlertRequestDto';
import type { UserAcademyJwtSsoResponseDto } from '../models/UserAcademyJwtSsoResponseDto';
import type { UserAddCoinFavouritesRequestDto } from '../models/UserAddCoinFavouritesRequestDto';
import type { UserBeansResponseDto } from '../models/UserBeansResponseDto';
import type { UserBeanTransactionsResponseDto } from '../models/UserBeanTransactionsResponseDto';
import type { UserCheckoutResponseDto } from '../models/UserCheckoutResponseDto';
import type { UserChestResponseDto } from '../models/UserChestResponseDto';
import type { UserClaimTradeRewardsDto } from '../models/UserClaimTradeRewardsDto';
import type { UserClaimTradeRewardsResponseDto } from '../models/UserClaimTradeRewardsResponseDto';
import type { UserCoinFavouritesResponseDto } from '../models/UserCoinFavouritesResponseDto';
import type { UserConnectRequestDto } from '../models/UserConnectRequestDto';
import type { UserConnectResponseDto } from '../models/UserConnectResponseDto';
import type { UserCreateCheckoutRequestDto } from '../models/UserCreateCheckoutRequestDto';
import type { UserCreateWalletListRequestDto } from '../models/UserCreateWalletListRequestDto';
import type { UserItemResponseDto } from '../models/UserItemResponseDto';
import type { UserListedPublicPlanResponseDto } from '../models/UserListedPublicPlanResponseDto';
import type { UserLoginRequestDto } from '../models/UserLoginRequestDto';
import type { UserOpenChestRequestDto } from '../models/UserOpenChestRequestDto';
import type { UserOpenChestResponseDto } from '../models/UserOpenChestResponseDto';
import type { UserPortalResponseDto } from '../models/UserPortalResponseDto';
import type { UserPortfolioWalletsResponseDto } from '../models/UserPortfolioWalletsResponseDto';
import type { UserQuestInfoResponseDto } from '../models/UserQuestInfoResponseDto';
import type { UserRetrieveInvoiceResponseDto } from '../models/UserRetrieveInvoiceResponseDto';
import type { UserStatsResponseDto } from '../models/UserStatsResponseDto';
import type { UserUpdateAvatarRequestDto } from '../models/UserUpdateAvatarRequestDto';
import type { UserUpdatePortfolioWalletsRequestDto } from '../models/UserUpdatePortfolioWalletsRequestDto';
import type { UserUpdateSubscriptionRequestDto } from '../models/UserUpdateSubscriptionRequestDto';
import type { UserUpdateSubscriptionResponseDto } from '../models/UserUpdateSubscriptionResponseDto';
import type { UserVerifyRequestDto } from '../models/UserVerifyRequestDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class UserService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Connect wallet and receive the payload needed to sign a message
   * @param requestBody
   * @returns UserConnectResponseDto
   * @throws ApiError
   */
  public userControllerRegister(requestBody: UserConnectRequestDto): CancelablePromise<UserConnectResponseDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: 'userService/user/connect',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Verify signature
   * @param requestBody
   * @returns AuthDto
   * @throws ApiError
   */
  public userControllerLogin(requestBody: UserVerifyRequestDto): CancelablePromise<AuthDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: 'userService/user/verify',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Connect new wallet and receive the payload needed to sign message for new address
   * @param requestBody
   * @returns UserConnectResponseDto
   * @throws ApiError
   */
  public userControllerChangeAddress(requestBody: UserConnectRequestDto): CancelablePromise<UserConnectResponseDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: 'userService/user/changeAddress/request',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Verify signature for new address
   * @param requestBody
   * @returns AuthDto
   * @throws ApiError
   */
  public userControllerChangeAddressVerify(requestBody: UserVerifyRequestDto): CancelablePromise<AuthDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: 'userService/user/changeAddress/verify',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param moneyId
   * @returns AuthDto
   * @returns any
   * @throws ApiError
   */
  public userControllerAnonymousSignup(moneyId: string): CancelablePromise<AuthDto | any> {
    return this.httpRequest.request({
      method: 'POST',
      url: 'userService/user/anonymousSignup/{moneyId}',
      path: {
        moneyId: moneyId,
      },
    });
  }

  /**
   * Login with Magic
   * @param requestBody
   * @returns AuthDto
   * @throws ApiError
   */
  public userControllerLoginMagic(requestBody: UserLoginRequestDto): CancelablePromise<AuthDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: 'userService/user/loginMagic',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Logout with Magic
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public userControllerLogOutMagic(requestBody: UserLoginRequestDto): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'PUT',
      url: 'userService/user/logoutMagic',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Set smart address
   * @param address
   * @returns string
   * @throws ApiError
   */
  public userControllerSetSmartAddress(address: string): CancelablePromise<string> {
    return this.httpRequest.request({
      method: 'PUT',
      url: 'userService/user/smartAddress/{address}',
      path: {
        address: address,
      },
    });
  }

  /**
   * Change user email with Magic
   * @param requestBody
   * @returns AuthDto
   * @throws ApiError
   */
  public userControllerChangeEmail(requestBody: UserLoginRequestDto): CancelablePromise<AuthDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: 'userService/user/changeEmail',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Add Email to verified emails
   * @param requestBody
   * @returns AddEmailDataRequestDto
   * @throws ApiError
   */
  public userControllerAddEmail(requestBody: UserLoginRequestDto): CancelablePromise<Array<AddEmailDataRequestDto>> {
    return this.httpRequest.request({
      method: 'POST',
      url: 'userService/user/addEmail',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Claim beans daily
   * @returns UserBeansResponseDto
   * @throws ApiError
   */
  public userControllerClaimBeans(): CancelablePromise<UserBeansResponseDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: 'userService/user/claimBeans',
    });
  }

  /**
   * Claim trade reward
   * @param requestBody
   * @returns UserClaimTradeRewardsResponseDto
   * @throws ApiError
   */
  public userControllerClaimTradingReward(
    requestBody: UserClaimTradeRewardsDto,
  ): CancelablePromise<UserClaimTradeRewardsResponseDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: 'userService/user/claimTradeReward',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Return data about the users
   * @returns UserStatsResponseDto
   * @throws ApiError
   */
  public userControllerUserStats(): CancelablePromise<UserStatsResponseDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: 'userService/user/stats',
    });
  }

  /**
   * Return the user bean transactions: DAILY_CLAIM=1 MONTHLY_REWARD=2
   * @returns UserBeanTransactionsResponseDto
   * @throws ApiError
   */
  public userControllerBeanTransactions(): CancelablePromise<Array<UserBeanTransactionsResponseDto>> {
    return this.httpRequest.request({
      method: 'GET',
      url: 'userService/user/beanTransactions',
    });
  }

  /**
   * @deprecated
   * Return a user payed plan
   * @returns PlanResponseDto
   * @throws ApiError
   */
  public userControllerPlan(): CancelablePromise<PlanResponseDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: 'userService/user/plan',
    });
  }

  /**
   * @deprecated
   * Return moralis money plans available for purchase
   * @returns UserListedPublicPlanResponseDto
   * @throws ApiError
   */
  public userControllerListedPlans(): CancelablePromise<UserListedPublicPlanResponseDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: 'userService/user/listedPlans',
    });
  }

  /**
   * Return a user plan stage based on subscription level
   * @returns PlanStageDto
   * @throws ApiError
   */
  public userControllerPlanStage(): CancelablePromise<PlanStageDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: 'userService/user/planStage',
    });
  }

  /**
   * @deprecated
   * Return a customer mapping or creates it if it does not exist
   * @returns void
   * @throws ApiError
   */
  public userControllerCustomerMapping(): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'GET',
      url: 'userService/user/customerMapping',
    });
  }

  /**
   * Create a subscription for a specific user
   * @param requestBody
   * @returns UserCheckoutResponseDto
   * @throws ApiError
   */
  public userControllerCreateCheckout(
    requestBody: UserCreateCheckoutRequestDto,
  ): CancelablePromise<UserCheckoutResponseDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: 'userService/user/createCheckout',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Return a link to the user portal
   * @returns UserPortalResponseDto
   * @throws ApiError
   */
  public userControllerPortal(): CancelablePromise<UserPortalResponseDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: 'userService/user/portal',
    });
  }

  /**
   * Update a subscription for a specific user
   * @param planId
   * @param affiliateId
   * @param affiliateProgram
   * @returns UserRetrieveInvoiceResponseDto
   * @throws ApiError
   */
  public userControllerSubscriptionInvoice(
    planId?: string,
    affiliateId?: string,
    affiliateProgram?: string,
  ): CancelablePromise<UserRetrieveInvoiceResponseDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: 'userService/user/subscriptionInvoice',
      query: {
        planId: planId,
        affiliateId: affiliateId,
        affiliateProgram: affiliateProgram,
      },
    });
  }

  /**
   * Removes subscription cancelation
   * @returns UserUpdateSubscriptionResponseDto
   * @throws ApiError
   */
  public userControllerContinueSubscription(): CancelablePromise<UserUpdateSubscriptionResponseDto> {
    return this.httpRequest.request({
      method: 'PUT',
      url: 'userService/user/continueSubscription',
    });
  }

  /**
   * Check if subscription is older than 2 months
   * @returns boolean
   * @throws ApiError
   */
  public userControllerCheckSubscriptionAge(): CancelablePromise<boolean> {
    return this.httpRequest.request({
      method: 'GET',
      url: 'userService/user/checkSubscriptionAge',
    });
  }

  /**
   * Update a subscription for a specific user
   * @param requestBody
   * @returns UserUpdateSubscriptionResponseDto
   * @throws ApiError
   */
  public userControllerUpdateSubscription(
    requestBody: UserUpdateSubscriptionRequestDto,
  ): CancelablePromise<UserUpdateSubscriptionResponseDto> {
    return this.httpRequest.request({
      method: 'PUT',
      url: 'userService/user/updateSubscription',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Cancels a subscription for a specific user
   * @param reason
   * @returns UserUpdateSubscriptionResponseDto
   * @throws ApiError
   */
  public userControllerCancelSubscription(reason?: string): CancelablePromise<UserUpdateSubscriptionResponseDto> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: 'userService/user/cancelSubscription',
      query: {
        reason: reason,
      },
    });
  }

  /**
   * Add a coin favourite for a specific user
   * @param requestBody
   * @returns UserCoinFavouritesResponseDto
   * @throws ApiError
   */
  public userControllerAddUserCoinFavourite(
    requestBody: UserAddCoinFavouritesRequestDto,
  ): CancelablePromise<UserCoinFavouritesResponseDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: 'userService/user/coinFavourite',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Remove a coin favourite for a specific user
   * @param favouriteId
   * @returns any
   * @throws ApiError
   */
  public userControllerRemoveUserCoinFavourite(favouriteId: string): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: 'userService/user/coinFavourite/{favouriteId}',
      path: {
        favouriteId: favouriteId,
      },
    });
  }

  /**
   * Get all favourite coins of a user
   * @returns UserCoinFavouritesResponseDto
   * @throws ApiError
   */
  public userControllerGetAllUserCoinFavourites(): CancelablePromise<UserCoinFavouritesResponseDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: 'userService/user/coinFavourite/all',
    });
  }

  /**
   * Get a jwt token to access the academy courses
   * @param type
   * @param destination
   * @returns UserAcademyJwtSsoResponseDto
   * @throws ApiError
   */
  public userControllerGetAcademyJwt(
    type: string,
    destination: string,
  ): CancelablePromise<UserAcademyJwtSsoResponseDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: 'userService/user/academySSO/{type}/{destination}',
      path: {
        type: type,
        destination: destination,
      },
    });
  }

  /**
   * Open chest and claim rewards
   * @param requestBody
   * @returns UserOpenChestResponseDto
   * @throws ApiError
   */
  public userControllerOpenChest(
    requestBody: UserOpenChestRequestDto,
  ): CancelablePromise<Array<UserOpenChestResponseDto>> {
    return this.httpRequest.request({
      method: 'POST',
      url: 'userService/user/openChest',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * User reward items claimed
   * @returns UserItemResponseDto
   * @throws ApiError
   */
  public userControllerGetUserItems(): CancelablePromise<Array<UserItemResponseDto>> {
    return this.httpRequest.request({
      method: 'GET',
      url: 'userService/user/userItems',
    });
  }

  /**
   * Get all user chests
   * @returns UserChestResponseDto
   * @throws ApiError
   */
  public userControllerGetUserChests(): CancelablePromise<Array<UserChestResponseDto>> {
    return this.httpRequest.request({
      method: 'GET',
      url: 'userService/user/userChests',
    });
  }

  /**
   * Update user avatar
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public userControllerSetUserAvatar(requestBody: UserUpdateAvatarRequestDto): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'PUT',
      url: 'userService/user/avatar',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Get gas price
   * @param chain
   * @returns GetGasPricesResponseDto
   * @throws ApiError
   */
  public userControllerGetGasPrice(chain: string): CancelablePromise<GetGasPricesResponseDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: 'userService/user/gasPrice/{chain}',
      path: {
        chain: chain,
      },
    });
  }

  /**
   * Review a token or chain
   * @param requestBody
   * @returns CreateReviewResponseDto
   * @throws ApiError
   */
  public userControllerCreateReview(requestBody: CreateReviewRequestDto): CancelablePromise<CreateReviewResponseDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: 'userService/user/review',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Update review of a token or chain
   * @param requestBody
   * @returns UpdateReviewResponseDto
   * @throws ApiError
   */
  public userControllerUpdateReview(requestBody: UpdateReviewRequestDto): CancelablePromise<UpdateReviewResponseDto> {
    return this.httpRequest.request({
      method: 'PUT',
      url: 'userService/user/review',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Get all reviews done by a user or get rating by address and chainId
   * @param chainId
   * @param address
   * @returns GetReviewResponseDto
   * @throws ApiError
   */
  public userControllerGetUserReviews(
    chainId?: string,
    address?: string,
  ): CancelablePromise<Array<GetReviewResponseDto>> {
    return this.httpRequest.request({
      method: 'GET',
      url: 'userService/user/review',
      query: {
        chainId: chainId,
        address: address,
      },
    });
  }

  /**
   * Create Token Alert
   * @param requestBody
   * @returns TokenAlertResponseDto
   * @throws ApiError
   */
  public userControllerCreateTokenAlert(
    requestBody: CreateTokenAlertRequestDto,
  ): CancelablePromise<TokenAlertResponseDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: 'userService/user/tokenAlert',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Get Token Alerts
   * @returns TokenAlertResponseDto
   * @throws ApiError
   */
  public userControllerGetTokenAlerts(): CancelablePromise<Array<TokenAlertResponseDto>> {
    return this.httpRequest.request({
      method: 'GET',
      url: 'userService/user/tokenAlert',
    });
  }

  /**
   * Update Token Alert
   * @param requestBody
   * @returns TokenAlertResponseDto
   * @throws ApiError
   */
  public userControllerUpdateTokenAlert(
    requestBody: UpdateTokenAlertRequestDto,
  ): CancelablePromise<TokenAlertResponseDto> {
    return this.httpRequest.request({
      method: 'PUT',
      url: 'userService/user/tokenAlert',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Delete Token Alert
   * @param alertId
   * @returns any
   * @throws ApiError
   */
  public userControllerDeleteTokenAlert(alertId: string): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: 'userService/user/tokenAlert/{alertId}',
      path: {
        alertId: alertId,
      },
    });
  }

  /**
   * Get a list of saved queries for token explorer
   * @returns GetTokenExplorerResponseDto
   * @throws ApiError
   */
  public userControllerGetStrategy(): CancelablePromise<Array<GetTokenExplorerResponseDto>> {
    return this.httpRequest.request({
      method: 'GET',
      url: 'userService/user/tokenExplorerQuery',
    });
  }

  /**
   * Save query for token explorer
   * @param requestBody
   * @returns PostTokenExplorerResponseDto
   * @throws ApiError
   */
  public userControllerCreateStrategyAlert(
    requestBody: PostTokenExplorerRequestDto,
  ): CancelablePromise<PostTokenExplorerResponseDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: 'userService/user/tokenExplorerQuery',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Update query for token explorer
   * @param requestBody
   * @returns PutQueryResponseDto
   * @throws ApiError
   */
  public userControllerUpdateStrategyAlert(requestBody: PutQueryRequestDto): CancelablePromise<PutQueryResponseDto> {
    return this.httpRequest.request({
      method: 'PUT',
      url: 'userService/user/tokenExplorerQuery',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Get a list of saved queries for token explorer
   * @returns GetTokenExplorerResponseDto
   * @throws ApiError
   */
  public userControllerGetStrategy1(): CancelablePromise<Array<GetTokenExplorerResponseDto>> {
    return this.httpRequest.request({
      method: 'GET',
      url: 'userService/user/strategyAlert',
    });
  }

  /**
   * Save query for token explorer
   * @param requestBody
   * @returns PostTokenExplorerResponseDto
   * @throws ApiError
   */
  public userControllerCreateStrategyAlert1(
    requestBody: PostTokenExplorerRequestDto,
  ): CancelablePromise<PostTokenExplorerResponseDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: 'userService/user/strategyAlert',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Update query for token explorer
   * @param requestBody
   * @returns PutQueryResponseDto
   * @throws ApiError
   */
  public userControllerUpdateStrategyAlert1(requestBody: PutQueryRequestDto): CancelablePromise<PutQueryResponseDto> {
    return this.httpRequest.request({
      method: 'PUT',
      url: 'userService/user/strategyAlert',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Enable a disabled alert
   * @param requestBody
   * @returns GetTokenExplorerResponseDto
   * @throws ApiError
   */
  public userControllerEnableStrategyAlert(
    requestBody: EnableOrDisableStrategyAlertDto,
  ): CancelablePromise<GetTokenExplorerResponseDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: 'userService/user/strategyAlert/enable',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Disabled an active alert
   * @param requestBody
   * @returns GetTokenExplorerResponseDto
   * @throws ApiError
   */
  public userControllerDisableStrategyAlert(
    requestBody: EnableOrDisableStrategyAlertDto,
  ): CancelablePromise<GetTokenExplorerResponseDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: 'userService/user/strategyAlert/disable',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Delete strategy from saved strategies
   * @param strategyId
   * @returns any
   * @throws ApiError
   */
  public userControllerDeleteStrategyAlert(strategyId: string): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: 'userService/user/tokenExplorerQuery/{strategyId}',
      path: {
        strategyId: strategyId,
      },
    });
  }

  /**
   * Delete strategy from saved strategies
   * @param strategyId
   * @returns any
   * @throws ApiError
   */
  public userControllerDeleteStrategyAlert1(strategyId: string): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: 'userService/user/strategyAlert/{strategyId}',
      path: {
        strategyId: strategyId,
      },
    });
  }

  /**
   * Get coins being monitored by the strategy
   * @param strategyId
   * @param publicId
   * @param cursor
   * @param limit
   * @returns IGetStrategyAlertCoinsResponse
   * @throws ApiError
   */
  public userControllerGetStrategyAlertCoins(
    strategyId?: string,
    publicId?: string,
    cursor?: string,
    limit?: number,
  ): CancelablePromise<IGetStrategyAlertCoinsResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: 'userService/user/strategyAlert/coins',
      query: {
        strategyId: strategyId,
        publicId: publicId,
        cursor: cursor,
        limit: limit,
      },
    });
  }

  /**
   * Get Bundle list collections
   * @returns GetAllCuratedWalletListResponse
   * @throws ApiError
   */
  public userControllerGetCuratedWalletLists(): CancelablePromise<Array<GetAllCuratedWalletListResponse>> {
    return this.httpRequest.request({
      method: 'GET',
      url: 'userService/user/allCuratedWalletLists',
    });
  }

  /**
   * Get Bundle list collections
   * @returns GetAllCuratedWalletListResponse
   * @throws ApiError
   */
  public userControllerGetCuratedWalletLists1(): CancelablePromise<Array<GetAllCuratedWalletListResponse>> {
    return this.httpRequest.request({
      method: 'GET',
      url: 'userService/user/allBundle',
    });
  }

  /**
   * Get Bundle collection by name
   * @param name
   * @returns GetCuratedWalletListResponse
   * @throws ApiError
   */
  public userControllerGetCuratedWalletListByName(name: string): CancelablePromise<GetCuratedWalletListResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: 'userService/user/curatedWalletLists/{name}',
      path: {
        name: name,
      },
    });
  }

  /**
   * Get Bundle collection by name
   * @param name
   * @returns GetCuratedWalletListResponse
   * @throws ApiError
   */
  public userControllerGetCuratedWalletListByName1(name: string): CancelablePromise<GetCuratedWalletListResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: 'userService/user/bundle/{name}',
      path: {
        name: name,
      },
    });
  }

  /**
   * Follow a curated moralis money wallet list collections for a user
   * @param requestBody
   * @returns GetCuratedWalletListResponse
   * @throws ApiError
   */
  public userControllerFollowCuratedWalletList(
    requestBody: FollowCuratedWalletListRequest,
  ): CancelablePromise<GetCuratedWalletListResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: 'userService/user/curatedWalletLists/follow',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Follow a curated moralis money wallet list collections for a user
   * @param requestBody
   * @returns GetCuratedWalletListResponse
   * @throws ApiError
   */
  public userControllerFollowCuratedWalletList1(
    requestBody: FollowCuratedWalletListRequest,
  ): CancelablePromise<GetCuratedWalletListResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: 'userService/user/bundle/follow',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Unfollow a curated moralis money wallet list collections for a user
   * @param requestBody
   * @returns GetCuratedWalletListResponse
   * @throws ApiError
   */
  public userControllerUnfollowCuratedWalletList(
    requestBody: UnFollowCuratedWalletListRequest,
  ): CancelablePromise<GetCuratedWalletListResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: 'userService/user/curatedWalletLists/unfollow',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Unfollow a curated moralis money wallet list collections for a user
   * @param requestBody
   * @returns GetCuratedWalletListResponse
   * @throws ApiError
   */
  public userControllerUnfollowCuratedWalletList1(
    requestBody: UnFollowCuratedWalletListRequest,
  ): CancelablePromise<GetCuratedWalletListResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: 'userService/user/bundle/unfollow',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Return a list of bundle followed by a user and add the user config to it, using the user JWT
   * @returns GetBundlesFollowedResponse
   * @throws ApiError
   */
  public userControllerGetBundlesFollowed(): CancelablePromise<GetBundlesFollowedResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: 'userService/user/bundles/followed',
    });
  }

  /**
   * Get the list of notification emitted for a specific stream
   * @param streamId
   * @returns NotificationHistoryResponseDto
   * @throws ApiError
   */
  public userControllerGetNotificationHistory(
    streamId: string,
  ): CancelablePromise<Array<NotificationHistoryResponseDto>> {
    return this.httpRequest.request({
      method: 'GET',
      url: 'userService/user/notificationHistory/{streamId}',
      path: {
        streamId: streamId,
      },
    });
  }

  /**
   * Get user wallet list collections
   * @returns GetUserWalletListResponse
   * @throws ApiError
   */
  public userControllerGetUserWalletLists(): CancelablePromise<Array<GetUserWalletListResponse>> {
    return this.httpRequest.request({
      method: 'GET',
      url: 'userService/user/walletLists',
    });
  }

  /**
   * create a user wallet list collection
   * @param requestBody
   * @returns GetUserWalletListResponse
   * @throws ApiError
   */
  public userControllerCreateUserWalletList(
    requestBody: UserCreateWalletListRequestDto,
  ): CancelablePromise<GetUserWalletListResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: 'userService/user/walletLists',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * edit a user wallet list collection
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public userControllerEditUserWalletList(requestBody: EditUserWalletListRequestDto): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'PUT',
      url: 'userService/user/walletLists',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * delete a user wallet list collection
   * @param walletListId
   * @returns any
   * @throws ApiError
   */
  public userControllerDeleteUserWalletList(walletListId: string): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: 'userService/user/walletLists/{walletListId}',
      path: {
        walletListId: walletListId,
      },
    });
  }

  /**
   * save an address to a user wallet list collection
   * @param requestBody
   * @returns GetUserWalletListResponse
   * @throws ApiError
   */
  public userControllerSaveAddress(requestBody: SaveAddressRequestDto): CancelablePromise<GetUserWalletListResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: 'userService/user/saveAddress',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * unsave an address from a user wallet list collection
   * @param requestBody
   * @returns GetUserWalletListResponse
   * @throws ApiError
   */
  public userControllerRemoveSavedAddress(
    requestBody: UnSaveAddressRequestDto,
  ): CancelablePromise<GetUserWalletListResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: 'userService/user/unsaveAddress',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Check if a wallet address is already saved for a user
   * @param address
   * @returns boolean
   * @throws ApiError
   */
  public userControllerIsAddressSaved(address: string): CancelablePromise<boolean> {
    return this.httpRequest.request({
      method: 'GET',
      url: 'userService/user/isAddressSaved/{address}',
      path: {
        address: address,
      },
    });
  }

  /**
   * Set a description for an address in a wallet list
   * @param requestBody
   * @returns boolean
   * @throws ApiError
   */
  public userControllerSetAddressDescription(requestBody: SetAddressDescriptionRequestDto): CancelablePromise<boolean> {
    return this.httpRequest.request({
      method: 'PUT',
      url: 'userService/user/setDescription',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Set a username access to moralis money line indicator
   * @param requestBody
   * @returns boolean
   * @throws ApiError
   */
  public userControllerSetMoneyLineAccess(requestBody: GetMoneyLineAccessRequestDto): CancelablePromise<boolean> {
    return this.httpRequest.request({
      method: 'POST',
      url: 'userService/user/setMoneyLineAccess',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Save smart trading data address
   * @param requestBody
   * @returns SmartTradingDataResponseDto
   * @throws ApiError
   */
  public userControllerSetSmartTradingData(
    requestBody: SaveSmartTradingDataRequestDto,
  ): CancelablePromise<SmartTradingDataResponseDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: 'userService/user/tradingData/smart',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Get user trading data
   * @returns SmartTradingDataResponseDto
   * @throws ApiError
   */
  public userControllerGetUserTradingData(): CancelablePromise<SmartTradingDataResponseDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: 'userService/user/tradingData',
    });
  }

  /**
   * Get user portfolio wallets
   * @returns UserPortfolioWalletsResponseDto
   * @throws ApiError
   */
  public userControllerGetUserPortfolioWallets(): CancelablePromise<UserPortfolioWalletsResponseDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: 'userService/user/portfolioWallets',
    });
  }

  /**
   * Add/remove wallets to user portfolio
   * @param requestBody
   * @returns UserPortfolioWalletsResponseDto
   * @throws ApiError
   */
  public userControllerAddOrRemoveUserPortfolioWallets(
    requestBody: UserUpdatePortfolioWalletsRequestDto,
  ): CancelablePromise<UserPortfolioWalletsResponseDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: 'userService/user/portfolioWallets',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Get all available public quests
   * @param filterQuestId
   * @returns GetPublicQuestsResponseDto
   * @throws ApiError
   */
  public userControllerGetPublicQuests(filterQuestId?: string): CancelablePromise<GetPublicQuestsResponseDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: 'userService/user/quests',
      query: {
        filterQuestId: filterQuestId,
      },
    });
  }

  /**
   * Get user quest information
   * @param questId
   * @returns UserQuestInfoResponseDto
   * @throws ApiError
   */
  public userControllerGetUserQuestInfo(questId: string): CancelablePromise<UserQuestInfoResponseDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: 'userService/user/userQuest',
      query: {
        questId: questId,
      },
    });
  }

  /**
   * Add/remove an quest item from user quest
   * @param requestBody
   * @returns UserQuestInfoResponseDto
   * @throws ApiError
   */
  public userControllerUpdateUserQuestItem(
    requestBody: UpdateQuestItemRequestDto,
  ): CancelablePromise<UserQuestInfoResponseDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: 'userService/user/userQuest',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
}
