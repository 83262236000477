import { toRequiredString } from './lib';

const [protocol, apiHost] = toRequiredString(process.env.NEXT_PUBLIC_SEGMENT_INT_URL, {
  varName: 'NEXT_PUBLIC_SEGMENT_INT_URL',
}).split('://');

export const EnvSegment = {
  cfg: {
    writeKey: toRequiredString(process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY, { varName: 'NEXT_PUBLIC_SEGMENT_WRITE_KEY' }),
    cdnURL: toRequiredString(process.env.NEXT_PUBLIC_SEGMENT_CDN_URL, { varName: 'NEXT_PUBLIC_SEGMENT_WRITE_KEY' }),
  },
  intergationCfg: {
    apiHost,
    protocol,
  },
};
