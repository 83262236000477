import create from 'zustand';
import { persist } from 'zustand/middleware';
import { GetTokenExplorerResponseDto } from '../services';

type TQueryStore = {
  currentQueryId?: string;
  getQueryById: (queryId: string) => GetTokenExplorerResponseDto | undefined;
  queries: GetTokenExplorerResponseDto[];
  setCurrentQueryId: (queryId?: string) => void;
  setQueries: (queries: GetTokenExplorerResponseDto[]) => void;
  updateQuery: (query: GetTokenExplorerResponseDto) => void;
};

const updateQuery = (
  queries: GetTokenExplorerResponseDto[],
  updateQuery: GetTokenExplorerResponseDto,
): GetTokenExplorerResponseDto[] => {
  const filteredQueries = queries.filter((item) => {
    return item.id !== updateQuery.id;
  });
  const updatedQueries: GetTokenExplorerResponseDto[] = [];
  updatedQueries.push(...filteredQueries, updateQuery);
  return updatedQueries;
};

const useQueryStore = create(
  persist<TQueryStore>(
    (set, get) => {
      return {
        currentQueryId: '',
        queries: [],
        getQueryById: (queryId: string) => {
          const queryState = get().queries;
          return queryState.find((query) => {
            return query.id === queryId;
          });
        },
        setCurrentQueryId: (queryId?: string) => {
          set(() => {
            return {
              currentQueryId: queryId || undefined,
            };
          });
        },
        setQueries: (response: GetTokenExplorerResponseDto[]) => {
          set(() => {
            return {
              queries: response,
            };
          });
        },
        updateQuery: (updatedQuery: GetTokenExplorerResponseDto) => {
          set((state) => {
            return {
              queries: updateQuery(state.queries, updatedQuery),
            };
          });
        },
      };
    },
    {
      name: 'mm-query-storage',
      getStorage: () => {
        return localStorage;
      },
    },
  ),
);

export default useQueryStore;
