import { useState } from 'react';
import { ethers } from 'ethers';
import { useDebounce } from 'moralis-ui';
import useSWR from 'swr';
import { api } from '@/utils/api';
import { logApp } from '@/utils/logApp';
import { addWalletToPortfolio, addWalletToWatchList } from '../libs/addWallet';
import { EAdditionMethod } from '../libs/types';
import { getMergedList } from '../utils/list';
import { TList } from '../utils/organizeWalletsByList';
import { useMultiWalletData } from './useMultiWalletData';

const log = logApp.create('useAddWallet');

export const useAddWallet = () => {
  const { data, mutate } = useMultiWalletData();

  const [isAdding, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const debouncedInputValue = useDebounce({ value: searchTerm, delay: 1000 });

  // TODO: reuse useEnsName
  const { isLoading: isLoadingEnsName } = useSWR(['ensName', debouncedInputValue], async () => {
    if (!debouncedInputValue || ethers.utils.isAddress(debouncedInputValue)) {
      return;
    }
    const response = await api.web3.web3ControllerGetAddressFromEns(debouncedInputValue);
    if (response.address) {
      setSearchTerm(response.address);
    }
  });

  const mergedLists = data ? getMergedList(data as TList) : [];

  const addToPortfolio = async (additionMethod: EAdditionMethod, address?: string) => {
    await add(() => addWalletToPortfolio(address || searchTerm, additionMethod)).catch((err) => {
      log.warnError('[addToPortfolio]', err, { searchTerm, address });
    });
  };

  const addToWatchList = async (address?: string) => {
    await add(() => addWalletToWatchList(address || searchTerm)).catch((err) => {
      log.warnError('[addToWatchList]', err, { searchTerm, address });
    });
  };

  const add = async (fetch: () => Promise<void>) => {
    setIsLoading(true);
    await fetch();
    await mutate();
    setSearchTerm('');
    setIsLoading(false);
  };

  return {
    addToPortfolio,
    addToWatchList,
    isAdding,
    mergedLists,
    searchTerm,
    setSearchTerm,
    isLoadingEnsName,
  };
};
