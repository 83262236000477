/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type GetReviewResponseDto = {
  chainId: string;
  address?: string;
  name?: string;
  symbol?: string;
  type: GetReviewResponseDto.type;
  rating: number;
  reviewId: string;
  createdAt: number;
  updatedAt: number;
  moneyId: string;
};

export namespace GetReviewResponseDto {
  export enum type {
    TOKEN = 'token',
    CHAIN = 'chain',
  }
}
