import { toRequiredString } from './lib';

const nodeEnv = toRequiredString(process.env.NODE_ENV, { varName: 'NODE_ENV', defaultValue: 'development' });
export const EnvApp = {
  logName: 'moralis.com',
  version: toRequiredString(process.env.NEXT_PUBLIC_APP_VERSION, {
    varName: 'NEXT_PUBLIC_APP_VERSION',
    defaultValue: 'dev',
  }),
  nodeEnv,
  isDevEnv: nodeEnv === 'development',
  isTestEnv: nodeEnv === 'test',
  usePrettyLog: Boolean(process.env.PRETTY_LOG),
};
