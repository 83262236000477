import { color as designSystemColors } from '@web3uikit/styles';

const color = {
  black: '#000000',
  moneyAero10: '#F0F8FF',
  moneyAero20: '#DCEEFE',
  moneyAero30: '#BBDBF7',
  moneyAero40: '#85B3DB',
  moneyAero50: '#6287B7',
  moneyAero60: '#3877AD',
  moneyAero70: '#1A3656',
  moneyAero71: '#193554',
  moneyAero72: '#193452',
  moneyAero73: '#183251',
  moneyAero74: '#17314F',
  moneyAero75: '#17304D',
  moneyAero76: '#162F4B',
  moneyAero77: '#152E49',
  moneyAero78: '#142C48',
  moneyAero79: '#142B46',
  moneyAero80: '#132A44',
  moneyAero83: '#10253E',
  moneyAero85: '#0D2035',
  moneyAero90: '#09182A',
  moneyBlue20: '#213853',
  moneyBlue30: '#57A5FF',
  moneyBlue40: '#0D243F',
  moneyBlue50: '#0150AD',
  moneyBlue60: '#003470',
  moneyBlueNatural40: '#0F7FFF',
  moneyGreen40: '#5FC578',
  moneyGrey30: '#CACFD3',
  moneyGrey40: '#A8AFB7',
  moneyGrey50: '#5E84B5',
  moneyLavender50: '#5555FA',
  moneyMint40: '#00D1AE',
  moneyMint50: '#00AD96',
  moneyNavy40: '#0B72C4',
  moneyPink40: '#E156D0',
  moneyPurple40: '#BC68FF',
  moneyRed40: '#E95557',
  moneyWhite: '#FFFFFE',
  moneyWhiteOpacity5: 'rgba(255,255,255,0.05)',
  moneyYellow50: '#EBBB00',
  moneyTeal50: '#0E5C58',
  moneyPurple50: '#9B22FF',
  ...designSystemColors,
} as const;

export const bgGradient = 'linear-gradient(0deg, rgba(9,27,48,1) 0%, rgba(13,36,63,1) 100%)';

export const modalGradient = 'radial-gradient(102.8% 102.8% at 3.14% -9.06%, #2F5E92 0%, #133B5C 83.07%, #11365A 100%)';

export const progressGradient = 'linear-gradient(90deg, #0F7FFF 0%, #57A5FF 100%)';

export default color;
