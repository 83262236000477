import { useMemo } from 'react';
import { TimeInterval } from '@/helpers/hooks';
import { THiddenGemDTO } from '@/types/tokenExplorer';

export enum EChanges {
  BuyersChange = 'BuyersChange',
  BuyersVolumeChangeUSD = 'BuyersVolumeChangeUSD',
  ExperiencedBuyersChange = 'ExperiencedBuyersChange',
  ExperiencedNetBuyersChange = 'ExperiencedNetBuyersChange',
  ExperiencedSellersChange = 'ExperiencedSellersChange',
  HoldersChange = 'HoldersChange',
  LiquidityChange = 'LiquidityChange',
  LiquidityChangeUSD = 'LiquidityChangeUSD',
  NetBuyersChange = 'NetBuyersChange',
  NetVolumeChangeUSD = 'NetVolumeChangeUSD',
  PricePercentChangeUSD = 'PricePercentChangeUSD',
  SellersChange = 'SellersChange',
  SellersVolumeChangeUSD = 'SellersVolumeChangeUSD',
  VolumeChangeUSD = 'VolumeChangeUSD',
}

const defaultMetrics = [
  EChanges.BuyersChange,
  EChanges.BuyersVolumeChangeUSD,
  EChanges.ExperiencedBuyersChange,
  EChanges.ExperiencedNetBuyersChange,
  EChanges.ExperiencedSellersChange,
  EChanges.HoldersChange,
  EChanges.LiquidityChange,
  EChanges.LiquidityChangeUSD,
  EChanges.NetBuyersChange,
  EChanges.NetVolumeChangeUSD,
  EChanges.PricePercentChangeUSD,
  EChanges.SellersChange,
  EChanges.SellersVolumeChangeUSD,
  EChanges.VolumeChangeUSD,
];

export function useTokenChangeMetrics<TChange extends EChanges>(
  timeframe: TimeInterval,
  tokenData: THiddenGemDTO,
  metrics?: TChange[],
) {
  type TParsedMetrics = {
    [TKey in TChange]: number;
  };

  const data: TParsedMetrics = useMemo(() => {
    const usedMetrics = metrics || defaultMetrics;

    const result: Partial<TParsedMetrics> = {};

    for (const key of usedMetrics) {
      result[key as TChange] = tokenData[`${timeframe}${key}`];
    }

    return result as TParsedMetrics;
  }, [timeframe, tokenData, metrics]);

  return data;
}
