import { useRouter } from 'next/router';
import { TLinkStatus } from '@/types/TNavLinkClickParams';

export const useCheckActiveLinks = () => {
  const router = useRouter();
  const currentPath = router.pathname;

  const checkActiveLink = (hrefs: string[]): TLinkStatus => {
    for (const href of hrefs) {
      // for home page link
      if (href === '/') {
        if (href === currentPath) {
          return 'active';
        }
      } else {
        // not the home page link
        if (currentPath.includes(href)) {
          return 'active';
        }
      }
    }
    return 'initial';
  };

  return { checkActiveLink };
};
