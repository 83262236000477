import { useMultiWalletData } from '@/components/manageWallets/hooks/useMultiWalletData';
import { getMergedList } from '@/components/manageWallets/utils/list';
import { useAccountSelectorStore } from '../utils/accountSelectorStore';

export const useAccountSelector = () => {
  const { tabId, setTabId } = useAccountSelectorStore();
  const { data, isLoading } = useMultiWalletData();
  const list = data?.[tabId];
  const mergedLists = data ? getMergedList(data) : [];

  return {
    data,
    isLoading,
    tabId,
    setTabId,
    list,
    mergedLists,
  };
};
