export const language = {
  en: {
    buysOrSellsAToken: 'buys or sells a token',
    every: 'Every',
    for: 'for',
    hasFoundYou: 'has found you',
    inTheLast: 'in the last',
    is: 'is',
    when: 'when',
    youWillBeAlertedEachTime: 'You will be alerted each time a wallet in',
    youWillBeAlertedEvery: 'You will be alerted every',
    youWillGetANotification: 'you will get a notification with all the new coins that your strategy',
  },
};
