/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { NotificationConfigDto } from './NotificationConfigDto';

export type CreateTokenAlertRequestDto = {
  /**
   * The name of the alert submitted by the user
   */
  readonly name: string;
  /**
   * How often the alert should be triggered
   */
  timeframe: CreateTokenAlertRequestDto.timeframe;
  /**
   * The operator to use when comparing the threshold value to the current value
   */
  operator: CreateTokenAlertRequestDto.operator;
  /**
   * The type of filter used by the alert
   */
  filter: CreateTokenAlertRequestDto.filter;
  /**
   * No use at the moment
   */
  readonly topic: string;
  /**
   * The address of the token to be monitored
   */
  readonly tokenAddress: string;
  /**
   * The name of the token to be monitored
   */
  readonly tokenName: string;
  /**
   * The ID of the chain the token is on
   */
  readonly chainId: string;
  /**
   * Used as a coolDown after while alert will be sent again, 0 mean no repeat
   */
  intervalInSeconds: CreateTokenAlertRequestDto.intervalInSeconds;
  /**
   * The config of the notification
   */
  readonly config: NotificationConfigDto;
  /**
   * The percentual change needed to trigger an alert
   */
  readonly percentualChange?: string;
};

export namespace CreateTokenAlertRequestDto {
  /**
   * How often the alert should be triggered
   */
  export enum timeframe {
    _10 = '10',
    _30 = '30',
    _60 = '60',
    _240 = '240',
    _720 = '720',
    _1440 = '1440',
    _2880 = '2880',
    _5760 = '5760',
    _10080 = '10080',
    _20160 = '20160',
    _30240 = '30240',
    _43200 = '43200',
    _129600 = '129600',
  }

  /**
   * The operator to use when comparing the threshold value to the current value
   */
  export enum operator {
    LT = 'lt',
    GT = 'gt',
  }

  /**
   * The type of filter used by the alert
   */
  export enum filter {
    EXPERIENCED_NET_BUYERS_CHANGE = 'experiencedNetBuyersChange',
    NET_BUYERS_CHANGE = 'netBuyersChange',
    NET_VOLUME_CHANGE_USD = 'netVolumeChangeUSD',
    PRICE_CHANGE = 'priceChange',
  }

  /**
   * Used as a coolDown after while alert will be sent again, 0 mean no repeat
   */
  export enum intervalInSeconds {
    _0 = '0',
    _5 = '5',
    _30 = '30',
    _60 = '60',
    _300 = '300',
    _3600 = '3600',
    _14400 = '14400',
    _86400 = '86400',
  }
}
