import { ButtonTransparent } from '@/components/core/Button/ButtonTransparent';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './TokenSearchInput.module.scss';

interface ITokenSearchInputProps {
  disabled?: boolean;
  isOpen: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClose: () => void;
  placeholder?: string;
  value?: string | ReadonlyArray<string> | number;
}

const TokenSearchInput = ({ onChange, value, onClose, isOpen, placeholder, disabled }: ITokenSearchInputProps) => {
  return (
    <div className={styles.wrapper}>
      <input className={styles.input} disabled={disabled} placeholder={placeholder} value={value} onChange={onChange} />
      {isOpen && (
        <ButtonTransparent className={styles.cross} onClick={onClose}>
          <FontAwesomeIcon icon={faXmark} style={{ width: '12px' }} />
        </ButtonTransparent>
      )}
    </div>
  );
};

export default TokenSearchInput;
