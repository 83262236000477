/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { cachedPairDelete } from '../models/cachedPairDelete';
import type { Coin } from '../models/Coin';
import type { EDataSummaryType } from '../models/EDataSummaryType';
import type { getCoinListOptions } from '../models/getCoinListOptions';
import type { getCoinsByFilteringOptions } from '../models/getCoinsByFilteringOptions';
import type { IAddCoinData } from '../models/IAddCoinData';
import type { IAddStrategyCoinDto } from '../models/IAddStrategyCoinDto';
import type { IBlacklistCoinRequest } from '../models/IBlacklistCoinRequest';
import type { IGetChainsDataSummaryResponse } from '../models/IGetChainsDataSummaryResponse';
import type { IGetPaginatedStrategyCoinsResponse } from '../models/IGetPaginatedStrategyCoinsResponse';
import type { IGetStrategiesAnalysisDto } from '../models/IGetStrategiesAnalysisDto';
import type { IGetStrategiesAnalysisResult } from '../models/IGetStrategiesAnalysisResult';
import type { INotificationSubscriptionResponse } from '../models/INotificationSubscriptionResponse';
import type { ISetNotificationSubscriptionOptions } from '../models/ISetNotificationSubscriptionOptions';
import type { IUploadLogoResponse } from '../models/IUploadLogoResponse';
import type { TCleanNotificationSubscription } from '../models/TCleanNotificationSubscription';
import type { TUpdateNotificationSubscription } from '../models/TUpdateNotificationSubscription';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class InsightsService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @param requestBody
   * @returns Coin Ok
   * @throws ApiError
   */
  public hiddenGems(requestBody: getCoinsByFilteringOptions): CancelablePromise<Array<Coin>> {
    return this.httpRequest.request({
      method: 'POST',
      url: 'insightsService/hiddenGems',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param chainId
   * @param address
   * @param uniqueName
   * @returns Coin Ok
   * @throws ApiError
   */
  public hiddenGemCoinSearch(chainId: string, address?: string, uniqueName?: string): CancelablePromise<Coin> {
    return this.httpRequest.request({
      method: 'GET',
      url: 'insightsService/hiddenGems',
      query: {
        chainId: chainId,
        address: address,
        uniqueName: uniqueName,
      },
    });
  }

  /**
   * @param requestBody
   * @returns Coin Ok
   * @throws ApiError
   */
  public hiddenGemCoinsSearch(requestBody: getCoinListOptions): CancelablePromise<Array<Coin>> {
    return this.httpRequest.request({
      method: 'POST',
      url: 'insightsService/hiddenGems/listCoins',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param requestBody
   * @returns any Ok
   * @throws ApiError
   */
  public setNotificationSubscription(requestBody: ISetNotificationSubscriptionOptions): CancelablePromise<{
    subscriptionId: string;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: 'insightsService/hiddenGems/notificationSubscription',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param subscriptionId
   * @returns TCleanNotificationSubscription Ok
   * @throws ApiError
   */
  public getNotificationSubscription(subscriptionId: string): CancelablePromise<TCleanNotificationSubscription> {
    return this.httpRequest.request({
      method: 'GET',
      url: 'insightsService/hiddenGems/notificationSubscription',
      query: {
        subscriptionId: subscriptionId,
      },
    });
  }

  /**
   * @param requestBody
   * @returns TCleanNotificationSubscription Ok
   * @throws ApiError
   */
  public updateNotificationSubscription(
    requestBody: TUpdateNotificationSubscription,
  ): CancelablePromise<TCleanNotificationSubscription> {
    return this.httpRequest.request({
      method: 'PUT',
      url: 'insightsService/hiddenGems/notificationSubscription',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param requestBody
   * @returns INotificationSubscriptionResponse Ok
   * @throws ApiError
   */
  public deleteNotificationSubscription(
    requestBody: INotificationSubscriptionResponse,
  ): CancelablePromise<INotificationSubscriptionResponse> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: 'insightsService/hiddenGems/notificationSubscription',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param requestBody
   * @returns any Ok
   * @throws ApiError
   */
  public getAndDeleteTokenPairCache(requestBody: {
    readOnly?: boolean;
    chainId: string;
    coinTokenAddress: string;
  }): CancelablePromise<
    | cachedPairDelete
    | {
        newPair: any;
        oldPair: any;
        message: string;
      }
  > {
    return this.httpRequest.request({
      method: 'POST',
      url: 'insightsService/hiddenGems/tokenPairCache',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param requestBody
   * @returns any Ok
   * @throws ApiError
   */
  public blacklistCoin(requestBody: IBlacklistCoinRequest): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: 'insightsService/hiddenGems/blacklist',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param formData
   * @returns IUploadLogoResponse Ok
   * @throws ApiError
   */
  public uploadFile(formData: {
    address: string;
    chainId: string;
    file: Blob;
  }): CancelablePromise<IUploadLogoResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: 'insightsService/hiddenGems/uploadLogo',
      formData: formData,
      mediaType: 'multipart/form-data',
    });
  }

  /**
   * @param chainId
   * @param limit
   * @param offset
   * @returns string Ok
   * @throws ApiError
   */
  public coinUniqueNames(chainId: string, limit?: number, offset?: number): CancelablePromise<Array<string>> {
    return this.httpRequest.request({
      method: 'GET',
      url: 'insightsService/hiddenGems/coinUniqueNames',
      query: {
        chainId: chainId,
        limit: limit,
        offset: offset,
      },
    });
  }

  /**
   * @param requestBody
   * @returns any Ok
   * @throws ApiError
   */
  public addRatings(requestBody: IAddCoinData): CancelablePromise<{
    message: string;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: 'insightsService/hiddenGems/ratings',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param requestBody
   * @returns any Ok
   * @throws ApiError
   */
  public addStrategyCoins(requestBody: IAddStrategyCoinDto): CancelablePromise<{
    message: string;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: 'insightsService/hiddenGems/strategyCoins',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param subscriptionId
   * @param cursor
   * @param limit
   * @returns IGetPaginatedStrategyCoinsResponse Ok
   * @throws ApiError
   */
  public getSubscriptionStrategyCoins(
    subscriptionId: string,
    cursor?: string,
    limit?: number,
  ): CancelablePromise<IGetPaginatedStrategyCoinsResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: 'insightsService/hiddenGems/strategyCoins',
      query: {
        subscriptionId: subscriptionId,
        cursor: cursor,
        limit: limit,
      },
    });
  }

  /**
   * @param requestBody
   * @returns IGetStrategiesAnalysisResult Ok
   * @throws ApiError
   */
  public getStrategiesAnalysisBatch(
    requestBody: IGetStrategiesAnalysisDto,
  ): CancelablePromise<Array<IGetStrategiesAnalysisResult>> {
    return this.httpRequest.request({
      method: 'POST',
      url: 'insightsService/hiddenGems/strategyAnalysis/batch',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param chainIds
   * @param dataType
   * @param categoryId
   * @returns IGetChainsDataSummaryResponse Ok
   * @throws ApiError
   */
  public getChainsDataSummary(
    chainIds: string,
    dataType: EDataSummaryType,
    categoryId?: string,
  ): CancelablePromise<IGetChainsDataSummaryResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: 'insightsService/hiddenGems/chainDataGraphs',
      query: {
        chainIds: chainIds,
        dataType: dataType,
        categoryId: categoryId,
      },
    });
  }
}
