import { useMemo } from 'react';
import { useStrategies } from './useStrategies/useStrategies';
import { useBundles } from './useBundles';
import { useTokenAlerts } from './useTokenAlerts';
import { useUserStatsService } from './useUserStatsService';

export const useUserAlertsCount = () => {
  const { stage } = useUserStatsService();
  const { alerts: tokenAlerts } = useTokenAlerts();
  const { strategies } = useStrategies();
  const { bundles } = useBundles();

  const alertCounts = useMemo(() => {
    const counts = {
      token: tokenAlerts?.filter((alert) => alert.notification.enabled)?.length ?? 0,
      strategy: strategies?.withAlert?.filter((alert) => alert.notification)?.length ?? 0,
      bundle: bundles?.withAlert?.length ?? 0,
    };
    return {
      ...counts,
      all: Object.values(counts).reduce((acc, curr) => acc + curr, 0),
    };
  }, [tokenAlerts, strategies?.withAlert, bundles?.withAlert]);

  return {
    isLimitReached: alertCounts.all >= (stage?.alerts ?? 0),
    max: stage?.alerts === -1 ? Infinity : stage?.alerts,
    alertCounts,
  };
};
