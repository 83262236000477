import { useEffect } from 'react';
import { getUnixTime } from 'date-fns';
import { useLogout } from '@/helpers/hooks/index';
import { UserStatsResponseDto } from '@/services/index';
import useAuthStore from '@/stores/authStore';
import useBeanStore from '@/stores/beanStore';
import useUserStore from '@/stores/userStore';
import { api } from '@/utils/api';
import { fetchJSON } from '@/utils/api/fetchJSON';
import { logApp } from '@/utils/logApp';

const log = logApp.create('useUserStatsService');

export const useUserStatsService = () => {
  const { setStage, setUser, stage } = useUserStore();
  const { setBeanState } = useBeanStore();
  const { handleLogout } = useLogout();
  const { setCustomer } = useAuthStore();
  const authToken = useAuthStore((state) => {
    return state.authToken;
  });
  const customer = useAuthStore(({ customer }) => customer);

  // delete this function and uncomment this line:
  // level: userStatusResponse.data.level
  // when BE is ready for level system

  const userLevel = (beanTotal: number): { level: number; nextGoal: number } => {
    // D&D level system from IGN
    // https://www.ign.com/wikis/dungeons-and-dragons/Level_and_XP_progression

    if (!beanTotal) return { level: 0, nextGoal: 10000 };
    switch (true) {
      case beanTotal < 10000:
        return { level: 1, nextGoal: 30000 };
      case beanTotal < 30000:
        return { level: 2, nextGoal: 60000 };
      case beanTotal < 60000:
        return { level: 3, nextGoal: 100000 };
      case beanTotal < 100000:
        return { level: 4, nextGoal: 150000 };
      case beanTotal < 150000:
        return { level: 5, nextGoal: 210000 };
      case beanTotal < 210000:
        return { level: 6, nextGoal: 280000 };
      case beanTotal < 280000:
        return { level: 7, nextGoal: 360000 };
      case beanTotal < 360000:
        return { level: 8, nextGoal: 450000 };
      case beanTotal < 450000:
        return { level: 9, nextGoal: 550000 };
      case beanTotal < 550000:
        return { level: 10, nextGoal: 660000 };
      case beanTotal < 660000:
        return { level: 11, nextGoal: 780000 };
      case beanTotal < 780000:
        return { level: 12, nextGoal: 910000 };
      case beanTotal < 910000:
        return { level: 13, nextGoal: 1050000 };
      case beanTotal < 1050000:
        return { level: 14, nextGoal: 1200000 };
      case beanTotal < 1200000:
        return { level: 15, nextGoal: 1360000 };
      case beanTotal < 1360000:
        return { level: 16, nextGoal: 1530000 };
      case beanTotal < 1530000:
        return { level: 17, nextGoal: 1710000 };
      case beanTotal < 1710000:
        return { level: 18, nextGoal: 1900000 };
      case beanTotal < 1900000:
        return { level: 19, nextGoal: 2500000 };
      default:
        return { level: 0, nextGoal: 10000 };
    }
  };

  // TODO: (Maxim) use api.organizations
  const getOrCreateMapping = async (token: string, userStats: UserStatsResponseDto) =>
    await fetchJSON({
      url: '/api/billingCoordinator/organizations',
      method: 'GET',
      authToken: token,
      response: fetchJSON.as<unknown>,
      onBadResponse: async (resp) => {
        if (resp.status != 404) {
          log.error('Error getting customer', fetchJSON.error(resp));
          return;
        }
        return await fetchJSON({
          url: '/api/billingCoordinator/organizations',
          method: 'POST',
          authToken: token,
          response: fetchJSON.as<unknown>,
          body: { email: userStats.email },
        });
      },
    });

  const getPlanTypeId = (currentPlanTag?: string): number => {
    switch (true) {
      case currentPlanTag?.includes('starter'):
        return 20;
      case currentPlanTag?.includes('pro'):
        return 40;
      case currentPlanTag?.includes('staff'):
        return 69;
      case currentPlanTag?.includes('enterprise'):
        return 90;
      default:
        return 1;
    }
  };

  // fixes bug on user login not updating values
  useEffect(() => {
    if (!authToken) return;
    handleUserStatus();
    getUserPlan();
  }, [authToken]);

  const getUserPlan = async () => {
    try {
      // NOTE GET PLANS FOR PRICE PAGE HERE LATER BILL
      const userPlanResponse = await api.user.userControllerPlan();

      let subPrice = '0';
      let isMonthlySub = true;

      let isOg = false;
      try {
        const ogCutOffTimeUnix = 1681578058;
        const subscription = await api.subscriptions.subscriptionControllerGetSubscription();
        const subCreated = new Date(subscription.createdAt);
        isOg = Boolean(getUnixTime(subCreated) < ogCutOffTimeUnix);
        subPrice = subscription.unit.item.unit_amount_decimal;
        isMonthlySub = subscription.unit.annual ? false : true;
      } catch (err) {
        log.warnError('Unable to get subscription data', err);
        isOg = false;
      }

      setUser({
        isOg: isOg,
        plan: {
          ...userPlanResponse,
          planTypeId: getPlanTypeId(userPlanResponse.tag),
          price: Number(subPrice),
          isMonthly: isMonthlySub,
        },
      });

      const stage = await api.user.userControllerPlanStage();
      setStage(stage);
    } catch (error) {
      // TODO: (Kristam) WE NEED TO HANDLE THIS ERROR BETTER. SUPPRESSED FOR NOW TO STOP SPAMMING FE NOTIFICATIONS
      // ERRORS SHOULD PROVIDE FEEDBACK TO THE USER SO THEY KNOW WHAT TO DO, NO POINT SAYING
      // "Can't get userPlanStageDto" they don't know what that means -
      // user message should be human/actionable/clear
      log.error('Unable to get user planPlanStageDto', error);
    }
  };

  const handleUserStatus = async () => {
    try {
      const userStatusResponse = await api.user.userControllerUserStats();
      const levelAndGoal = userLevel(userStatusResponse.beanTotal);
      setBeanState({
        amount: userStatusResponse.beanTotal,
        goal: levelAndGoal.nextGoal,
        nextDate: userStatusResponse.nextClaimDate,
      });
      setUser({
        connectedAddress: userStatusResponse.address,
        email: userStatusResponse.email,
        id: userStatusResponse.userId,
        isEmailValidated: userStatusResponse?.isEmailValidated,
        lastLogin: userStatusResponse.lastLogged,
        level: levelAndGoal.level,
        profileId: userStatusResponse.profileId,
        validatedProtocols: userStatusResponse.validatedProtocols,
        addonLimits: userStatusResponse.addonLimits,
        purchasedTrialPlan: userStatusResponse.purchasedTrialPlan,
      });
      if (!customer && authToken) {
        const customer = await getOrCreateMapping(authToken, userStatusResponse);
        setCustomer(customer);
      }
    } catch (err) {
      log.warnError('[handleUserStatus] Error getting user stats', err);

      // NEED TO HANDLE THIS ERROR BETTER
      // SUPPRESSED FOR NOW TO STOP SPAMMING FE NOTIFICATIONS
      // SOMETIMES IT SENDS DOZENS OF THE SAME NOTIFICATION IN A ROW
      handleLogout({
        notification: undefined,
      });
    }
  };

  const getUserStats = async () => {
    if (!authToken) return;
    handleUserStatus();
    getUserPlan();
  };

  return { getUserStats, stage };
};
