/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Subscription } from '../models/Subscription';
import type { SubscriptionParamsDto } from '../models/SubscriptionParamsDto';
import type { SubscriptionUpdateOrganizationDto } from '../models/SubscriptionUpdateOrganizationDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class SubscriptionsService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @param requestBody
   * @returns Subscription
   * @returns any
   * @throws ApiError
   */
  public subscriptionControllerCreateSubscription(
    requestBody: SubscriptionParamsDto,
  ): CancelablePromise<Subscription | any> {
    return this.httpRequest.request({
      method: 'POST',
      url: 'billingCoordinator/subscriptions',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns Subscription
   * @throws ApiError
   */
  public subscriptionControllerGetSubscription(): CancelablePromise<Subscription> {
    return this.httpRequest.request({
      method: 'GET',
      url: 'billingCoordinator/subscriptions',
    });
  }

  /**
   * @param requestBody
   * @returns Subscription
   * @throws ApiError
   */
  public subscriptionControllerUpdateSubscription(requestBody: SubscriptionParamsDto): CancelablePromise<Subscription> {
    return this.httpRequest.request({
      method: 'PUT',
      url: 'billingCoordinator/subscriptions',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns Subscription
   * @throws ApiError
   */
  public subscriptionControllerCancelSubscription(): CancelablePromise<Subscription> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: 'billingCoordinator/subscriptions',
    });
  }

  /**
   * @param requestBody
   * @returns Subscription
   * @throws ApiError
   */
  public subscriptionControllerUpdateSubscriptionOrganization(
    requestBody: SubscriptionUpdateOrganizationDto,
  ): CancelablePromise<Subscription> {
    return this.httpRequest.request({
      method: 'PUT',
      url: 'billingCoordinator/subscriptions/update-org',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
}
