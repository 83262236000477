'use client';
import { FC } from 'react';
import { ButtonBase, IButtonProps } from '../ButtonBase';
import styles from './styles.module.scss';

const ButtonSources: FC<IButtonProps> = ({ className, ...props }: IButtonProps) => {
  return <ButtonBase className={`${styles.sources} ${className}`} {...props} />;
};

export default ButtonSources;
