import React, { useState } from 'react';
import Image from 'next/image';
import { ChainLogo } from '../ChainLogo';
import styles from './CoinLogo.module.scss';

interface ICoinLogoProps {
  chainId?: string;
  className?: string;
  name: string;
  size?: 'large' | 'medium' | 'small' | 'xs';
  src: string | undefined | null;
}

const CoinLogo = ({ chainId, name, size = 'medium', src, className }: ICoinLogoProps) => {
  const [isImgLoaded, setIsImgLoaded] = useState(false);
  return (
    <div className={`${styles.logo} ${styles[size]} ${className || ''}`}>
      {src && (
        <Image
          alt={name}
          className={`${styles.coin} ${isImgLoaded ? '' : styles.hidden}`}
          height={500}
          src={src}
          width={500}
          onError={() => setIsImgLoaded(false)}
          onLoad={() => setIsImgLoaded(true)}
        />
      )}
      {!isImgLoaded && <span role="img">{name.slice(0, 2)}</span>}
      {chainId && <ChainLogo chainId={chainId} className={styles.chain} />}
    </div>
  );
};

const CL = React.memo(CoinLogo);
CL.displayName = 'CoinLogo';
export default CL;
