import { utils } from 'ethers';
import { EnvApp } from './env/app';
import { log } from './featureFlag/log';

// devmode=1
const CONTROL_HASH = '0x0a260506bea4da4371005a3555620ccc01ca721fd54d642e5b1e7fc4674ffddb';
const DEV_MODE_KEY = 'MoralisMoney_devMode';
const DEV_MODE_VALUE = 'enabled';
export const NO_DEVMODE_SPELL = 'no devmode';
export const DEV_MODE_SPELL = 'devmode=1';

// localStorage.setItem('MoralisMoney.devMode', 'enabled');
export const isDevModeEnabled = () => {
  if (typeof window === 'undefined') {
    return false;
  }
  return window.localStorage.getItem(DEV_MODE_KEY) === DEV_MODE_VALUE;
};

export const isValidDevModeCode = (code: string) => {
  const hash = utils.sha256(utils.toUtf8Bytes(code));
  return hash === CONTROL_HASH;
};

export const enableDevMode = () => {
  if (typeof window === 'undefined') {
    return false;
  }
  log.info('Developer mode enabled', { DEV_MODE_KEY, DEV_MODE_VALUE });
  window.localStorage.setItem(DEV_MODE_KEY, DEV_MODE_VALUE);
  window.location.reload();
};

export const disableDevMode = () => {
  if (typeof window === 'undefined') {
    return false;
  }
  log.info('Developer mode disabled', { DEV_MODE_KEY, DEV_MODE_VALUE });
  window.localStorage.removeItem(DEV_MODE_KEY);
  window.location.reload();
};

export const isDevDomain = () => {
  if (typeof window !== 'undefined') {
    // Use a regex to check if the hostname starts with 'feat' or 'fix'
    return /^(feat|fix)/.test(window.location.hostname);
  }
  return false;
};

export const isDevEnv = () => EnvApp.isDevEnv;
