'use client';
import { Magic } from 'magic-sdk';
import { OAuthExtension } from '@magic-ext/oauth';
import { EnvMagic } from './env/magic';

export type MagicInstance = ReturnType<typeof createMagic>;
export const createMagic = () => new Magic(EnvMagic.publicAuthKey, { extensions: [new OAuthExtension()] });

export const loginWithEmailOTP = async (magic: MagicInstance, email: string) =>
  await magic.auth.loginWithEmailOTP({
    email,
    showUI: true,
  });
