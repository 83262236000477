import create from 'zustand';
import AnalyticsTracker from '@/utils/tracking';

type TAnalyticsStore = {
  setTracker: (tracker: AnalyticsTracker) => void;
  tracker: AnalyticsTracker | undefined;
};

const useAnalyticsStore = create<TAnalyticsStore>((set) => {
  return {
    tracker: undefined,
    setTracker: (tracker: AnalyticsTracker) => {
      return set({ tracker });
    },
  };
});

export default useAnalyticsStore;
