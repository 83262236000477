import { useState } from 'react';
import { useRouter } from 'next/router';
import { Modal } from 'moralis-ui';
import { useConnectWallet } from '@/helpers/hooks/useConnectWallet';
import { useAccountSelector } from '../accountSelector/hooks/useAccountSelector';
import { useAccountSelectorStore } from '../accountSelector/utils/accountSelectorStore';
import { portfolioURL } from '../portfolio/lib/portfolioURL';
import { useAddConnectedWallet } from './hooks/useAddConnectedWallet';
import { useAddWallet } from './hooks/useAddWallet';
import { EAdditionMethod, EPortfolioList } from './libs/types';
import { manageWalletStore } from './stores/manageWalletStores';
import { canAddNewWallet } from './utils/canAddNewWallet';
import { AddWalletFormUI, TAddWalletTo } from './AddWalletFormUI';

export const AddWallet = () => {
  const [addToList, setAddToList] = useState<TAddWalletTo | null>(null);
  const { setIsAddWalletModalOpen, isAddWalletModalOpen } = manageWalletStore((state) => state);
  const { handleWalletConnection, isConnected } = useConnectWallet();
  const { isAdding, mergedLists, isLoadingEnsName, searchTerm, setSearchTerm, addToPortfolio, addToWatchList } =
    useAddWallet();
  useAddConnectedWallet();
  const router = useRouter();
  const hasAlreadyAddedThatWallet = Boolean(
    mergedLists && mergedLists.find((wallet) => wallet.apiData.address.toLowerCase() === searchTerm.toLowerCase()),
  );
  const isVisible = isAddWalletModalOpen && Boolean(mergedLists) && canAddNewWallet(mergedLists);

  const { setActiveSelection } = useAccountSelectorStore();
  const { setTabId } = useAccountSelector();

  const goto = (listId: EPortfolioList) => {
    setIsAddWalletModalOpen(false);
    setAddToList(null);
    setActiveSelection({
      id: searchTerm,
      list: listId,
    });
    setTabId(listId);
    if (router.pathname === portfolioURL.root) {
      router.push(portfolioURL.tokens(searchTerm));
    }
  };

  const handleAddToPortfolio = async () => {
    setAddToList('portfolio');
    await addToPortfolio(EAdditionMethod.WATCHED);
    goto(EPortfolioList.MY);
  };

  const handleAddToWatched = async () => {
    setAddToList('watchList');
    await addToWatchList();
    goto(EPortfolioList.WATCHED);
  };

  return (
    <Modal
      idToAppend="add-wallet-modal"
      isVisible={isVisible}
      key={`${mergedLists?.length}-${isAddWalletModalOpen}`}
      slots={{
        footer: null,
        header: <p className="subtitle-20 color-body-default"> Add Wallet </p>,
      }}
      trigger={null}
      width="542px"
      onChange={(isVisible) => {
        if (!isVisible && isAddWalletModalOpen) {
          setIsAddWalletModalOpen(false);
        }
        if (!isVisible && searchTerm) {
          setSearchTerm('');
        }
      }}
    >
      <AddWalletFormUI
        addToList={addToList}
        isLoadingEnsName={isLoadingEnsName}
        hasAlreadyAddedThatWallet={hasAlreadyAddedThatWallet}
        searchTerm={searchTerm}
        isAdding={isAdding}
        isWalletConnected={isConnected}
        onAddWallet={handleAddToPortfolio}
        onAddToWatchlist={handleAddToWatched}
        onChangeInput={setSearchTerm}
        onConnectWallet={() => {
          handleWalletConnection();
          setIsAddWalletModalOpen(false);
        }}
      />
    </Modal>
  );
};
