import { ISvgProps } from './types';

const Base = ({ width = '120', height = '120', ...props }: ISvgProps) => {
  return (
    <svg height={height} viewBox="0 0 160 160" width={width} {...props} fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#a)">
        <mask id="b" maskUnits="userSpaceOnUse" x="38" y="38" width="84" height="84">
          <path d="M122 38H38v84h84V38Z" fill="#fff" />
        </mask>
        <g mask="url(#b)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M79.917 122C103.16 122 122 103.196 122 80s-18.841-42-42.083-42C58.2 38 40.323 54.42 38.073 75.5h55.37v8.25H38C39.9 105.189 57.942 122 79.917 122Z"
            fill="#fff"
          />
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" transform="translate(38 38)" d="M0 0h84v84H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Base;
