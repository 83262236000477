export const language = {
  en: {
    update: (name: string) => `Strategy alert "${name}" successfully updated!`,
    create: (name: string) => `Strategy alert "${name}" successfully created!`,
    deleteStrategy: (name: string) => `Saved strategy "${name}" successfully deleted!`,
    toggleStrategy: (name: string, enabled: boolean) =>
      `Strategy alert "${name}" successfully ${enabled ? 'enabled' : 'disabled'}!`,
    deleteAlert: (name: string) => `Strategy alert "${name}" successfully deleted!`,
  },
};
