import create from 'zustand';
import { persist } from 'zustand/middleware';
import { DEFAULT_AVATAR, TTab } from '@/components/beans/types';
import { UserChestResponseDto, UserItemResponseDto } from '../services';

type TRewardsStore = {
  rewards: {
    avatar: UserItemResponseDto;
    chests: UserChestResponseDto[];
    items: UserItemResponseDto[];
    magicBeans: UserItemResponseDto;
  };
  setRewards: (rewards: {
    avatar: UserItemResponseDto;
    chests: UserChestResponseDto[];
    items: UserItemResponseDto[];
    magicBeans: UserItemResponseDto;
  }) => void;
  setShowChangeWalletModal: (showChangeWalletModal: boolean) => void;
  setTab: (tab: TTab) => void;
  showChangeWalletModal: boolean;
  tab: TTab;
};

const useRewardsStore = create(
  persist<TRewardsStore>(
    (set) => {
      return {
        tab: 'chests',

        setTab: (tab) => {
          return set(() => {
            return { tab };
          });
        },
        showChangeWalletModal: false,
        setShowChangeWalletModal: (showChangeWalletModal) => {
          return set(() => {
            return { showChangeWalletModal };
          });
        },
        chests: [],
        rewards: {
          avatar: DEFAULT_AVATAR,
          chests: [],
          items: [],
          magicBeans: {} as UserItemResponseDto,
        },
        setRewards: (rewards) => {
          return set(() => {
            return { rewards };
          });
        },
      };
    },
    {
      name: 'rewards-store',
      getStorage: () => {
        return localStorage;
      },
    },
  ),
);

export default useRewardsStore;
