/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { TToken } from './TToken';

export type Sorting = {
  metric?: TToken | 'daysSinceMinted' | 'chainId';
  type?: Sorting.type;
};

export namespace Sorting {
  export enum type {
    ASC = 'ASC',
    DESC = 'DESC',
  }
}
