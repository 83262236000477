import { ButtonDark, ButtonDestructive } from '@/components/core/Button';
import { Modal, ModalClose, ModalContent } from '@/components/core/Modal';
import { IDeleteModalProps, useAlertModalContext } from '../../context';
import { language } from './values';
import styles from './styles.module.scss';
import typography from '@/styles/scss/typography.module.scss';

const DeleteAlertModal = () => {
  const { modalContent, closeAlertModal, modalProps } = useAlertModalContext<IDeleteModalProps>();

  if (modalContent !== 'delete') return null;

  return (
    <Modal initialOpen={true} onOpenChange={closeAlertModal}>
      <ModalContent className={styles.deleteAlertModal}>
        <div className={styles.modalHeader}>
          <h3 className={typography.h3}>{language.en.confirmDeletion}</h3>
          <ModalClose />
        </div>
        <div className={styles.modalFooter}>
          <p className={typography.digit24}>
            {language.en.areYouUseYouWantToDelete}
            <br />
            {`"${modalProps?.name}"?`}
          </p>
          <h4>{language.en.thereIsNoWayToUndoThisAction}</h4>
          <div className={styles.buttons}>
            <ButtonDark size="large" onClick={closeAlertModal}>
              {language.en.cancel}
            </ButtonDark>
            <ButtonDestructive
              size="large"
              onClick={() => {
                modalProps?.onDelete();
                closeAlertModal();
              }}
            >
              {language.en.yesDelete}
            </ButtonDestructive>
          </div>
        </div>
      </ModalContent>
    </Modal>
  );
};

export default DeleteAlertModal;
