'use client';
import { FC } from 'react';
import { IInputProps } from './types';
import styles from './InputStyles.module.scss';

const Input: FC<IInputProps> = ({
  autoComplete = true,
  autoFocus = false,
  disabled = false,
  hasError,
  id,
  label,
  max,
  maxLength,
  min,
  minLength,
  name,
  onBlur,
  onChange,
  onFocus,
  pattern,
  placeholder,
  required,
  style,
  testid,
  type = 'text',
  value,
  ...props
}) => {
  return (
    <label className={`input-label ${styles.label} ${hasError && styles.error}`}>
      {label && <span className={`input-label-text ${styles.span}`}>{label}</span>}
      <input
        autoComplete={`${autoComplete}`}
        autoFocus={autoFocus}
        className={`input ${styles.input}`}
        data-testid={testid}
        disabled={disabled}
        id={id}
        max={type === 'number' ? max : undefined}
        maxLength={maxLength}
        min={type === 'number' ? min : undefined}
        minLength={minLength}
        name={name || type + ' input'}
        pattern={pattern}
        placeholder={placeholder}
        required={required}
        style={{ ...style }}
        type={type}
        value={value}
        onBlur={(event: React.FocusEvent<HTMLInputElement>) => onBlur && onBlur(event)}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChange && onChange(event)}
        onFocus={(event: React.FocusEvent<HTMLInputElement>) => onFocus && onFocus(event)}
        {...props}
      />
    </label>
  );
};

export default Input;
