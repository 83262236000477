/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Customer } from '../models/Customer';
import type { CustomerCreateMoneyDto } from '../models/CustomerCreateMoneyDto';
import type { CustomerUpdateDto } from '../models/CustomerUpdateDto';
import type { StripeCustomerDto } from '../models/StripeCustomerDto';
import type { TaxIdCreateDto } from '../models/TaxIdCreateDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class OrganizationsService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @param requestBody
   * @returns StripeCustomerDto
   * @throws ApiError
   */
  public organizationControllerCreate(requestBody: CustomerCreateMoneyDto): CancelablePromise<StripeCustomerDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: 'billingCoordinator/organizations',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns Customer
   * @throws ApiError
   */
  public organizationControllerGet(): CancelablePromise<Customer> {
    return this.httpRequest.request({
      method: 'GET',
      url: 'billingCoordinator/organizations',
    });
  }

  /**
   * @param requestBody
   * @returns StripeCustomerDto
   * @throws ApiError
   */
  public organizationControllerUpdate(requestBody: CustomerUpdateDto): CancelablePromise<StripeCustomerDto> {
    return this.httpRequest.request({
      method: 'PUT',
      url: 'billingCoordinator/organizations',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns StripeCustomerDto
   * @throws ApiError
   */
  public organizationControllerGetCustomer(): CancelablePromise<StripeCustomerDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: 'billingCoordinator/organizations/customer',
    });
  }

  /**
   * @param requestBody
   * @returns TaxIdCreateDto
   * @returns any
   * @throws ApiError
   */
  public organizationControllerCreateTaxId(requestBody: TaxIdCreateDto): CancelablePromise<TaxIdCreateDto | any> {
    return this.httpRequest.request({
      method: 'POST',
      url: 'billingCoordinator/organizations/tax_ids',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param taxId
   * @returns TaxIdCreateDto
   * @throws ApiError
   */
  public organizationControllerDelete(taxId: string): CancelablePromise<TaxIdCreateDto> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: 'billingCoordinator/organizations/tax_ids/{taxId}',
      path: {
        taxId: taxId,
      },
    });
  }
}
