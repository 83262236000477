import { ISwitchProps } from './types';
import styles from './styles.module.scss';

const Switch: React.FC<ISwitchProps> = ({
  checked,
  className = '',
  disabled = false,
  id,
  onChange,
  onBlur,
  labelLeft,
  labelRight,
  hasError,
  ...props
}) => {
  const labelId = `label-${id}`;
  return (
    <label
      className={`${styles.switch} ${disabled ? styles.disabled : ''} ${className} ${hasError ? styles.error : ''}`}
      htmlFor={id}
    >
      {labelLeft && (
        <div className={styles.labels}>
          <span id={labelId}>{labelLeft}</span>
        </div>
      )}
      <input
        aria-checked={checked}
        aria-labelledby={labelId}
        checked={checked}
        disabled={disabled}
        id={id}
        role="switch"
        type="checkbox"
        onBlur={onBlur}
        onChange={onChange}
        {...props}
      />
      {labelRight && (
        <div className={styles.labels}>
          <span id={labelId}>{labelRight}</span>
        </div>
      )}
    </label>
  );
};

export default Switch;
