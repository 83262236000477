import { ISvgProps } from './types';

const Solana = ({ width = '120', height = '120', ...props }: ISvgProps) => {
  return (
    <svg width={width} height={height} viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M51.6468 97.7366C52.1536 97.23 52.8505 96.9346 53.5897 96.9346H120.62C121.845 96.9346 122.457 98.412 121.591 99.2774L108.35 112.511C107.843 113.018 107.146 113.313 106.407 113.313H39.3769C38.152 113.313 37.5395 111.836 38.4054 110.971L51.6468 97.7366Z"
        fill="white"
      />
      <path
        d="M51.6467 48.3259C52.1747 47.8193 52.8716 47.5238 53.5896 47.5238H120.62C121.845 47.5238 122.457 49.0013 121.591 49.8666L108.35 63.1005C107.843 63.6071 107.146 63.9026 106.407 63.9026H39.3768C38.152 63.9026 37.5395 62.4251 38.4054 61.5598L51.6467 48.3259Z"
        fill="white"
      />
      <path
        d="M108.35 72.873C107.843 72.3664 107.146 72.0709 106.407 72.0709H39.3769C38.152 72.0709 37.5395 73.5484 38.4054 74.4138L51.6468 87.6477C52.1536 88.1542 52.8505 88.4497 53.5897 88.4497H120.62C121.845 88.4497 122.457 86.9722 121.591 86.1069L108.35 72.873Z"
        fill="white"
      />
    </svg>
  );
};

export default Solana;
