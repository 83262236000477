import { Controller, useFormContext } from 'react-hook-form';
import { Select, TSelectOption } from '@/components/core/Select';
import { IStrategyAlertFormData, ITokenAlertFormData } from '../../organisms';
import { language } from './values';
import commonStyles from '../styles.module.scss';
import styles from './styles.module.scss';
import typography from '@/styles/scss/typography.module.scss';

export interface IFrequencyFieldProps {
  disabled?: boolean;
  options: TSelectOption[];
}

const FrequencyField = ({ options }: IFrequencyFieldProps) => {
  const { control } = useFormContext<ITokenAlertFormData | IStrategyAlertFormData>();

  return (
    <div>
      <div className={commonStyles.col25}>
        <h5 className={typography.bodySmallRegular} style={{ lineHeight: '40px' }}>
          {language.en.title}
        </h5>
      </div>
      <Controller
        control={control}
        name="selectCoolDown"
        render={({ field, fieldState: { invalid, error } }) => (
          <div className={`${commonStyles.col75} ${styles.selectWrapper}`}>
            <Select
              hasError={invalid}
              id={field.name}
              options={options}
              placeholder={language.en.placeholder}
              {...field}
            />
            {/* TODO: remove when Select will support errorMessage natively */}
            {error?.message && <span className="caption-12-regular color-input-error">{error?.message}</span>}
          </div>
        )}
        rules={{
          required: true,
          validate: (value) => {
            if (options.find((option) => option.id === value)?.disabled) {
              return language.en.invalidAlertFrequency;
            }
            return true;
          },
        }}
      />
    </div>
  );
};

export default FrequencyField;
