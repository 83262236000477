/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { UsageResponseDto } from '../models/UsageResponseDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class UsagesService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @param service
   * @returns UsageResponseDto
   * @throws ApiError
   */
  public usageControllerGetUsagesByService(service: string): CancelablePromise<UsageResponseDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: 'billingCoordinator/usages/{service}',
      path: {
        service: service,
      },
    });
  }

  /**
   * @returns UsageResponseDto
   * @throws ApiError
   */
  public usageControllerGetAllUsages(): CancelablePromise<UsageResponseDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: 'billingCoordinator/usages',
    });
  }
}
