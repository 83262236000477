/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum TToken {
  TEN_MINUTES_HOLDERS_CHANGE = 'TenMinutesHoldersChange',
  TEN_MINUTES_BUYERS_CHANGE = 'TenMinutesBuyersChange',
  TEN_MINUTES_EXPERIENCED_BUYERS_CHANGE = 'TenMinutesExperiencedBuyersChange',
  TEN_MINUTES_LIQUIDITY_CHANGE = 'TenMinutesLiquidityChange',
  TEN_MINUTES_LIQUIDITY_CHANGE_USD = 'TenMinutesLiquidityChangeUSD',
  TEN_MINUTES_SELLERS_CHANGE = 'TenMinutesSellersChange',
  TEN_MINUTES_EXPERIENCED_SELLERS_CHANGE = 'TenMinutesExperiencedSellersChange',
  TEN_MINUTES_NET_BUYERS_CHANGE = 'TenMinutesNetBuyersChange',
  TEN_MINUTES_EXPERIENCED_NET_BUYERS_CHANGE = 'TenMinutesExperiencedNetBuyersChange',
  TEN_MINUTES_PRICE_PERCENT_CHANGE_USD = 'TenMinutesPricePercentChangeUSD',
  TEN_MINUTES_VOLUME_CHANGE_USD = 'TenMinutesVolumeChangeUSD',
  TEN_MINUTES_BUYERS_VOLUME_CHANGE_USD = 'TenMinutesBuyersVolumeChangeUSD',
  TEN_MINUTES_SELLERS_VOLUME_CHANGE_USD = 'TenMinutesSellersVolumeChangeUSD',
  TEN_MINUTES_NET_VOLUME_CHANGE_USD = 'TenMinutesNetVolumeChangeUSD',
  TEN_MINUTES_NUMBER_OF_RATINGS_CHANGE = 'TenMinutesNumberOfRatingsChange',
  THIRTY_MINUTES_HOLDERS_CHANGE = 'ThirtyMinutesHoldersChange',
  THIRTY_MINUTES_BUYERS_CHANGE = 'ThirtyMinutesBuyersChange',
  THIRTY_MINUTES_EXPERIENCED_BUYERS_CHANGE = 'ThirtyMinutesExperiencedBuyersChange',
  THIRTY_MINUTES_LIQUIDITY_CHANGE = 'ThirtyMinutesLiquidityChange',
  THIRTY_MINUTES_LIQUIDITY_CHANGE_USD = 'ThirtyMinutesLiquidityChangeUSD',
  THIRTY_MINUTES_SELLERS_CHANGE = 'ThirtyMinutesSellersChange',
  THIRTY_MINUTES_EXPERIENCED_SELLERS_CHANGE = 'ThirtyMinutesExperiencedSellersChange',
  THIRTY_MINUTES_NET_BUYERS_CHANGE = 'ThirtyMinutesNetBuyersChange',
  THIRTY_MINUTES_EXPERIENCED_NET_BUYERS_CHANGE = 'ThirtyMinutesExperiencedNetBuyersChange',
  THIRTY_MINUTES_PRICE_PERCENT_CHANGE_USD = 'ThirtyMinutesPricePercentChangeUSD',
  THIRTY_MINUTES_VOLUME_CHANGE_USD = 'ThirtyMinutesVolumeChangeUSD',
  THIRTY_MINUTES_BUYERS_VOLUME_CHANGE_USD = 'ThirtyMinutesBuyersVolumeChangeUSD',
  THIRTY_MINUTES_SELLERS_VOLUME_CHANGE_USD = 'ThirtyMinutesSellersVolumeChangeUSD',
  THIRTY_MINUTES_NET_VOLUME_CHANGE_USD = 'ThirtyMinutesNetVolumeChangeUSD',
  THIRTY_MINUTES_NUMBER_OF_RATINGS_CHANGE = 'ThirtyMinutesNumberOfRatingsChange',
  ONE_HOUR_HOLDERS_CHANGE = 'OneHourHoldersChange',
  ONE_HOUR_BUYERS_CHANGE = 'OneHourBuyersChange',
  ONE_HOUR_EXPERIENCED_BUYERS_CHANGE = 'OneHourExperiencedBuyersChange',
  ONE_HOUR_LIQUIDITY_CHANGE = 'OneHourLiquidityChange',
  ONE_HOUR_LIQUIDITY_CHANGE_USD = 'OneHourLiquidityChangeUSD',
  ONE_HOUR_SELLERS_CHANGE = 'OneHourSellersChange',
  ONE_HOUR_EXPERIENCED_SELLERS_CHANGE = 'OneHourExperiencedSellersChange',
  ONE_HOUR_NET_BUYERS_CHANGE = 'OneHourNetBuyersChange',
  ONE_HOUR_EXPERIENCED_NET_BUYERS_CHANGE = 'OneHourExperiencedNetBuyersChange',
  ONE_HOUR_PRICE_PERCENT_CHANGE_USD = 'OneHourPricePercentChangeUSD',
  ONE_HOUR_VOLUME_CHANGE_USD = 'OneHourVolumeChangeUSD',
  ONE_HOUR_BUYERS_VOLUME_CHANGE_USD = 'OneHourBuyersVolumeChangeUSD',
  ONE_HOUR_SELLERS_VOLUME_CHANGE_USD = 'OneHourSellersVolumeChangeUSD',
  ONE_HOUR_NET_VOLUME_CHANGE_USD = 'OneHourNetVolumeChangeUSD',
  ONE_HOUR_NUMBER_OF_RATINGS_CHANGE = 'OneHourNumberOfRatingsChange',
  FOUR_HOURS_HOLDERS_CHANGE = 'FourHoursHoldersChange',
  FOUR_HOURS_BUYERS_CHANGE = 'FourHoursBuyersChange',
  FOUR_HOURS_EXPERIENCED_BUYERS_CHANGE = 'FourHoursExperiencedBuyersChange',
  FOUR_HOURS_LIQUIDITY_CHANGE = 'FourHoursLiquidityChange',
  FOUR_HOURS_LIQUIDITY_CHANGE_USD = 'FourHoursLiquidityChangeUSD',
  FOUR_HOURS_SELLERS_CHANGE = 'FourHoursSellersChange',
  FOUR_HOURS_EXPERIENCED_SELLERS_CHANGE = 'FourHoursExperiencedSellersChange',
  FOUR_HOURS_NET_BUYERS_CHANGE = 'FourHoursNetBuyersChange',
  FOUR_HOURS_EXPERIENCED_NET_BUYERS_CHANGE = 'FourHoursExperiencedNetBuyersChange',
  FOUR_HOURS_PRICE_PERCENT_CHANGE_USD = 'FourHoursPricePercentChangeUSD',
  FOUR_HOURS_VOLUME_CHANGE_USD = 'FourHoursVolumeChangeUSD',
  FOUR_HOURS_BUYERS_VOLUME_CHANGE_USD = 'FourHoursBuyersVolumeChangeUSD',
  FOUR_HOURS_SELLERS_VOLUME_CHANGE_USD = 'FourHoursSellersVolumeChangeUSD',
  FOUR_HOURS_NET_VOLUME_CHANGE_USD = 'FourHoursNetVolumeChangeUSD',
  FOUR_HOURS_NUMBER_OF_RATINGS_CHANGE = 'FourHoursNumberOfRatingsChange',
  TWELVE_HOURS_HOLDERS_CHANGE = 'TwelveHoursHoldersChange',
  TWELVE_HOURS_BUYERS_CHANGE = 'TwelveHoursBuyersChange',
  TWELVE_HOURS_EXPERIENCED_BUYERS_CHANGE = 'TwelveHoursExperiencedBuyersChange',
  TWELVE_HOURS_LIQUIDITY_CHANGE = 'TwelveHoursLiquidityChange',
  TWELVE_HOURS_LIQUIDITY_CHANGE_USD = 'TwelveHoursLiquidityChangeUSD',
  TWELVE_HOURS_SELLERS_CHANGE = 'TwelveHoursSellersChange',
  TWELVE_HOURS_EXPERIENCED_SELLERS_CHANGE = 'TwelveHoursExperiencedSellersChange',
  TWELVE_HOURS_NET_BUYERS_CHANGE = 'TwelveHoursNetBuyersChange',
  TWELVE_HOURS_EXPERIENCED_NET_BUYERS_CHANGE = 'TwelveHoursExperiencedNetBuyersChange',
  TWELVE_HOURS_PRICE_PERCENT_CHANGE_USD = 'TwelveHoursPricePercentChangeUSD',
  TWELVE_HOURS_VOLUME_CHANGE_USD = 'TwelveHoursVolumeChangeUSD',
  TWELVE_HOURS_BUYERS_VOLUME_CHANGE_USD = 'TwelveHoursBuyersVolumeChangeUSD',
  TWELVE_HOURS_SELLERS_VOLUME_CHANGE_USD = 'TwelveHoursSellersVolumeChangeUSD',
  TWELVE_HOURS_NET_VOLUME_CHANGE_USD = 'TwelveHoursNetVolumeChangeUSD',
  TWELVE_HOURS_NUMBER_OF_RATINGS_CHANGE = 'TwelveHoursNumberOfRatingsChange',
  ONE_DAY_HOLDERS_CHANGE = 'OneDayHoldersChange',
  ONE_DAY_BUYERS_CHANGE = 'OneDayBuyersChange',
  ONE_DAY_EXPERIENCED_BUYERS_CHANGE = 'OneDayExperiencedBuyersChange',
  ONE_DAY_LIQUIDITY_CHANGE = 'OneDayLiquidityChange',
  ONE_DAY_LIQUIDITY_CHANGE_USD = 'OneDayLiquidityChangeUSD',
  ONE_DAY_SELLERS_CHANGE = 'OneDaySellersChange',
  ONE_DAY_EXPERIENCED_SELLERS_CHANGE = 'OneDayExperiencedSellersChange',
  ONE_DAY_NET_BUYERS_CHANGE = 'OneDayNetBuyersChange',
  ONE_DAY_EXPERIENCED_NET_BUYERS_CHANGE = 'OneDayExperiencedNetBuyersChange',
  ONE_DAY_PRICE_PERCENT_CHANGE_USD = 'OneDayPricePercentChangeUSD',
  ONE_DAY_VOLUME_CHANGE_USD = 'OneDayVolumeChangeUSD',
  ONE_DAY_BUYERS_VOLUME_CHANGE_USD = 'OneDayBuyersVolumeChangeUSD',
  ONE_DAY_SELLERS_VOLUME_CHANGE_USD = 'OneDaySellersVolumeChangeUSD',
  ONE_DAY_NET_VOLUME_CHANGE_USD = 'OneDayNetVolumeChangeUSD',
  ONE_DAY_NUMBER_OF_RATINGS_CHANGE = 'OneDayNumberOfRatingsChange',
  TWO_DAYS_HOLDERS_CHANGE = 'TwoDaysHoldersChange',
  TWO_DAYS_BUYERS_CHANGE = 'TwoDaysBuyersChange',
  TWO_DAYS_EXPERIENCED_BUYERS_CHANGE = 'TwoDaysExperiencedBuyersChange',
  TWO_DAYS_LIQUIDITY_CHANGE = 'TwoDaysLiquidityChange',
  TWO_DAYS_LIQUIDITY_CHANGE_USD = 'TwoDaysLiquidityChangeUSD',
  TWO_DAYS_SELLERS_CHANGE = 'TwoDaysSellersChange',
  TWO_DAYS_EXPERIENCED_SELLERS_CHANGE = 'TwoDaysExperiencedSellersChange',
  TWO_DAYS_NET_BUYERS_CHANGE = 'TwoDaysNetBuyersChange',
  TWO_DAYS_EXPERIENCED_NET_BUYERS_CHANGE = 'TwoDaysExperiencedNetBuyersChange',
  TWO_DAYS_PRICE_PERCENT_CHANGE_USD = 'TwoDaysPricePercentChangeUSD',
  TWO_DAYS_VOLUME_CHANGE_USD = 'TwoDaysVolumeChangeUSD',
  TWO_DAYS_BUYERS_VOLUME_CHANGE_USD = 'TwoDaysBuyersVolumeChangeUSD',
  TWO_DAYS_SELLERS_VOLUME_CHANGE_USD = 'TwoDaysSellersVolumeChangeUSD',
  TWO_DAYS_NET_VOLUME_CHANGE_USD = 'TwoDaysNetVolumeChangeUSD',
  TWO_DAYS_NUMBER_OF_RATINGS_CHANGE = 'TwoDaysNumberOfRatingsChange',
  FOUR_DAYS_HOLDERS_CHANGE = 'FourDaysHoldersChange',
  FOUR_DAYS_BUYERS_CHANGE = 'FourDaysBuyersChange',
  FOUR_DAYS_EXPERIENCED_BUYERS_CHANGE = 'FourDaysExperiencedBuyersChange',
  FOUR_DAYS_LIQUIDITY_CHANGE = 'FourDaysLiquidityChange',
  FOUR_DAYS_LIQUIDITY_CHANGE_USD = 'FourDaysLiquidityChangeUSD',
  FOUR_DAYS_SELLERS_CHANGE = 'FourDaysSellersChange',
  FOUR_DAYS_EXPERIENCED_SELLERS_CHANGE = 'FourDaysExperiencedSellersChange',
  FOUR_DAYS_NET_BUYERS_CHANGE = 'FourDaysNetBuyersChange',
  FOUR_DAYS_EXPERIENCED_NET_BUYERS_CHANGE = 'FourDaysExperiencedNetBuyersChange',
  FOUR_DAYS_PRICE_PERCENT_CHANGE_USD = 'FourDaysPricePercentChangeUSD',
  FOUR_DAYS_VOLUME_CHANGE_USD = 'FourDaysVolumeChangeUSD',
  FOUR_DAYS_BUYERS_VOLUME_CHANGE_USD = 'FourDaysBuyersVolumeChangeUSD',
  FOUR_DAYS_SELLERS_VOLUME_CHANGE_USD = 'FourDaysSellersVolumeChangeUSD',
  FOUR_DAYS_NET_VOLUME_CHANGE_USD = 'FourDaysNetVolumeChangeUSD',
  FOUR_DAYS_NUMBER_OF_RATINGS_CHANGE = 'FourDaysNumberOfRatingsChange',
  ONE_WEEK_HOLDERS_CHANGE = 'OneWeekHoldersChange',
  ONE_WEEK_BUYERS_CHANGE = 'OneWeekBuyersChange',
  ONE_WEEK_EXPERIENCED_BUYERS_CHANGE = 'OneWeekExperiencedBuyersChange',
  ONE_WEEK_LIQUIDITY_CHANGE = 'OneWeekLiquidityChange',
  ONE_WEEK_LIQUIDITY_CHANGE_USD = 'OneWeekLiquidityChangeUSD',
  ONE_WEEK_SELLERS_CHANGE = 'OneWeekSellersChange',
  ONE_WEEK_EXPERIENCED_SELLERS_CHANGE = 'OneWeekExperiencedSellersChange',
  ONE_WEEK_NET_BUYERS_CHANGE = 'OneWeekNetBuyersChange',
  ONE_WEEK_EXPERIENCED_NET_BUYERS_CHANGE = 'OneWeekExperiencedNetBuyersChange',
  ONE_WEEK_PRICE_PERCENT_CHANGE_USD = 'OneWeekPricePercentChangeUSD',
  ONE_WEEK_VOLUME_CHANGE_USD = 'OneWeekVolumeChangeUSD',
  ONE_WEEK_BUYERS_VOLUME_CHANGE_USD = 'OneWeekBuyersVolumeChangeUSD',
  ONE_WEEK_SELLERS_VOLUME_CHANGE_USD = 'OneWeekSellersVolumeChangeUSD',
  ONE_WEEK_NET_VOLUME_CHANGE_USD = 'OneWeekNetVolumeChangeUSD',
  ONE_WEEK_NUMBER_OF_RATINGS_CHANGE = 'OneWeekNumberOfRatingsChange',
  TWO_WEEKS_HOLDERS_CHANGE = 'TwoWeeksHoldersChange',
  TWO_WEEKS_BUYERS_CHANGE = 'TwoWeeksBuyersChange',
  TWO_WEEKS_EXPERIENCED_BUYERS_CHANGE = 'TwoWeeksExperiencedBuyersChange',
  TWO_WEEKS_LIQUIDITY_CHANGE = 'TwoWeeksLiquidityChange',
  TWO_WEEKS_LIQUIDITY_CHANGE_USD = 'TwoWeeksLiquidityChangeUSD',
  TWO_WEEKS_SELLERS_CHANGE = 'TwoWeeksSellersChange',
  TWO_WEEKS_EXPERIENCED_SELLERS_CHANGE = 'TwoWeeksExperiencedSellersChange',
  TWO_WEEKS_NET_BUYERS_CHANGE = 'TwoWeeksNetBuyersChange',
  TWO_WEEKS_EXPERIENCED_NET_BUYERS_CHANGE = 'TwoWeeksExperiencedNetBuyersChange',
  TWO_WEEKS_PRICE_PERCENT_CHANGE_USD = 'TwoWeeksPricePercentChangeUSD',
  TWO_WEEKS_VOLUME_CHANGE_USD = 'TwoWeeksVolumeChangeUSD',
  TWO_WEEKS_BUYERS_VOLUME_CHANGE_USD = 'TwoWeeksBuyersVolumeChangeUSD',
  TWO_WEEKS_SELLERS_VOLUME_CHANGE_USD = 'TwoWeeksSellersVolumeChangeUSD',
  TWO_WEEKS_NET_VOLUME_CHANGE_USD = 'TwoWeeksNetVolumeChangeUSD',
  TWO_WEEKS_NUMBER_OF_RATINGS_CHANGE = 'TwoWeeksNumberOfRatingsChange',
  THREE_WEEKS_HOLDERS_CHANGE = 'ThreeWeeksHoldersChange',
  THREE_WEEKS_BUYERS_CHANGE = 'ThreeWeeksBuyersChange',
  THREE_WEEKS_EXPERIENCED_BUYERS_CHANGE = 'ThreeWeeksExperiencedBuyersChange',
  THREE_WEEKS_LIQUIDITY_CHANGE = 'ThreeWeeksLiquidityChange',
  THREE_WEEKS_LIQUIDITY_CHANGE_USD = 'ThreeWeeksLiquidityChangeUSD',
  THREE_WEEKS_SELLERS_CHANGE = 'ThreeWeeksSellersChange',
  THREE_WEEKS_EXPERIENCED_SELLERS_CHANGE = 'ThreeWeeksExperiencedSellersChange',
  THREE_WEEKS_NET_BUYERS_CHANGE = 'ThreeWeeksNetBuyersChange',
  THREE_WEEKS_EXPERIENCED_NET_BUYERS_CHANGE = 'ThreeWeeksExperiencedNetBuyersChange',
  THREE_WEEKS_PRICE_PERCENT_CHANGE_USD = 'ThreeWeeksPricePercentChangeUSD',
  THREE_WEEKS_VOLUME_CHANGE_USD = 'ThreeWeeksVolumeChangeUSD',
  THREE_WEEKS_BUYERS_VOLUME_CHANGE_USD = 'ThreeWeeksBuyersVolumeChangeUSD',
  THREE_WEEKS_SELLERS_VOLUME_CHANGE_USD = 'ThreeWeeksSellersVolumeChangeUSD',
  THREE_WEEKS_NET_VOLUME_CHANGE_USD = 'ThreeWeeksNetVolumeChangeUSD',
  THREE_WEEKS_NUMBER_OF_RATINGS_CHANGE = 'ThreeWeeksNumberOfRatingsChange',
  ONE_MONTH_HOLDERS_CHANGE = 'OneMonthHoldersChange',
  ONE_MONTH_BUYERS_CHANGE = 'OneMonthBuyersChange',
  ONE_MONTH_EXPERIENCED_BUYERS_CHANGE = 'OneMonthExperiencedBuyersChange',
  ONE_MONTH_LIQUIDITY_CHANGE = 'OneMonthLiquidityChange',
  ONE_MONTH_LIQUIDITY_CHANGE_USD = 'OneMonthLiquidityChangeUSD',
  ONE_MONTH_SELLERS_CHANGE = 'OneMonthSellersChange',
  ONE_MONTH_EXPERIENCED_SELLERS_CHANGE = 'OneMonthExperiencedSellersChange',
  ONE_MONTH_NET_BUYERS_CHANGE = 'OneMonthNetBuyersChange',
  ONE_MONTH_EXPERIENCED_NET_BUYERS_CHANGE = 'OneMonthExperiencedNetBuyersChange',
  ONE_MONTH_PRICE_PERCENT_CHANGE_USD = 'OneMonthPricePercentChangeUSD',
  ONE_MONTH_VOLUME_CHANGE_USD = 'OneMonthVolumeChangeUSD',
  ONE_MONTH_BUYERS_VOLUME_CHANGE_USD = 'OneMonthBuyersVolumeChangeUSD',
  ONE_MONTH_SELLERS_VOLUME_CHANGE_USD = 'OneMonthSellersVolumeChangeUSD',
  ONE_MONTH_NET_VOLUME_CHANGE_USD = 'OneMonthNetVolumeChangeUSD',
  ONE_MONTH_NUMBER_OF_RATINGS_CHANGE = 'OneMonthNumberOfRatingsChange',
  ONE_QUARTER_HOLDERS_CHANGE = 'OneQuarterHoldersChange',
  ONE_QUARTER_BUYERS_CHANGE = 'OneQuarterBuyersChange',
  ONE_QUARTER_EXPERIENCED_BUYERS_CHANGE = 'OneQuarterExperiencedBuyersChange',
  ONE_QUARTER_LIQUIDITY_CHANGE = 'OneQuarterLiquidityChange',
  ONE_QUARTER_LIQUIDITY_CHANGE_USD = 'OneQuarterLiquidityChangeUSD',
  ONE_QUARTER_SELLERS_CHANGE = 'OneQuarterSellersChange',
  ONE_QUARTER_EXPERIENCED_SELLERS_CHANGE = 'OneQuarterExperiencedSellersChange',
  ONE_QUARTER_NET_BUYERS_CHANGE = 'OneQuarterNetBuyersChange',
  ONE_QUARTER_EXPERIENCED_NET_BUYERS_CHANGE = 'OneQuarterExperiencedNetBuyersChange',
  ONE_QUARTER_PRICE_PERCENT_CHANGE_USD = 'OneQuarterPricePercentChangeUSD',
  ONE_QUARTER_VOLUME_CHANGE_USD = 'OneQuarterVolumeChangeUSD',
  ONE_QUARTER_BUYERS_VOLUME_CHANGE_USD = 'OneQuarterBuyersVolumeChangeUSD',
  ONE_QUARTER_SELLERS_VOLUME_CHANGE_USD = 'OneQuarterSellersVolumeChangeUSD',
  ONE_QUARTER_NET_VOLUME_CHANGE_USD = 'OneQuarterNetVolumeChangeUSD',
  ONE_QUARTER_NUMBER_OF_RATINGS_CHANGE = 'OneQuarterNumberOfRatingsChange',
  TOTAL_LIQUIDITY_LOCKED_IN_PERCENT = 'TotalLiquidityLockedInPercent',
  TOTAL_SUPPLY_LOCKED_IN_PERCENT = 'TotalSupplyLockedInPercent',
  DEXT_SCORE = 'DextScore',
  TWITTER_FOLLOWER = 'TwitterFollower',
  TWITTER_CREATED_AT = 'TwitterCreatedAt',
  FULLY_DILUTED_VALUATION = 'FullyDilutedValuation',
  MARKET_CAP = 'MarketCap',
  PRICE_USD = 'PriceUSD',
  RATING = 'Rating',
  TOTAL_NUMBER_OF_RATING = 'TotalNumberOfRating',
  MORALIS_SCORE = 'MoralisScore',
  COIN_CATEGORIES = 'CoinCategories',
  ON_CHAIN_STRENGTH_INDEX = 'OnChainStrengthIndex',
}
