import { Modal, ModalClose, ModalContent } from '@/components/core/Modal';
import { useAlertModalContext } from '../../context';
import { SelectAlertTypeCards } from '../../molecules';
import { language } from './values';
import styles from '../../styles.module.scss';
import typography from '@/styles/scss/typography.module.scss';

const SelectAlertToCreateModal = () => {
  const { modalContent, closeAlertModal } = useAlertModalContext();

  if (modalContent !== 'select') return null;

  return (
    <Modal initialOpen={true} onOpenChange={closeAlertModal}>
      <ModalContent className={styles.modal}>
        <div className={styles.header}>
          <h5 className={typography.h2}>{language.en.title}</h5>
          <ModalClose />
        </div>
        <SelectAlertTypeCards />
      </ModalContent>
    </Modal>
  );
};

export default SelectAlertToCreateModal;
