/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class JiffyscanService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Returns an array of erc20 transfers that the address has made
   * @param address
   * @param network
   * @param first
   * @param skip
   * @returns any
   * @throws ApiError
   */
  public jiffyScanControllerGetAddressErc20Transfers(
    address: string,
    network?: string,
    first?: string,
    skip?: string,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: 'userService/jiffyscan',
      query: {
        address: address,
        network: network,
        first: first,
        skip: skip,
      },
    });
  }
}
