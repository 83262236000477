/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ChainCoinAddressesDto } from '../models/ChainCoinAddressesDto';
import type { CoinInfoDto } from '../models/CoinInfoDto';
import type { CoinMetadataDto } from '../models/CoinMetadataDto';
import type { CoinsWithAlphaDensityScoreDto } from '../models/CoinsWithAlphaDensityScoreDto';
import type { GetChainsDataSummaryHistoryResponseDto } from '../models/GetChainsDataSummaryHistoryResponseDto';
import type { GetChainsDataSummaryResponseDto } from '../models/GetChainsDataSummaryResponseDto';
import type { GetCoinFiltersRequestDto } from '../models/GetCoinFiltersRequestDto';
import type { GetCoinsListRequestDto } from '../models/GetCoinsListRequestDto';
import type { GetCoinsWithAlphaDensityScoreRequestDto } from '../models/GetCoinsWithAlphaDensityScoreRequestDto';
import type { GetPaginatedCoinsByChainIdRequestDto } from '../models/GetPaginatedCoinsByChainIdRequestDto';
import type { GetPaginatedCoinsByChainIdResponseDto } from '../models/GetPaginatedCoinsByChainIdResponseDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class CoinFilterService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Get Coins Matching Filters
   * @param requestBody
   * @returns CoinInfoDto
   * @throws ApiError
   */
  public getCoinFilters(requestBody: GetCoinFiltersRequestDto): CancelablePromise<Array<CoinInfoDto>> {
    return this.httpRequest.request({
      method: 'POST',
      url: 'insightsRepoService/coin-filter/coin/filter',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Get Coin Metadata
   * @param requestBody
   * @returns CoinMetadataDto
   * @throws ApiError
   */
  public getMultipleCoinMetadata(requestBody: ChainCoinAddressesDto): CancelablePromise<Array<CoinMetadataDto>> {
    return this.httpRequest.request({
      method: 'POST',
      url: 'insightsRepoService/coin-filter/coins/get-multiple-metadata',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Get Supported Coins
   * @param requestBody
   * @returns ChainCoinAddressesDto
   * @throws ApiError
   */
  public getSupportedCoins(requestBody: ChainCoinAddressesDto): CancelablePromise<ChainCoinAddressesDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: 'insightsRepoService/coin-filter/coins/get-supported-coins',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Get list of coins using address/unique names
   * @param requestBody
   * @returns CoinInfoDto
   * @throws ApiError
   */
  public getCoinsList(requestBody: GetCoinsListRequestDto): CancelablePromise<Array<CoinInfoDto>> {
    return this.httpRequest.request({
      method: 'POST',
      url: 'insightsRepoService/coin-filter/coins/get-coins-list',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Get coins by chain id with pagination
   * @param requestBody
   * @returns GetPaginatedCoinsByChainIdResponseDto
   * @throws ApiError
   */
  public getPaginatedCoinsByChainId(
    requestBody: GetPaginatedCoinsByChainIdRequestDto,
  ): CancelablePromise<GetPaginatedCoinsByChainIdResponseDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: 'insightsRepoService/coin-filter/coins/get-coins-by-chain-id',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Get coins based with alpha density score
   * @param requestBody
   * @returns CoinsWithAlphaDensityScoreDto
   * @throws ApiError
   */
  public getCoinsWithAlphaDensityScore(
    requestBody: GetCoinsWithAlphaDensityScoreRequestDto,
  ): CancelablePromise<Array<CoinsWithAlphaDensityScoreDto>> {
    return this.httpRequest.request({
      method: 'POST',
      url: 'insightsRepoService/coin-filter/coins/get-alpha-density-score',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Get chain data summary like tokensCount, oneDayVolumeUsd etc
   * @param chainIds
   * @param categoryId
   * @returns any
   * @returns GetChainsDataSummaryResponseDto
   * @throws ApiError
   */
  public getChainsDataSummary(
    chainIds: string,
    categoryId?: number,
  ): CancelablePromise<any | GetChainsDataSummaryResponseDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: 'insightsRepoService/coin-filter/coins/get-chains-data-summary',
      query: {
        chainIds: chainIds,
        categoryId: categoryId,
      },
    });
  }

  /**
   * Get chain data summary history to create graphs
   * @param chainIds
   * @param dataType
   * @param categoryId
   * @returns any
   * @returns GetChainsDataSummaryHistoryResponseDto
   * @throws ApiError
   */
  public getChainsDataSummaryHistory(
    chainIds: Array<string>,
    dataType: 'total-volume' | 'net-volume',
    categoryId?: number,
  ): CancelablePromise<any | GetChainsDataSummaryHistoryResponseDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: 'insightsRepoService/coin-filter/coins/get-chains-data-summary-history',
      query: {
        chainIds: chainIds,
        categoryId: categoryId,
        dataType: dataType,
      },
    });
  }
}
