import color from '@/styles/color';
import { hexToRgb } from './hexToRgb';

const fontUItemp = "'Lato', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif";

const styleHelpers = {
  //Border Radius
  borderRadius: '12px',
  borderRadiusCircle: '50%',
  borderRadiusMax: '300px',
  borderRadiusMedium: '8px',
  borderRadiusSmall: '4px',

  //Box Shadows
  boxShadow: `0px 0 30px rgba(${hexToRgb(color.moneyAero90)}, 0.7)`,
  boxShadowBottom: `0px 20px 30px rgba(${hexToRgb(color.moneyAero90)}, 0.7)`,

  //Colors
  modalOverlay: `rgba(${hexToRgb(color.moneyAero90)}, 0.8)`,

  //Transitions
  transition: 'all 0.1s ease',

  //Fonts
  fontUIRegular: fontUItemp,
  fontUIMedium: fontUItemp,
  fontUIBold: fontUItemp,

  //Menus
  mobileMenuHeight: '85px',
} as const;

export default styleHelpers;
