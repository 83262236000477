import { UserWalletListDto } from '@/services/index';

export enum EAdditionMethod {
  COINBASE_WALLET = 'Coinbase Wallet',
  METAMASK = 'MetaMask',
  RAINBOW = 'Rainbow',
  WALLET_CONNECT = 'WalletConnect',
  WATCHED = 'Watched',
}

export enum EPortfolioList {
  MY = 'yourPortfolio',
  WATCHED = 'watchedWallets',
}

export const portfolioListToTab = (list: EPortfolioList) => {
  if (list === EPortfolioList.MY) return 'My Wallets';
  return 'Watch List';
};

export interface UserWallet {
  apiData: UserWalletListDto;
  authMethod?: string;
  balanceUsd: number;
  isLoggedInWallet?: boolean;
  isStatic: boolean;
}
