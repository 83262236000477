import Link from 'next/link';
import color from '@/styles/color';
import { ERoutePath } from '@/utils/ERoutePath';
import { faExclamationCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { language } from './values';
import styles from './styles.module.scss';

interface IReachedLimitWarningProps {
  className?: string;
}
const ReachedLimitWarning = ({ className = '' }: IReachedLimitWarningProps) => {
  return (
    <div className={`${styles.wrapper_limit} ${className}`}>
      <div className={styles.inner}>
        <FontAwesomeIcon color={color.moneyRed40} icon={faExclamationCircle} style={{ width: '16px' }} />
        <span>{language.en.reachedLimit}</span>
      </div>
      <Link className={styles.link} href={ERoutePath.manageSubscription} target="_blank">
        {language.en.upgrade}
      </Link>
    </div>
  );
};

export default ReachedLimitWarning;
