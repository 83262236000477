import { ControlledInput } from '../../atoms';
import { language } from './values';
import styles from '../styles.module.scss';
import typography from '@/styles/scss/typography.module.scss';

const AlertNameField = () => {
  return (
    <div>
      <div className={styles.col25}>
        <h5 className={typography.bodySmallRegular}>{language.en.title}</h5>
      </div>
      <div className={styles.col75}>
        <ControlledInput required name="inputName" placeholder={language.en.placeholder} />
      </div>
    </div>
  );
};

export default AlertNameField;
