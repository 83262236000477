/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Coin } from '../models/Coin';
import type { IBuyers } from '../models/IBuyers';
import type { ISellers } from '../models/ISellers';
import type { ObjectType } from '../models/ObjectType';
import type { ReservesTimeline } from '../models/ReservesTimeline';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class TimelineService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @param secret
   * @param address
   * @returns any Ok
   * @throws ApiError
   */
  public hiddenGemCoinSearch1(
    secret: string,
    address: string = '',
  ): CancelablePromise<
    | {
        coin?: any;
        reserves?: any;
        holders?: any;
        sellers?: any;
        buyers?: any;
      }
    | {
        coin: Coin;
        reserves: Array<ReservesTimeline>;
        holders: Array<ObjectType>;
        sellers: Array<ISellers>;
        buyers: Array<IBuyers>;
      }
  > {
    return this.httpRequest.request({
      method: 'GET',
      url: 'insightsService/timeline',
      query: {
        address: address,
        secret: secret,
      },
    });
  }
}
