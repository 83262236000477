/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ChainId {
  _0X1 = '0x1',
  _0X38 = '0x38',
  _0X89 = '0x89',
  _0XA4B1 = '0xa4b1',
  _0XA86A = '0xa86a',
  _0XFA = '0xfa',
  _0XA = '0xa',
  _0X171 = '0x171',
  _0X2105 = '0x2105',
  _0XE708 = '0xe708',
}
