import { TSelectOption } from 'moralis-ui';

export const language = {
  en: {
    button: (isEditing: boolean) => (isEditing ? 'Save Changes' : 'Save Strategy Alert'),
  },
};

export const strategyNotificationPeriods: TSelectOption[] = [
  {
    id: '10',
    label: '10 minutes (Pro Plan or higher)',
  },
  {
    id: '30',
    label: '30 minutes (Pro Plan or higher)',
  },
  {
    id: '60',
    label: '1 hour (Pro Plan or higher)',
  },
  {
    id: '240',
    label: '4 hour',
  },
  {
    id: '720',
    label: '12 hours',
  },
  {
    id: '1440',
    label: '1 day',
  },
];
