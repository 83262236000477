import { useUserAlertsCount } from '@/helpers/hooks/useUserAlertsCount';
import { ReachedLimitWarning } from './atoms';
import { language } from './values';
import styles from './styles.module.scss';

const AlertsUsedLabel = () => {
  const { alertCounts, max } = useUserAlertsCount();

  if (alertCounts?.all >= (max || 0)) {
    return <ReachedLimitWarning />;
  }

  return (
    <div className={styles.wrapper}>
      <span>{language.en.usedOf(alertCounts?.all, max || 0)}</span>
    </div>
  );
};

export default AlertsUsedLabel;
