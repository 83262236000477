import type { IncomingMessage, ServerResponse } from 'http';
import { ParsedUrlQuery } from 'querystring';
import { EnvApp } from './env/app';
import { AppCtxStatic, LogEntry, Logger, LogHandle } from './Logger';

class LoggerWithBuffer {
  private appCtx: AppCtxStatic<string> = {
    appName: EnvApp.logName,
    version: EnvApp.version,
  };
  private bfr: LogEntry[] = [];
  private _log: LogHandle = (le: LogEntry) => {
    this.bfr.push(le);
  };

  constructor(private initLog: () => Promise<LogHandle>) {
    this.initLog().then((log) => {
      this._log = log;
      this.bfr.forEach(log);
      this.bfr.length = 0;
    });
  }

  create<ItCtx extends Partial<AppCtxStatic<string>>>(name: string, ctx?: ItCtx) {
    return new Logger({
      logName: name,
      app: {
        ...this.appCtx,
        ...ctx,
      },
      send: (le: LogEntry) => this._log(le),
    });
  }
}

const logItem =
  EnvApp.nodeEnv === 'test'
    ? async () => () => null
    : typeof window === 'undefined'
    ? () => import('./log.server').then((m) => m.createServerLog())
    : () => import('./log.client').then((m) => m.createClientLog());

export const logApp = new LoggerWithBuffer(logItem);

export const getLogCtxByRequest = (
  req: Request,
  importMetaURL: string,
  { extra }: { extra?: Record<string, unknown> } = {},
) => ({
  modulePath: importMetaURL,
  method: req.method,
  url: req.url,
  headers: Object.fromEntries(req.headers),
  extra,
});

export const getLogCtxByResponse = (
  resp: Response,
  importMetaURL: string,
  {
    method,
    extra,
  }: {
    method: string | null;
    extra?: Record<string, unknown>;
  },
) => ({
  modulePath: importMetaURL,
  method,
  ok: resp.ok,
  url: resp.url,
  status: resp.status,
  statusText: resp.statusText,
  redirected: resp.redirected,
  extra,
});

// Server side

const simplifyReq = (req: IncomingMessage) => ({
  method: req.method,
  url: req.url,
  headers: req.headers,
});

export const getLogCtxByServerRequest = (
  req: IncomingMessage,
  importMetaURL: string,
  { extra }: { extra?: Record<string, unknown> } = {},
) => ({
  ...simplifyReq(req),
  modulePath: importMetaURL,
  extra,
});

export const getLogCtxByServerResponse = (
  resp: ServerResponse,
  importMetaURL: string,
  {
    ctxParams,
    extra,
  }: {
    ctxParams?: ParsedUrlQuery;
    extra?: Record<string, unknown>;
  } = {},
) => ({
  modulePath: importMetaURL,
  req: simplifyReq(resp.req),
  status: resp.statusCode,
  statusText: resp.statusMessage,
  headersSent: resp.headersSent,
  ctxParams,
  extra,
});
