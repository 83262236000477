import useSWR from 'swr';
import { removeEmptyStrings } from '@/helpers/utils/strings';
import { CreateTokenAlertRequestDto, UpdateTokenAlertRequestDto } from '@/services/index';
import useAuthStore from '@/stores/authStore';
import { api } from '@/utils/api';
import { language } from './values';

const fetchTokenAlerts = async () => {
  return await api.user.userControllerGetTokenAlerts();
};

export const useTokenAlerts = () => {
  const authToken = useAuthStore((state) => state.authToken);
  const {
    data: alerts,
    error,
    mutate,
    isLoading,
    isValidating,
  } = useSWR(authToken ? 'get-token-alerts' : null, fetchTokenAlerts);

  const updateTokenAlert = async (requestBody: UpdateTokenAlertRequestDto) => {
    const updatedAlert = await api.user.userControllerUpdateTokenAlert(requestBody);
    mutate(
      (prevData) =>
        prevData?.map((oldAlert) => (oldAlert.alert.alertId === updatedAlert.alert.alertId ? updatedAlert : oldAlert)),
      false,
    );
    return updatedAlert;
  };

  const createTokenAlert = async (requestBody: CreateTokenAlertRequestDto) => {
    const newAlert = await api.user.userControllerCreateTokenAlert(requestBody);
    mutate((prevData) => [...(prevData || []), newAlert], false);
    return newAlert;
  };

  const deleteTokenAlert = async (alertId: string) => {
    await api.user.userControllerDeleteTokenAlert(alertId);
    mutate((prevData) => prevData?.filter((alert) => alert.alert.alertId !== alertId), false);
  };

  const toggleTokenAlert = async (alertId: string, enabled: boolean) => {
    const targetAlert = alerts?.find((alert) => alert.alert.alertId === alertId);
    if (!targetAlert) {
      throw new Error(`Alert with id ${alertId} not found`);
    }

    return updateTokenAlert({
      alertId: targetAlert.alert.alertId,
      timeframe: targetAlert.alert.timeframe,
      operator: targetAlert.alert.operator,
      filter: targetAlert.alert.filter,
      name: targetAlert.notification.name,
      thresholdValue: targetAlert.alert.thresholdValue,
      topic: targetAlert.alert.topic,
      tokenAddress: targetAlert.alert.tokenAddress,
      tokenName: targetAlert.alert.tokenName,
      chainId: targetAlert.alert.chainId,
      intervalInSeconds: targetAlert.alert.intervalInSeconds,
      enabled,
      config: {
        ...removeEmptyStrings(targetAlert.notification.config),
        serviceIssuer: 'MoralisMoney',
      },
    });
  };

  return {
    messages: language.en,
    alerts,
    isLoading: isLoading || isValidating,
    error,
    createAlert: createTokenAlert,
    updateAlert: updateTokenAlert,
    deleteAlert: deleteTokenAlert,
    toggleAlert: toggleTokenAlert,
  };
};
