import { useCallback } from 'react';
import { normalizeTelegramUsername } from '@/components/common/CreateAlertModal/utils';
import useUserStore from '@/stores/userStore';
import { EProtocolType } from '@/types/validatedProtocol';
import { api } from '@/utils/api';
import { DisplayableError } from '@/utils/DisplayableError';
import { EnvTG } from '@/utils/env/tg';

export function useTelegram() {
  const { userValidatedProtocolsTelegram, userId, setUser } = useUserStore((state) => {
    return {
      userValidatedProtocolsTelegram: state.user?.validatedProtocols?.find(
        (protocol) => protocol.type === EProtocolType.TELEGRAM,
      ),
      userId: state.user?.id,
      setUser: state.setUser,
    };
  });

  const getValidatedTelegram = useCallback(
    (input: string): string | undefined => {
      if (!userValidatedProtocolsTelegram?.data) return;
      for (const validatedTg of userValidatedProtocolsTelegram.data) {
        if (normalizeTelegramUsername(validatedTg) === input) {
          return validatedTg;
        }
      }
    },
    [userValidatedProtocolsTelegram?.data],
  );

  const validateTelegram = async (inputTelegram: string) => {
    let validatedTelegram = getValidatedTelegram(inputTelegram);
    if (validatedTelegram) return validatedTelegram;

    const { validatedProtocols } = await api.user.userControllerUserStats();
    setUser({ validatedProtocols });

    validatedTelegram = getValidatedTelegram(inputTelegram);
    if (validatedTelegram) return validatedTelegram;

    window.open(`${EnvTG.botURL}?start=${userId}`, '_blank');
    throw new DisplayableError(
      'Telegram username is not verified',
      'Please start a conversation with our bot to verify your Telegram username',
    );
  };

  return { validateTelegram };
}
