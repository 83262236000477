import { ISvgProps } from './types';

const AllChains = ({ width = '36', height = '36', ...props }: ISvgProps) => {
  return (
    <svg fill="none" height={height} viewBox="0 0 16 16" width={width} xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect fill="#396993" height="16" rx="8" width="16" />
      <path
        d="M5.25 5h2.484a2.244 2.244 0 0 1 2.25 2.266c0 1.109-.796 2.046-1.875 2.234h-.03a.5.5 0 1 1-.173-.984h.032C8.546 8.406 9 7.875 9 7.266A1.28 1.28 0 0 0 7.734 6H5.25a1.267 1.267 0 0 0-.203 2.516h.031a.5.5 0 1 1-.172.984h-.031A2.265 2.265 0 0 1 3 7.266C3 6.016 4 5 5.25 5Zm5.484 6H8.25C7 11 6 10 6 8.75c0-1.11.797-2.047 1.875-2.234h.031a.5.5 0 1 1 .172.984h-.031A1.26 1.26 0 0 0 8.25 10h2.484C11.438 10 12 9.437 12 8.75c0-.625-.453-1.14-1.063-1.25h-.03a.5.5 0 1 1 .171-.984h.031A2.28 2.28 0 0 1 13 8.75C13 10 11.984 11 10.734 11Z"
        fill="#F0F8FF"
      />
    </svg>
  );
};

export default AllChains;
