import { useEffect, useState } from 'react';
import { popMessage } from 'moralis-ui'; // Imported popMessage
import { FormProvider, useWatch } from 'react-hook-form';
import { AlertsUsedLabel } from '@/components/common/AlertsUsedLabel';
import { ButtonPrimary } from '@/components/core/Button';
import { useAlertModal } from '@/helpers/hooks/useAlertModal';
import { defaultUseFormErrorHandler, useFormHandler } from '@/helpers/hooks/useFormHandler';
import { useMagic } from '@/helpers/hooks/useMagic';
import { useTelegram } from '@/helpers/hooks/useTelegram';
import { useTokenAlerts } from '@/helpers/hooks/useTokenAlerts';
import { useUserAlertsCount } from '@/helpers/hooks/useUserAlertsCount';
import { TokenAlertResponseDto } from '@/services/index';
import { api } from '@/utils/api';
import { POP_MESSAGE } from '@/utils/popMessage/constants';
import { AlertScenarioTextVisualization } from '../../atoms';
import {
  AlertNameField,
  ConditionFieldset,
  FrequencyField,
  NotificationsFieldset,
  SelectTokenField,
} from '../../molecules';
import { CooldownTimeFrameType, FilterType, OperatorType, TimeframeType } from '../../values';
import { ITokenAlertCoin, ITokenAlertFormData, ITokenAlertProps } from './types';
import { getFormDefaultValues, getPayload } from './utils';
import { language, tokenNotificationPeriods } from './values';
import styles from './styles.module.scss';

const TokenAlert = ({ tokenData: tokenDataInitial, alert, notification }: ITokenAlertProps) => {
  const { isLimitReached } = useUserAlertsCount();
  const { validateEmail } = useMagic();
  const { close: closeModal } = useAlertModal();
  const { updateAlert, createAlert, messages } = useTokenAlerts();
  const { validateTelegram } = useTelegram();

  const { isLoading, handleOnSubmit, ...formDefaultMethods } = useFormHandler<
    ITokenAlertFormData,
    TokenAlertResponseDto
  >({
    defaultValues: getFormDefaultValues({
      alert,
      notification,
      tokenDataInitial,
    }),
    onSubmit: async (formData) => {
      if (formData.toggleProtocolTelegram) {
        formData.inputProtocolTelegram = await validateTelegram(formData.inputProtocolTelegram);
      }
      if (formData.toggleProtocolEmail) {
        formData.inputProtocolEmail = await validateEmail(formData.inputProtocolEmail);
      }

      const payload = getPayload({ alert, formData, tokenData });

      if ('alertId' in payload) {
        return await updateAlert(payload).then((r) => {
          popMessage({
            variant: 'success',
            title: 'Success!',
            message: messages.update(r?.notification?.name),
          });
          return r;
        });
      } else {
        return await createAlert(payload).then((r) => {
          popMessage({
            variant: 'success',
            title: 'Success!',
            message: messages.create(r?.notification?.name),
          });
          return r;
        });
      }
    },
    onSuccess: closeModal,
    onError: (error) =>
      defaultUseFormErrorHandler(error, () => {
        popMessage({
          variant: 'error',
          title: POP_MESSAGE.error.defaultTitle,
          message: POP_MESSAGE.error.defaultMessage,
        });
      }),
  });

  const [selectToken, inputValue, selectCoolDown, selectFilter, selectOperator, selectTimeframe] = useWatch({
    control: formDefaultMethods.control,
    name: ['selectToken', 'inputValue', 'selectCoolDown', 'selectFilter', 'selectOperator', 'selectTimeframe'],
  });

  const [tokenData, setTokenData] = useState<ITokenAlertCoin | undefined>(tokenDataInitial);

  useEffect(() => {
    if (tokenDataInitial?.price || !selectToken) return;
    const getTokenData = async () => {
      const [response] = await api.coinFilter.getCoinsList({
        coinAddresses: [selectToken.address],
        chainId: selectToken.chainId,
      });

      setTokenData({
        address: response.metadata.coinAddress,
        chainId: response.metadata.chainId,
        name: response.metadata.name,
        price: response.metadata.priceUSD,
        symbol: response.metadata.symbol,
      });
    };
    getTokenData();
  }, [selectToken, tokenDataInitial?.price]);

  return (
    <FormProvider {...formDefaultMethods}>
      <form className={styles.form} onSubmit={handleOnSubmit}>
        <AlertNameField />
        <SelectTokenField disabled={Boolean(tokenDataInitial)} setTokenData={setTokenData} tokenData={tokenData} />
        <ConditionFieldset tokenData={tokenData} />
        <FrequencyField options={tokenNotificationPeriods} />
        <NotificationsFieldset />
        <div className={styles.footer}>
          <AlertScenarioTextVisualization
            alertType="token"
            amount={inputValue}
            className={styles.scenario}
            cooldownTimeframe={selectCoolDown as CooldownTimeFrameType}
            filter={selectFilter as FilterType}
            operator={selectOperator as OperatorType}
            timeframe={selectTimeframe as TimeframeType}
            tokenName={tokenData?.name}
          />
          <AlertsUsedLabel />
          <ButtonPrimary
            className={styles.saveButton}
            disabled={!formDefaultMethods.formState.isDirty || isLimitReached}
            isLoading={isLoading}
            size="xl"
            type="submit"
          >
            {language.en.button(Boolean(notification))}
          </ButtonPrimary>
        </div>
      </form>
    </FormProvider>
  );
};

export default TokenAlert;
