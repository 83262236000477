import { useState } from 'react';
import { useRouter } from 'next/router';
import { popMessage } from 'moralis-ui';
import { useAccount, useDisconnect } from 'wagmi';
import { DEFAULT_AVATAR } from '@/components/beans/types';
import useAuthStore from '@/stores/authStore';
import useBeanStore from '@/stores/beanStore';
import useRewardsStore from '@/stores/rewardsStore';
import useUserStore from '@/stores/userStore';
import { EOAuth } from '@/types/auth';
import { logApp } from '@/utils/logApp';
import { createMagic } from '@/utils/magic';
import { UserItemResponseDto } from '../../services';

type TLogoutNotify = {
  message: string;
  title: string;
  type: 'success' | 'warning' | 'error';
};
export interface ILogoutProps {
  closeNavLeft?: boolean;
  notification?: TLogoutNotify;
  redirectUrl?: string;
}

const log = logApp.create('useLogout');

export const useLogout = () => {
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  // set up 3rd party hooks
  const router = useRouter();
  const { isConnected } = useAccount();
  const { disconnectAsync } = useDisconnect();

  // set up FE store hooks
  const authMethod = useAuthStore((state) => {
    return state.authMethod;
  });
  const clearAuth = useAuthStore((state) => {
    return state.clearAuth;
  });
  const clearUserStore = useUserStore((state) => {
    return state.clearUserStore;
  });
  const setBeanState = useBeanStore((state) => {
    return state.setBeanState;
  });
  const { setRewards } = useRewardsStore();

  // where the magic happens
  const handleLogout = async ({ redirectUrl, notification }: ILogoutProps) => {
    setIsLoggingOut(true);
    clearAuth();
    clearUserStore();

    if (isConnected) await disconnectAsync();
    if (authMethod === 'magic' || Object.values(EOAuth).includes(authMethod as EOAuth)) {
      await createMagic()
        .user.logout()
        .catch((error) => {
          log.error('[magic.user.logout]', error, { authMethod });
        });
    }

    setBeanState({ amount: 0, nextDate: null });
    setRewards({ avatar: DEFAULT_AVATAR, chests: [], items: [], magicBeans: {} as UserItemResponseDto });

    if (redirectUrl) {
      router.push(redirectUrl);
    }

    if (notification) {
      popMessage({
        variant: notification.type,
        title: notification.title,
        message: notification.message,
      });
    }

    setIsLoggingOut(false);
  };

  return { handleLogout, isLoggingOut };
};
