import create from 'zustand';
import { persist } from 'zustand/middleware';
import { TUser } from '@/types/user';
import { GetNetWorthResponseDto, PlanStageDto, SmartTradingDataResponseDto } from '../services';

type TUserStore = {
  clearUserStore: () => void;
  netWorth?: GetNetWorthResponseDto;
  setNetWorth: (netWorth: GetNetWorthResponseDto) => void;
  setStage: (stage: PlanStageDto) => void;
  setTradingAccountData: (tradingAccountData: SmartTradingDataResponseDto) => void;
  setUser: (userData: TUser) => void;
  stage?: PlanStageDto;
  user?: TUser;
};

const useUserStore = create(
  persist<TUserStore>(
    (set) => {
      return {
        user: undefined,
        netWorth: undefined,

        setTradingAccountData: (tradingAccountData: SmartTradingDataResponseDto) => {
          set((state) => {
            return {
              user: {
                ...state.user,
                tradingAccountData,
              },
            };
          });
        },

        clearUserStore: () => {
          set(() => {
            return {
              user: undefined,
              netWorth: undefined,
              stage: undefined,
            };
          });
        },

        setUser: (userData: TUser) => {
          set((state) => {
            return {
              user: {
                ...state.user,
                ...userData,
              },
            };
          });
        },

        setStage: (stage: PlanStageDto) => {
          set(() => {
            return {
              stage,
            };
          });
        },

        setNetWorth: (netWorth: GetNetWorthResponseDto) => {
          set(() => {
            return {
              netWorth,
            };
          });
        },
      };
    },
    {
      name: 'mm-user-storage',
      getStorage: () => {
        return localStorage;
      },
    },
  ),
);

export default useUserStore;
