'use client';
import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IButtonProps } from './types';
import styles from './styles.module.scss';

const ButtonBase = ({
  children,
  className = '',
  disabled = false,
  iconPosition = 'noneIcon',
  isLoading = false,
  onClick,
  size = 'regular',
  style,
  type = 'button',
  ...props
}: IButtonProps) => {
  const getIconPosition = (iconPosition: string) => {
    switch (iconPosition) {
      case 'rightIcon':
        return styles.rightIcon;
      case 'leftIcon':
        return styles.leftIcon;
      case 'onlyIcon':
        return styles.onlyIcon;
      default:
        return '';
    }
  };
  const getSizeStyles = (size: string) => {
    switch (size) {
      case 'large':
        return styles.buttonLarge;
      case 'small':
        return styles.buttonSmall;
      case 'xl':
        return styles.buttonXL;
      default:
        return styles.buttonRegular;
    }
  };

  return (
    <button
      className={`mm-button ${styles.button} ${getSizeStyles(size)} ${getIconPosition(iconPosition)} ${className}`}
      disabled={disabled || isLoading}
      style={{ ...style }}
      // eslint-disable-next-line react/button-has-type
      type={type}
      onClick={onClick}
      {...props}
    >
      {isLoading ? (
        <>
          <FontAwesomeIcon className={'spin'} icon={faSpinnerThird} style={{ width: '16px' }} />
          Loading
        </>
      ) : (
        <>{children}</>
      )}
    </button>
  );
};

export default ButtonBase;
