import { ISvgProps } from './types';

const Cronos = ({ width = '120', height = '120', ...props }: ISvgProps) => {
  return (
    <svg
      fill="currentColor"
      height={height}
      viewBox="0 0 160 160"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M80.0166 23L79.975 23.0249V23L30 51.7417V109.258L53.0648 122.515L79.975 138V137.975L80.0166 138L130 109.258V51.7583L80.0166 23ZM70.1448 115.29L44.8244 100.733V60.259L80.0166 40.0429V40.0594L115.192 60.2839V100.741L80.0166 120.965L70.1448 115.298V115.29Z"
        fill="white"
      />
      <path
        d="M79.9992 64.8326L93.6249 72.6788L103.347 67.0862L79.9992 53.6475L56.6348 67.0862V93.9389L79.9992 107.353L103.347 93.9389L93.6249 88.3297L79.9992 96.1759L66.3734 88.3297V72.6788L79.9992 64.8326Z"
        fill="white"
      />
    </svg>
  );
};

export default Cronos;
