import { CHAIN_ID_MAP } from '@/utils/supportedChains';
import AllChains from './AllChains';
import Arbitrum from './Arbitrum';
import Avalanche from './Avalanche';
import Base from './Base';
import BNB from './BNB';
import Cronos from './Cronos';
import Ethereum from './Ethereum';
import Fantom from './Fantom';
import Linea from './Linea';
import Optimism from './Optimism';
import Polygon from './Polygon';
import Pulsechain from './Pulsechain';
import Solana from './Solana';

export function getChainIconComponent(chainId: string) {
  switch (chainId) {
    case 'allChains':
      return AllChains;
    case CHAIN_ID_MAP.arbitrum:
      return Arbitrum;
    case CHAIN_ID_MAP.avalanche:
      return Avalanche;
    case CHAIN_ID_MAP.binance:
      return BNB;
    case CHAIN_ID_MAP.cronos:
      return Cronos;
    case CHAIN_ID_MAP.fantom:
      return Fantom;
    case CHAIN_ID_MAP.polygon:
      return Polygon;
    case CHAIN_ID_MAP.pulse:
      return Pulsechain;
    case CHAIN_ID_MAP.optimism:
      return Optimism;
    case CHAIN_ID_MAP.base:
      return Base;
    case CHAIN_ID_MAP.linea:
      return Linea;
    case CHAIN_ID_MAP.solana:
      return Solana;
    case CHAIN_ID_MAP.ethereum:
    default:
      return Ethereum;
  }
}
