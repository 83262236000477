import { useState } from 'react';
import { DefaultValues, FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { ApiError } from '@/services/index';
import { DisplayableError } from '@/utils/DisplayableError';
import { errorFeedback } from '@/utils/l10n/generic';
import { logApp } from '@/utils/logApp';

export interface INotificationParams {
  message: string;
  title?: string;
}

type UseFormHandlerOptions<TFormData, TSubmitResponse> = {
  defaultValues: DefaultValues<TFormData>;
  validateOn?: 'onSubmit' | 'onBlur' | 'onChange' | 'onTouched' | 'all' | undefined;
  onError?: (error: unknown, data: TFormData) => void;
  onSubmit: (data: TFormData) => Promise<TSubmitResponse>;
  /**
   * Will be called after the form is submitted successfully and reset
   */
  onSuccess?: (response: TSubmitResponse, data: TFormData) => void;
};

const log = logApp.create('useFormHandler');

export const useFormHandler = <TFormData extends FieldValues, TSubmitResponse>({
  defaultValues,
  validateOn = 'onSubmit',
  onSubmit,
  onError,
  onSuccess,
}: UseFormHandlerOptions<TFormData, TSubmitResponse>) => {
  const [isLoading, setIsLoading] = useState(false);
  const { handleSubmit, reset, ...formMethods } = useForm({ defaultValues, mode: validateOn });

  const handleOnSubmit: SubmitHandler<TFormData> = async (data: TFormData) => {
    setIsLoading(true);

    try {
      const response = await onSubmit(data);
      reset();
      onSuccess?.(response, data);
    } catch (err) {
      log.warnError('Error on form submit', err, data);
      onError?.(err, data);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    handleOnSubmit: handleSubmit(handleOnSubmit),
    isLoading,
    reset,
    handleSubmit,
    ...formMethods,
  };
};

/**
 * Default error handler for the useFormHandler hook.
 * You can use it for `onError` callback
 */
export function defaultUseFormErrorHandler(err: unknown, errorNotification: (params: INotificationParams) => void) {
  if (err instanceof ApiError) {
    errorNotification({
      message: (err.body?.cause as string) || errorFeedback,
    });
  } else if (err instanceof DisplayableError) {
    errorNotification({
      title: err.title,
      message: err.message,
    });
  } else if (err instanceof Error) {
    errorNotification({
      message: err.message,
    });
  } else {
    errorNotification({ message: errorFeedback });
  }
}
