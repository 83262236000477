/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetCoinsPageResponse } from '../models/GetCoinsPageResponse';
import type { GetTrendingCoinsResponseDto } from '../models/GetTrendingCoinsResponseDto';
import type { IGetCoinCursorResponse } from '../models/IGetCoinCursorResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class CoinsService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @param chainId
   * @param filterName
   * @param pageNumber
   * @returns IGetCoinCursorResponse
   * @throws ApiError
   */
  public coinsControllerGetPageCursor(
    chainId: string,
    filterName: string,
    pageNumber: number,
  ): CancelablePromise<IGetCoinCursorResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: 'coinsService/coins/get-page-cursor',
      query: {
        chainId: chainId,
        filterName: filterName,
        pageNumber: pageNumber,
      },
    });
  }

  /**
   * @param chainId
   * @param filterName
   * @param pageNumber
   * @returns GetCoinsPageResponse
   * @throws ApiError
   */
  public coinsControllerGetCoinsPage(
    chainId: string,
    filterName: string,
    pageNumber: number,
  ): CancelablePromise<GetCoinsPageResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: 'coinsService/coins/get-page',
      query: {
        chainId: chainId,
        filterName: filterName,
        pageNumber: pageNumber,
      },
    });
  }

  /**
   * @returns GetTrendingCoinsResponseDto
   * @throws ApiError
   */
  public coinsControllerGetTrendingCoins(): CancelablePromise<GetTrendingCoinsResponseDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: 'coinsService/coins/get-trending-coins',
    });
  }
}
