/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CategoryDto } from '../models/CategoryDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class CategoryService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Get all category values
   * @param includeContent
   * @param excludeUnusedCategories
   * @returns CategoryDto
   * @throws ApiError
   */
  public getCategories(
    includeContent?: boolean,
    excludeUnusedCategories?: boolean,
  ): CancelablePromise<Array<CategoryDto>> {
    return this.httpRequest.request({
      method: 'GET',
      url: 'insightsRepoService/category/all',
      query: {
        includeContent: includeContent,
        excludeUnusedCategories: excludeUnusedCategories,
      },
    });
  }

  /**
   * Get one or all categories
   * @param id
   * @returns CategoryDto
   * @throws ApiError
   */
  public getCategory(id: number): CancelablePromise<CategoryDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: 'insightsRepoService/category',
      query: {
        id: id,
      },
    });
  }
}
