import { color } from 'moralis-ui';

const top = 80; // > 80 green
const ok = [50, 79]; // 50-79 yellow
const bad = [0, 49]; // 0-49 red

export const getShieldColor = (percent: number | undefined): string => {
  switch (true) {
    case percent && percent >= top:
      return color.green600;
    case percent && percent >= ok[0] && percent <= ok[1]:
      return color.yellow500;
    case percent && percent >= bad[0] && percent <= bad[1]:
      return color.red400;
    default:
      return color.red400;
  }
};
