import { FC } from 'react';
import { useTabsContext } from '../context';
import styles from './Tab.module.scss';

interface ITabProps {
  children: React.ReactNode;
  className?: string;
  disabled?: boolean;
  id: string;
}

const Tab: FC<ITabProps> = ({ id, children, className = '', disabled }) => {
  const { activeTab, setActiveTab } = useTabsContext();

  const isSelected = id === activeTab;

  return (
    <button
      aria-selected={isSelected}
      className={`${styles.tab} ${isSelected ? styles.active : ''} ${className}`}
      disabled={disabled}
      id={id}
      role="tab"
      type="button"
      onClick={() => setActiveTab(id)}
    >
      {children}
    </button>
  );
};

export default Tab;
