import React, { createContext, useContext } from 'react';
import { AddWallet } from '../AddWallet';
import { manageWalletStore } from '../stores/manageWalletStores';

const ModalContext = createContext({});

export const useModalContext = () => useContext(ModalContext);

export const AddWalletModalProvider = ({ children }: React.PropsWithChildren) => (
  <ModalContext.Provider value={manageWalletStore((state) => state)}>
    <AddWallet />
    {children}
  </ModalContext.Provider>
);
