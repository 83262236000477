/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class DefaultService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @returns any The Health Check is successful
   * @throws ApiError
   */
  public healthControllerCheck(): CancelablePromise<{
    status?: string;
    info?: Record<string, Record<string, string>> | null;
    error?: Record<string, Record<string, string>> | null;
    details?: Record<string, Record<string, string>>;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: 'web3Service/api/health',
      errors: {
        503: `The Health Check is not successful`,
      },
    });
  }

  /**
   * @returns any The Health Check is successful
   * @throws ApiError
   */
  public healthControllerCheck1(): CancelablePromise<{
    status?: string;
    info?: Record<string, Record<string, string>> | null;
    error?: Record<string, Record<string, string>> | null;
    details?: Record<string, Record<string, string>>;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: 'userService/api/health',
      errors: {
        503: `The Health Check is not successful`,
      },
    });
  }

  /**
   * @returns any The Health Check is successful
   * @throws ApiError
   */
  public healthControllerCheck2(): CancelablePromise<{
    status?: string;
    info?: Record<string, Record<string, string>> | null;
    error?: Record<string, Record<string, string>> | null;
    details?: Record<string, Record<string, string>>;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: 'coinsService/api/health',
      errors: {
        503: `The Health Check is not successful`,
      },
    });
  }

  /**
   * @returns any
   * @throws ApiError
   */
  public appControllerGetInfo(): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: 'insightsRepoService/info',
    });
  }

  /**
   * @returns any
   * @throws ApiError
   */
  public appControllerGetInfo1(): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: 'insightsRepoSearchService/info',
    });
  }

  /**
   * @returns any
   * @throws ApiError
   */
  public appControllerGetHello(): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: 'billingCoordinator/',
    });
  }

  /**
   * @returns any The Health Check is successful
   * @throws ApiError
   */
  public healthControllerCheck3(): CancelablePromise<{
    status?: string;
    info?: Record<
      string,
      {
        status: string;
      }
    > | null;
    error?: Record<
      string,
      {
        status: string;
      }
    > | null;
    details?: Record<
      string,
      {
        status: string;
      }
    >;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: 'billingCoordinator/health',
      errors: {
        503: `The Health Check is not successful`,
      },
    });
  }
}
