import create from 'zustand';
import { TBeanState } from '@/types/beans';

type TBeanStore = {
  beans: TBeanState;
  setBeanState: (newStateData: TBeanState) => void;
  updateBeanState: (newStateData: TBeanState) => void;
};

const useBeanStore = create<TBeanStore>((set) => {
  return {
    beans: {
      amount: 0,
      goal: 10000,
      nextDate: null,
    },

    setBeanState: (newStateData: TBeanState) => {
      set((state) => {
        return {
          beans: {
            ...state.beans,
            ...newStateData,
          },
        };
      });
    },

    updateBeanState: (newStateData: TBeanState) => {
      set((state) => {
        return {
          beans: {
            ...state.beans,
            amount: Number(state.beans.amount) + Number(newStateData.amount),
            nextDate: newStateData.nextDate,
          },
        };
      });
    },
  };
});

export default useBeanStore;
