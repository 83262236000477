import { Modal, ModalClose, ModalContent } from '@/components/core/Modal';
import { useAlertModalContext } from '../../context';
import { BundleAlert } from '../../organisms';
import { IBundleAlertProps } from '../../organisms/BundleAlert/types';
import { language } from './values';
import styles from '../../styles.module.scss';
import typography from '@/styles/scss/typography.module.scss';

const StrategyAlertModal = () => {
  const { modalContent, closeAlertModal, modalProps } = useAlertModalContext<IBundleAlertProps>();

  if (modalContent !== 'bundle') return null;

  return (
    <Modal initialOpen={true} onOpenChange={closeAlertModal}>
      <ModalContent className={styles.modal}>
        <div className={styles.header}>
          <h5 className={typography.h2}>{language.en.title}</h5>
          <ModalClose />
        </div>
        <BundleAlert bundle={modalProps?.bundle} />
      </ModalContent>
    </Modal>
  );
};

export default StrategyAlertModal;
