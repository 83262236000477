import { isMagicAuth } from '@/components/accountSelector/utils/isMagicAuth';
import { getWalletNetWorth } from '@/components/portfolio/lib/netWorth';
import { GetWalletsNetWorthResponseDto, UserService } from '@/services/index';
import { organizeWalletsByList } from '../utils/organizeWalletsByList';
import { UserWallet } from './types';

export const fetchMultiPortfolioData = async (user: UserService, extraAddresses: Array<UserWallet>) => {
  const wallets = await user
    .userControllerGetUserPortfolioWallets()
    .then((res) => {
      const responseFormatted: Array<UserWallet> = res.wallets.map((data) => {
        return {
          apiData: data,
          balanceUsd: 0,
          isStatic: false,
        };
      });
      return responseFormatted;
    })
    .catch(() => null);

  if (!wallets || !extraAddresses?.length) {
    return null;
  }

  /**
   * Filter out wallets already listed in extraAddresses to avoid duplicates.
   * This might happen when the connect address is already in the user's wallet list.
   */
  const filteredWallets = wallets.filter(
    (wallet) =>
      !extraAddresses.some(
        (extraWallet) => extraWallet.apiData.address.toLowerCase() === wallet.apiData.address.toLowerCase(),
      ),
  );

  filteredWallets.push(...extraAddresses);

  const uniqueAddresses = prepareWalletAddresses(filteredWallets, extraAddresses);
  const balances = await getWalletNetWorth(uniqueAddresses);
  updateWalletBalances(filteredWallets, balances);
  prioritizeWallets(filteredWallets);
  return organizeWalletsByList(filteredWallets);
};

export const prepareWalletAddresses = (wallets: Array<UserWallet>, extraAddresses: Array<UserWallet> | undefined) => {
  const allAddresses = [
    wallets?.map((wallet) => wallet.apiData.address),
    extraAddresses?.map((wallet) => wallet.apiData.address),
  ]
    .flat()
    .filter(Boolean) as Array<string>;

  // remove duplicates
  return Array.from(new Set(allAddresses));
};

export const updateWalletBalances = (wallets: Array<UserWallet>, balances: GetWalletsNetWorthResponseDto) => {
  return wallets.forEach((wallet) => {
    const balance = balances.wallets.find((w) => w.address.toLowerCase() === wallet.apiData.address.toLowerCase());
    wallet.balanceUsd = balance ? parseFloat(balance.total_networth_usd) : 0;
  });
};

export const prioritizeWallets = (wallets: Array<UserWallet>) => {
  // Keep Logged in at the top
  wallets.sort((a) => (a?.isLoggedInWallet ? -1 : 1));

  // Look for a wallet with authMethod Magic and no balance first
  const magicWalletIndex = wallets.findIndex((wallet) => wallet.balanceUsd === 0 && isMagicAuth(wallet.authMethod));

  // Move address with add method Logged in to the top
  const loggedInWallet = wallets.find((wallet) => wallet.isLoggedInWallet);
  if (loggedInWallet) {
    wallets.splice(wallets.indexOf(loggedInWallet), 1);
    wallets.unshift(loggedInWallet);
  }

  // Set includeInAggregateView to false for the Magic wallet if it exists
  if (magicWalletIndex !== -1 && wallets[magicWalletIndex] && wallets[magicWalletIndex].apiData.settings) {
    wallets[magicWalletIndex].apiData.settings!.includeInAggregateView = false;
  }
};
