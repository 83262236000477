import { ISvgProps } from './types';

const Linea = ({ width = '120', height = '120', ...props }: ISvgProps) => {
  return (
    <svg fill="none" height={height} viewBox="0 0 200 200" width={width} xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect fill="#121212" height="207.623" width="199.4" />
      <g clipPath="url(#clip0_2303_643)">
        <path d="M132.369 155.99H49.7001V68.8854H68.6148V139.109H132.369V155.981V155.99Z" fill="white" />
        <path
          d="M132.369 85.7575C141.687 85.7575 149.241 78.2036 149.241 68.8855C149.241 59.5673 141.687 52.0134 132.369 52.0134C123.05 52.0134 115.497 59.5673 115.497 68.8855C115.497 78.2036 123.05 85.7575 132.369 85.7575Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_2303_643">
          <rect fill="white" height="103.977" transform="translate(49.7001 52.0134)" width="99.5407" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Linea;
