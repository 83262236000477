import Script from 'next/script';

const script = `
window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}
gtag('js', new Date());
gtag('config', 'G-Y29XRW23BZ', { page_path: window.location.pathname });
`;

// TODO: (Maxim) probably we should remove it if se send this data through segment anyway
// https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/quickstart/
// Google tag manager
export const GTag = () => (
  <>
    <Script async src="https://www.googletagmanager.com/gtag/js?id=G-Y29XRW23BZ" strategy="worker" />
    <Script dangerouslySetInnerHTML={{ __html: script }} id="gtag-script" strategy="worker" />
  </>
);
