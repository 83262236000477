/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type SubscriptionCancellationRequestedDto = {
  reasonSlug: SubscriptionCancellationRequestedDto.reasonSlug;
  reason?: string;
};

export namespace SubscriptionCancellationRequestedDto {
  export enum reasonSlug {
    FREE_PLAN_IS_SUFFICIENT = 'Free plan is sufficient',
    I_DON_T_NEED_IT_ANY_MORE = "I don't need it any more",
    I_M_SWITCHING_TO_ANOTHER_PROVIDER = "I'm switching to another provider",
    IT_WAS_TOO_EXPENSIVE = 'It was too expensive',
    MISSING_FEATURES = 'Missing features',
    MORALIS_DID_NOT_MEET_MY_NEEDS = 'Moralis did not meet my needs',
    OTHER_PLEASE_COMMENT_BELOW_ = 'Other (please comment below)',
    PROJECT_ENDED_OR_BUSINESS_CLOSED = 'Project ended or business closed',
    SWITCHING_TO_ANOTHER_PROVIDER = 'Switching to another provider',
    TECHNICAL_ISSUES = 'Technical issues',
    TOO_EXPENSIVE = 'Too expensive',
    I_AM_HAVING_TECHNICAL_PROBLEMS = 'I am having technical problems',
    I_DON_T_HAVE_TIME_TO_USE_IT = "I don't have time to use it",
    I_DON_T_UNDERSTAND_HOW_TO_USE_IT = "I don't understand how to use it",
    IT_S_TOO_EXPENSIVE_FOR_ME = "It's too expensive for me",
    I_M_USING_ANOTHER_SERVICE = "I'm using another service",
    I_DIDN_T_GET_THE_ALPHA_I_THOUGHT_I_WOULD_GET = "I didn't get the alpha I thought I would get",
    I_CAN_T_COVER_THE_SUBSCRIPTION_COST_WITH_TRADING_GAINS = "I can't cover the subscription cost with trading gains",
    THE_GAS_FEES_ARE_TOO_HIGH_FOR_ME_TO_TRADE_AT_THE_MOMENT = 'The gas fees are too high for me to trade at the moment',
  }
}
