/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetAddressFromEnsResponseDto } from '../models/GetAddressFromEnsResponseDto';
import type { GetDeFiPositionsResponseDto } from '../models/GetDeFiPositionsResponseDto';
import type { GetDeFiSummaryResponseDto } from '../models/GetDeFiSummaryResponseDto';
import type { GetEnsFromAddressResponseDto } from '../models/GetEnsFromAddressResponseDto';
import type { GetERC20AllowanceResponseDto } from '../models/GetERC20AllowanceResponseDto';
import type { GetERC20HistoryResponseDto } from '../models/GetERC20HistoryResponseDto';
import type { GetERC20MetadataResponseDto } from '../models/GetERC20MetadataResponseDto';
import type { GetERC20PriceResponseDto } from '../models/GetERC20PriceResponseDto';
import type { GetErc20TokenOwnersResponseDto } from '../models/GetErc20TokenOwnersResponseDto';
import type { GetLatestMarketDataResponseDto } from '../models/GetLatestMarketDataResponseDto';
import type { GetNetWorthRequestDto } from '../models/GetNetWorthRequestDto';
import type { GetNetWorthResponseDto } from '../models/GetNetWorthResponseDto';
import type { GetNftCollectionByWalletResponseDto } from '../models/GetNftCollectionByWalletResponseDto';
import type { GetNFTLowerPriceResponseDto } from '../models/GetNFTLowerPriceResponseDto';
import type { GetNFTMetadataResponseDto } from '../models/GetNFTMetadataResponseDto';
import type { GetNFTPriceResponseDto } from '../models/GetNFTPriceResponseDto';
import type { GetNftResponseDto } from '../models/GetNftResponseDto';
import type { GetNFTTransfersResponseDto } from '../models/GetNFTTransfersResponseDto';
import type { GetTokenBalanceResponseDto } from '../models/GetTokenBalanceResponseDto';
import type { GetTokenResponseDto } from '../models/GetTokenResponseDto';
import type { GetTransactionsResponseDto } from '../models/GetTransactionsResponseDto';
import type { GetWalletNftsV2Response } from '../models/GetWalletNftsV2Response';
import type { GetWalletPnLBreakdownResponseDto } from '../models/GetWalletPnLBreakdownResponseDto';
import type { GetWalletPnLSummaryResponseDto } from '../models/GetWalletPnLSummaryResponseDto';
import type { GetWalletsHistoryRequestDto } from '../models/GetWalletsHistoryRequestDto';
import type { GetWalletsHistoryResponseDto } from '../models/GetWalletsHistoryResponseDto';
import type { GetWalletsNetWorthRequestDto } from '../models/GetWalletsNetWorthRequestDto';
import type { GetWalletsNetWorthResponseDto } from '../models/GetWalletsNetWorthResponseDto';
import type { GetWalletsTokenBalancesWithPriceRequestDto } from '../models/GetWalletsTokenBalancesWithPriceRequestDto';
import type { GetWalletsTokenBalancesWithPricesDto } from '../models/GetWalletsTokenBalancesWithPricesDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class Web3Service {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Return Balance for a specific token
   * @param address
   * @param chain
   * @returns GetTokenBalanceResponseDto
   * @throws ApiError
   */
  public web3ControllerGetBalance(address: string, chain: string): CancelablePromise<GetTokenBalanceResponseDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: 'web3Service/web3/getBalance',
      query: {
        address: address,
        chain: chain,
      },
    });
  }

  /**
   * @deprecated
   * Return tokens for a specific wallet
   * @param address
   * @param chain
   * @returns any[]
   * @throws ApiError
   */
  public web3ControllerGetWalletTokenBalances(address: string, chain: string): CancelablePromise<any[]> {
    return this.httpRequest.request({
      method: 'GET',
      url: 'web3Service/web3/getWalletTokenBalances',
      query: {
        address: address,
        chain: chain,
      },
    });
  }

  /**
   * @deprecated
   * Return tokens for a specific wallet and the native token of the chain
   * @param address
   * @param chain
   * @returns GetTokenResponseDto
   * @throws ApiError
   */
  public web3ControllerGetWalletTokenBalancesAndNative(
    address: string,
    chain: string,
  ): CancelablePromise<Array<GetTokenResponseDto>> {
    return this.httpRequest.request({
      method: 'GET',
      url: 'web3Service/web3/getWalletTokenBalancesAndNative',
      query: {
        address: address,
        chain: chain,
      },
    });
  }

  /**
   * @deprecated
   * Return transactions for a specific wallet
   * @param address
   * @param chain
   * @param subdomain
   * @param fromDate
   * @param toDate
   * @param cursor
   * @param cursorErc20
   * @param limit
   * @returns GetTransactionsResponseDto
   * @throws ApiError
   */
  public web3ControllerGetWalletTransactions(
    address: string,
    chain: string,
    subdomain?: string,
    fromDate?: string,
    toDate?: string,
    cursor?: string,
    cursorErc20?: string,
    limit?: number,
  ): CancelablePromise<GetTransactionsResponseDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: 'web3Service/web3/getWalletTransactions',
      query: {
        address: address,
        chain: chain,
        subdomain: subdomain,
        from_date: fromDate,
        to_date: toDate,
        cursor: cursor,
        cursorErc20: cursorErc20,
        limit: limit,
      },
    });
  }

  /**
   * @deprecated
   * Return transactions for a specific wallet with decoded call
   * @param address
   * @param chain
   * @param subdomain
   * @param fromDate
   * @param toDate
   * @param cursor
   * @param cursorErc20
   * @param limit
   * @returns GetTransactionsResponseDto
   * @throws ApiError
   */
  public web3ControllerGetWalletTransactionsVerbose(
    address: string,
    chain: string,
    subdomain?: string,
    fromDate?: string,
    toDate?: string,
    cursor?: string,
    cursorErc20?: string,
    limit?: number,
  ): CancelablePromise<GetTransactionsResponseDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: 'web3Service/web3/getWalletTransactionsVerbose',
      query: {
        address: address,
        chain: chain,
        subdomain: subdomain,
        from_date: fromDate,
        to_date: toDate,
        cursor: cursor,
        cursorErc20: cursorErc20,
        limit: limit,
      },
    });
  }

  /**
   * @deprecated
   * Return transactions for a specific wallet
   * @param address
   * @param chain
   * @param subdomain
   * @param fromDate
   * @param toDate
   * @param cursor
   * @param cursorErc20
   * @param limit
   * @returns GetTransactionsResponseDto
   * @throws ApiError
   */
  public web3ControllerGetWalletTransactionsBeta(
    address: string,
    chain: string,
    subdomain?: string,
    fromDate?: string,
    toDate?: string,
    cursor?: string,
    cursorErc20?: string,
    limit?: number,
  ): CancelablePromise<GetTransactionsResponseDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: 'web3Service/web3/getWalletTransactionsBeta',
      query: {
        address: address,
        chain: chain,
        subdomain: subdomain,
        from_date: fromDate,
        to_date: toDate,
        cursor: cursor,
        cursorErc20: cursorErc20,
        limit: limit,
      },
    });
  }

  /**
   * Return ERC20 transactions for a specific wallet
   * @param address
   * @param chain
   * @param cursor
   * @param limit
   * @returns GetERC20HistoryResponseDto
   * @throws ApiError
   */
  public web3ControllerGetErc20History(
    address: string,
    chain: string,
    cursor?: string,
    limit?: number,
  ): CancelablePromise<GetERC20HistoryResponseDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: 'web3Service/web3/getERC20history',
      query: {
        address: address,
        chain: chain,
        cursor: cursor,
        limit: limit,
      },
    });
  }

  /**
   * @deprecated
   * Return Nft for a specific wallet
   * @param address
   * @param chain
   * @param limit
   * @param cursor
   * @returns GetNftResponseDto
   * @throws ApiError
   */
  public web3ControllerGetWalletNft(
    address: string,
    chain: string,
    limit?: number,
    cursor?: string,
  ): CancelablePromise<GetNftResponseDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: 'web3Service/web3/getWalletNft',
      query: {
        address: address,
        chain: chain,
        limit: limit,
        cursor: cursor,
      },
    });
  }

  /**
   * Return Metadata for a specific wallet
   * @param address
   * @param tokenId
   * @param chain
   * @param normalizeMetadata
   * @returns GetNFTMetadataResponseDto
   * @throws ApiError
   */
  public web3ControllerGetNftMetadata(
    address: string,
    tokenId: string,
    chain: string,
    normalizeMetadata: boolean,
  ): CancelablePromise<GetNFTMetadataResponseDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: 'web3Service/web3/getNFTMetadata',
      query: {
        address: address,
        tokenId: tokenId,
        chain: chain,
        normalizeMetadata: normalizeMetadata,
      },
    });
  }

  /**
   * Return NFT floor price
   * @param address
   * @param chain
   * @param days
   * @returns GetNFTLowerPriceResponseDto
   * @throws ApiError
   */
  public web3ControllerGetNftLowerPrice(
    address: string,
    chain: string,
    days: string,
  ): CancelablePromise<GetNFTLowerPriceResponseDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: 'web3Service/web3/getNFTLowerPrice',
      query: {
        address: address,
        chain: chain,
        days: days,
      },
    });
  }

  /**
   * Return NFT floor price
   * @param address
   * @param chain
   * @param tokenId
   * @param limit
   * @param cursor
   * @returns GetNFTTransfersResponseDto
   * @throws ApiError
   */
  public web3ControllerGetNftTransfers(
    address: string,
    chain: string,
    tokenId: string,
    limit?: number,
    cursor?: string,
  ): CancelablePromise<GetNFTTransfersResponseDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: 'web3Service/web3/getNFTTransfers',
      query: {
        address: address,
        chain: chain,
        tokenId: tokenId,
        limit: limit,
        cursor: cursor,
      },
    });
  }

  /**
   * Return Ens resolved address
   * @param address
   * @returns GetEnsFromAddressResponseDto
   * @throws ApiError
   */
  public web3ControllerGetEnsFromAddress(address: string): CancelablePromise<GetEnsFromAddressResponseDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: 'web3Service/web3/getEnsFromAddress',
      query: {
        address: address,
      },
    });
  }

  /**
   * Return address from ENS name
   * @param name
   * @returns GetAddressFromEnsResponseDto
   * @throws ApiError
   */
  public web3ControllerGetAddressFromEns(name: string): CancelablePromise<GetAddressFromEnsResponseDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: 'web3Service/web3/getAddressFromEns',
      query: {
        name: name,
      },
    });
  }

  /**
   * @deprecated
   * Return net worth for a specific address
   * @param requestBody
   * @returns GetNetWorthResponseDto
   * @throws ApiError
   */
  public web3ControllerGetNetWorth(requestBody: GetNetWorthRequestDto): CancelablePromise<GetNetWorthResponseDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: 'web3Service/web3/getNetWorth',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Return ERC20 price
   * @param address
   * @param chainId
   * @returns GetERC20PriceResponseDto
   * @throws ApiError
   */
  public web3ControllerGetErc20Price(address: string, chainId: string): CancelablePromise<GetERC20PriceResponseDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: 'web3Service/web3/getERC20price',
      query: {
        address: address,
        chainId: chainId,
      },
    });
  }

  /**
   * Return transaction by hash
   * @param transactionHash
   * @param chainId
   * @returns any
   * @throws ApiError
   */
  public web3ControllerTransactionByHash(transactionHash: string, chainId: string): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: 'web3Service/web3/transactionByHash',
      query: {
        transactionHash: transactionHash,
        chainId: chainId,
      },
    });
  }

  /**
   * Return ERC20 allowance
   * @param chain
   * @param ownerAddress
   * @param spenderAddress
   * @param tokenAddress
   * @returns GetERC20AllowanceResponseDto
   * @throws ApiError
   */
  public web3ControllerGetErc20Allowance(
    chain: string,
    ownerAddress: string,
    spenderAddress: string,
    tokenAddress: string,
  ): CancelablePromise<GetERC20AllowanceResponseDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: 'web3Service/web3/getERC20Allowance',
      query: {
        chain: chain,
        ownerAddress: ownerAddress,
        spenderAddress: spenderAddress,
        tokenAddress: tokenAddress,
      },
    });
  }

  /**
   * Return Metadata for a specific ERC20 token
   * @param addresses
   * @param chain
   * @returns GetERC20MetadataResponseDto
   * @throws ApiError
   */
  public web3ControllerGetErc20Metadata(
    addresses: Array<string>,
    chain: string,
  ): CancelablePromise<Array<GetERC20MetadataResponseDto>> {
    return this.httpRequest.request({
      method: 'GET',
      url: 'web3Service/web3/getERC20Metadata',
      query: {
        addresses: addresses,
        chain: chain,
      },
    });
  }

  /**
   * Return latest market data
   * @param page
   * @param perPage
   * @returns GetLatestMarketDataResponseDto
   * @throws ApiError
   */
  public web3ControllerGetLatestMarketData(
    page?: number,
    perPage?: number,
  ): CancelablePromise<Array<GetLatestMarketDataResponseDto>> {
    return this.httpRequest.request({
      method: 'GET',
      url: 'web3Service/web3/getLatestMarketData',
      query: {
        page: page,
        perPage: perPage,
      },
    });
  }

  /**
   * Return net worth for all wallets on multiple chains
   * @param requestBody
   * @returns GetWalletsNetWorthResponseDto
   * @throws ApiError
   */
  public web3ControllerGetWalletsNetWorth(
    requestBody: GetWalletsNetWorthRequestDto,
  ): CancelablePromise<GetWalletsNetWorthResponseDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: 'web3Service/web3/getWalletsNetWorth',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Return tokens for all wallets with prices
   * @param requestBody
   * @returns GetWalletsTokenBalancesWithPricesDto
   * @throws ApiError
   */
  public web3ControllerGetWalletsTokenBalancesWithPrices(
    requestBody: GetWalletsTokenBalancesWithPriceRequestDto,
  ): CancelablePromise<GetWalletsTokenBalancesWithPricesDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: 'web3Service/web3/getWalletsTokenBalancesWithPrices',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Return metrics regarding NFTs sales, including the sell price
   * @param chainId The chain Id of the NFT in hex
   * @param days The number of days to consider in the calculation
   * @param address The address of the NFT
   * @param tokenId The tokenId of the NFT
   * @returns GetNFTPriceResponseDto
   * @throws ApiError
   */
  public web3ControllerNftPrice(
    chainId: string,
    days: string,
    address: string,
    tokenId: string,
  ): CancelablePromise<GetNFTPriceResponseDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: 'web3Service/web3/nftPrice',
      query: {
        chainId: chainId,
        days: days,
        address: address,
        token_id: tokenId,
      },
    });
  }

  /**
   * Return history of all wallets
   * @param requestBody
   * @returns GetWalletsHistoryResponseDto
   * @throws ApiError
   */
  public web3ControllerGetWalletsHistory(
    requestBody: GetWalletsHistoryRequestDto,
  ): CancelablePromise<Array<GetWalletsHistoryResponseDto>> {
    return this.httpRequest.request({
      method: 'POST',
      url: 'web3Service/web3/getWalletsHistory',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Return Nft for a specific wallet
   * @param address
   * @param chainIdHex
   * @param limit
   * @param excludeSpam
   * @param cursor
   * @param normalizeMetadata
   * @param includePrices
   * @param collectionAddresses
   * @returns GetWalletNftsV2Response
   * @throws ApiError
   */
  public web3ControllerGetWalletNftV2(
    address: string,
    chainIdHex: string,
    limit?: number,
    excludeSpam?: boolean,
    cursor?: string,
    normalizeMetadata?: boolean,
    includePrices?: boolean,
    collectionAddresses?: Array<string>,
  ): CancelablePromise<GetWalletNftsV2Response> {
    return this.httpRequest.request({
      method: 'GET',
      url: 'web3Service/web3/getWalletNftV2',
      query: {
        address: address,
        chainIdHex: chainIdHex,
        limit: limit,
        excludeSpam: excludeSpam,
        cursor: cursor,
        normalizeMetadata: normalizeMetadata,
        includePrices: includePrices,
        collectionAddresses: collectionAddresses,
      },
    });
  }

  /**
   * Return a list of all NFT collections for a specific wallet
   * @param address
   * @param chain
   * @param limit
   * @param excludeSpam
   * @param cursor
   * @returns GetNftCollectionByWalletResponseDto
   * @throws ApiError
   */
  public web3ControllerGetNftsCollectionByWallet(
    address: string,
    chain: string,
    limit: string,
    excludeSpam: boolean,
    cursor?: string,
  ): CancelablePromise<GetNftCollectionByWalletResponseDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: 'web3Service/web3/getWalletNftCollections',
      query: {
        address: address,
        chain: chain,
        limit: limit,
        excludeSpam: excludeSpam,
        cursor: cursor,
      },
    });
  }

  /**
   * Return ERC20 token owners
   * @param chainId
   * @param tokenAddress
   * @param cursor
   * @param limit
   * @returns GetErc20TokenOwnersResponseDto
   * @throws ApiError
   */
  public web3ControllerGetErc20TokenOwners(
    chainId: string,
    tokenAddress: string,
    cursor?: string,
    limit?: number,
  ): CancelablePromise<GetErc20TokenOwnersResponseDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: 'web3Service/web3/getErc20TokenOwners',
      query: {
        chainId: chainId,
        tokenAddress: tokenAddress,
        cursor: cursor,
        limit: limit,
      },
    });
  }

  /**
   * Return DeFi summary of a wallet address
   * @param chainId
   * @param address
   * @returns GetDeFiSummaryResponseDto
   * @throws ApiError
   */
  public web3ControllerGetDeFiSummaryByWallet(
    chainId: string,
    address: string,
  ): CancelablePromise<GetDeFiSummaryResponseDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: 'web3Service/web3/getDeFiSummaryByWallet',
      query: {
        chainId: chainId,
        address: address,
      },
    });
  }

  /**
   * Return DeFi positions summary of a wallet address
   * @param chainId
   * @param address
   * @returns GetDeFiPositionsResponseDto
   * @throws ApiError
   */
  public web3ControllerGetDeFiPositionsByWallet(
    chainId: string,
    address: string,
  ): CancelablePromise<Array<GetDeFiPositionsResponseDto>> {
    return this.httpRequest.request({
      method: 'GET',
      url: 'web3Service/web3/getDeFiPositionsByWallet',
      query: {
        chainId: chainId,
        address: address,
      },
    });
  }

  /**
   * Return DeFi position details by wallet address and protocol
   * @param chainId
   * @param address
   * @param protocol
   * @returns GetDeFiPositionsResponseDto
   * @throws ApiError
   */
  public web3ControllerGetDeFiPositionsByWalletAndProtocol(
    chainId: string,
    address: string,
    protocol: string,
  ): CancelablePromise<GetDeFiPositionsResponseDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: 'web3Service/web3/getDeFiPositionsByWalletAndProtocol',
      query: {
        chainId: chainId,
        address: address,
        protocol: protocol,
      },
    });
  }

  /**
   * Return profitability information for a wallet
   * @param chainId
   * @param address
   * @param days
   * @param tokenAddresses
   * @returns GetWalletPnLBreakdownResponseDto
   * @throws ApiError
   */
  public web3ControllerGetWalletPnLBreakdown(
    chainId: string,
    address: string,
    days?: string,
    tokenAddresses?: Array<string>,
  ): CancelablePromise<GetWalletPnLBreakdownResponseDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: 'web3Service/web3/getWalletPnLBreakdown',
      query: {
        chainId: chainId,
        address: address,
        days: days,
        tokenAddresses: tokenAddresses,
      },
    });
  }

  /**
   * Return profitability summary for a wallet
   * @param chainId
   * @param address
   * @param days
   * @returns GetWalletPnLSummaryResponseDto
   * @throws ApiError
   */
  public web3ControllerGetWalletPnLSummary(
    chainId: string,
    address: string,
    days?: string,
  ): CancelablePromise<GetWalletPnLSummaryResponseDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: 'web3Service/web3/getWalletPnLSummary',
      query: {
        chainId: chainId,
        address: address,
        days: days,
      },
    });
  }
}
