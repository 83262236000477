export const language = {
  en: {
    strategy: {
      title: 'Strategy Alert',
      description: 'Get notified when your saved strategy spots new tokens.',
    },
    token: {
      title: 'Token Alert',
      description: 'Alerts for token price, buyer activity, volume, and more.',
    },
    bundle: {
      title: 'Bundle Alert',
      description: 'Get notified instantly when top traders make a move.',
    },
  },
};
