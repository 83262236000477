import { createContext, useContext, useState } from 'react';

interface FormResetContextType {
  setTriggerReset: (trigger: boolean) => void;
  setTriggerResetTable: (trigger: boolean) => void;
  triggerReset: boolean;
  triggerResetTable: boolean;
}

const FormResetContext = createContext<FormResetContextType>({
  triggerReset: false,
  setTriggerReset: () => {},
  triggerResetTable: false,
  setTriggerResetTable: () => {},
});

export const FormResetProvider = ({ children }: React.PropsWithChildren) => {
  const [triggerReset, setTriggerReset] = useState(false);
  const [triggerResetTable, setTriggerResetTable] = useState(false);

  return (
    <FormResetContext.Provider value={{ triggerReset, setTriggerReset, triggerResetTable, setTriggerResetTable }}>
      {children}
    </FormResetContext.Provider>
  );
};

export const useFormResetContext = () => useContext(FormResetContext);
