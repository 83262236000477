/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type addLockOptions = {
  token1Address: string | null;
  apiType: string;
  lockDate: number | null;
  unlockDate: number | null;
  lockedAmountInPercent: number;
  type: addLockOptions.type;
  pairAddress: string | null;
};

export namespace addLockOptions {
  export enum type {
    PAIR = 'pair',
    TOKEN = 'token',
  }
}
