/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SubscriptionCancellationRequestedDto } from '../models/SubscriptionCancellationRequestedDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class TrackService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public trackControllerListPublicPlans(requestBody: SubscriptionCancellationRequestedDto): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: 'billingCoordinator/track/subscription-cancellation-requested',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
}
