import { Modal, ModalClose, ModalContent } from '@/components/core/Modal';
import { useAlertModalContext } from '../../context';
import { ITokenAlertProps, TokenAlert } from '../../organisms';
import { language } from './values';
import styles from '../../styles.module.scss';
import typography from '@/styles/scss/typography.module.scss';

const TokenAlertModal = () => {
  const { modalProps, closeAlertModal, modalContent } = useAlertModalContext<ITokenAlertProps>();

  if (modalContent !== 'token') return null;

  return (
    <Modal initialOpen={true} onOpenChange={closeAlertModal}>
      <ModalContent className={styles.modal}>
        <div className={styles.header}>
          <h5 className={typography.h3}>{language.en.title(Boolean(modalProps?.notification))}</h5>
          <ModalClose />
        </div>
        <TokenAlert
          alert={modalProps?.alert}
          notification={modalProps?.notification}
          tokenData={modalProps?.tokenData}
        />
      </ModalContent>
    </Modal>
  );
};

export default TokenAlertModal;
