export const assert = (env: string, val?: unknown) => {
  throw Error(`Invalid value ${env} ${val}`);
};

export const toRequiredString = (
  value: string | unknown,
  { varName, defaultValue }: { varName: string; defaultValue?: string },
): string => (typeof value === 'string' ? value : defaultValue ?? assert(varName, value));

export const toOptionalString = (
  value: string | undefined,
  { defaultValue }: { defaultValue?: string } = {},
): string | undefined => value ?? defaultValue;

export const toNumber = (
  value: string | undefined,
  {
    varName,
    defaultValue,
    min = -Infinity,
    max = Infinity,
  }: { varName: string; defaultValue?: number; min?: number; max?: number },
): number => {
  if (!value) {
    return defaultValue ?? assert(varName, value);
  }
  const n = Number(value);
  if (Number.isFinite(n) && n >= min && n <= max) {
    return n;
  }
  return assert(varName, value);
};
