import { formatTokenPrice, getValueOrNA } from '@/utils/fmt';
import {
  AlertType,
  coolDownTimeframeOptionsMap,
  CooldownTimeFrameType,
  filterOptionsMap,
  filterTimeframeOptionsMap,
  FilterType,
  operatorOptionsMap,
  OperatorType,
  TimeframeType,
} from '../../values';
import { language } from './values';
import styles from './styles.module.scss';

interface IAlertScenarioVisualizationProps {
  alertType: AlertType;
  className?: string;
}

interface TokenAlertProps extends IAlertScenarioVisualizationProps {
  alertType: 'token';
  amount?: number;
  cooldownTimeframe?: CooldownTimeFrameType;
  filter?: FilterType;
  operator?: OperatorType;
  timeframe?: TimeframeType;
  tokenName?: string;
}

interface BundleAlertProps extends IAlertScenarioVisualizationProps {
  alertType: 'bundle';
  bundleName?: string;
}

interface StrategyAlertProps extends IAlertScenarioVisualizationProps {
  alertType: 'strategy';
  cooldownTimeframe?: CooldownTimeFrameType;
  strategyName?: string;
}

type AlertProps = TokenAlertProps | BundleAlertProps | StrategyAlertProps;

const AlertScenarioTextVisualization = (props: AlertProps) => {
  let content: React.ReactNode;
  switch (props.alertType) {
    case 'token':
      content = renderTokenTextVisualization(props as TokenAlertProps);
      break;
    case 'bundle':
      content = renderBundleTextVisualization(props as BundleAlertProps);
      break;
    case 'strategy':
      content = renderStrategyTextVisualization(props as StrategyAlertProps);
      break;
  }

  return <div className={`${styles.alertScenarioTextVisualization} ${props.className || ''}`}>{content}</div>;
};

const HighlightedText = ({ children }: React.PropsWithChildren) => (
  <span className={styles.highlightedText}>{children}</span>
);

function renderTokenTextVisualization({
  amount,
  cooldownTimeframe,
  filter,
  operator,
  timeframe,
  tokenName,
}: TokenAlertProps) {
  const cooldownValue = getValueOrNA(coolDownTimeframeOptionsMap[cooldownTimeframe!]);
  const filterValue = getValueOrNA(filterOptionsMap[filter!]);
  const operatorValue = getValueOrNA(operatorOptionsMap[operator!]);
  const timeframeValue = getValueOrNA(filterTimeframeOptionsMap[timeframe!]);
  const isDollarValue = filter === 'netVolumeChangeUSD' || filter === 'priceChange';

  return (
    <>
      {language.en.youWillBeAlertedEvery} <HighlightedText>{cooldownValue}</HighlightedText> {language.en.when}{' '}
      <HighlightedText>{filterValue}</HighlightedText> {language.en.for}{' '}
      <HighlightedText>{getValueOrNA(tokenName)}</HighlightedText> {language.en.is}{' '}
      <HighlightedText>{operatorValue}</HighlightedText>{' '}
      <HighlightedText>{isDollarValue ? formatTokenPrice(amount) : getValueOrNA(amount)}</HighlightedText>{' '}
      {language.en.inTheLast} <HighlightedText>{timeframeValue}</HighlightedText>
    </>
  );
}

function renderBundleTextVisualization({ bundleName }: BundleAlertProps) {
  return (
    <>
      {language.en.youWillBeAlertedEachTime} <HighlightedText>{getValueOrNA(bundleName)}</HighlightedText>{' '}
      {language.en.buysOrSellsAToken}
    </>
  );
}

function renderStrategyTextVisualization({ cooldownTimeframe, strategyName }: StrategyAlertProps) {
  const cooldownValue = getValueOrNA(coolDownTimeframeOptionsMap[cooldownTimeframe!]);
  return (
    <>
      {language.en.every} <HighlightedText>{cooldownValue}</HighlightedText> {language.en.youWillGetANotification}{' '}
      <HighlightedText>{getValueOrNA(strategyName)}</HighlightedText> {language.en.hasFoundYou}
    </>
  );
}

export default AlertScenarioTextVisualization;
