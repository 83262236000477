import { EPortfolioList, UserWallet } from '../libs/types';

export type TList = Record<EPortfolioList, Array<UserWallet>>;
export const organizeWalletsByList = (userPortfolioData: Array<UserWallet>): TList => {
  const yourPortfolioList: Array<UserWallet> = [];
  const watchedWalletsList: Array<UserWallet> = [];
  const result = {
    [EPortfolioList.MY]: yourPortfolioList,
    [EPortfolioList.WATCHED]: watchedWalletsList,
  };
  userPortfolioData.forEach((wallet: UserWallet) => {
    const listName = wallet?.apiData.settings?.currentListName as EPortfolioList;
    if (listName) result[listName].push(wallet);
  });
  return result;
};
