import { arbitrum, avalanche, base, bsc, fantom, mainnet, optimism, polygon, pulsechain } from 'wagmi/chains';
import { getDefaultConfig } from '@rainbow-me/rainbowkit';

const APP_NAME = 'Moralis';
const PROJECT_ID = '4fa7ec8bb2b9c02b0cc6469545448623';

export const RAINBOW_CONFIG = getDefaultConfig({
  appName: APP_NAME,
  projectId: PROJECT_ID,
  chains: [mainnet, bsc, polygon, arbitrum, fantom, optimism, pulsechain, base, avalanche],
  ssr: true,
});
