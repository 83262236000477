import { useFormContext } from 'react-hook-form';
import { ControlledInput, ControlledSelect, PriceInputWithSlider } from '../../atoms';
import { ITokenAlertCoin, ITokenAlertFormData } from '../../organisms/TokenAlert/types';
import { filterOptions, operatorOptions, timeframeOptions } from '../../values';
import { language } from './values';
import stylesCommon from '../styles.module.scss';
import styles from './styles.module.scss';
import typography from '@/styles/scss/typography.module.scss';

interface IConditionFieldsetProps {
  tokenData?: ITokenAlertCoin;
}

const ConditionFieldset = ({ tokenData }: IConditionFieldsetProps) => {
  const { watch } = useFormContext<ITokenAlertFormData>();

  return (
    <div>
      <div className={stylesCommon.col25}>
        <h5 className={typography.bodySmallRegular}>{language.en.title}</h5>
      </div>
      <div className={`${stylesCommon.col75}`}>
        <div className={`${styles.content} ${watch('selectFilter') === 'priceChange' ? styles.gap16 : styles.gap8}`}>
          <ControlledSelect
            required
            name="selectFilter"
            options={filterOptions}
            placeholder={language.en.filterPlaceholder}
          />

          {watch('selectFilter') === 'priceChange' ? (
            <PriceInputWithSlider price={tokenData?.price} />
          ) : (
            <>
              <div className={styles.horizontalStack}>
                <ControlledSelect
                  required
                  name="selectOperator"
                  options={watch('selectFilter') === 'netVolumeChangeUSD' ? operatorOptions[1] : operatorOptions[0]}
                  placeholder={language.en.operatorPlaceholder}
                />
                <ControlledInput required name="inputValue" placeholder={language.en.inputPlaceholder} type="number" />
              </div>
              <ControlledSelect
                required
                name="selectTimeframe"
                options={timeframeOptions}
                placeholder={language.en.timeframePlaceholder}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ConditionFieldset;
