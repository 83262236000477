/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AlertCountResponseDto } from '../models/AlertCountResponseDto';
import type { AlertDecreaseTokenAlertCountRequestDto } from '../models/AlertDecreaseTokenAlertCountRequestDto';
import type { AlertUnsubscribeAlertsRequestDto } from '../models/AlertUnsubscribeAlertsRequestDto';
import type { GetPublicStrategyAlertsDto } from '../models/GetPublicStrategyAlertsDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AlertService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Returns object with how many alerts user has for each type of alert
   * @returns AlertCountResponseDto
   * @throws ApiError
   */
  public alertControllerUserAlertsCount(): CancelablePromise<AlertCountResponseDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: 'userService/alert/userAlertsCount',
    });
  }

  /**
   * Notify Moralis Money from external service when a token alert has been deactivated
   * @param requestBody
   * @returns AlertCountResponseDto
   * @throws ApiError
   */
  public alertControllerDecreaseTokenAlertCount(
    requestBody: AlertDecreaseTokenAlertCountRequestDto,
  ): CancelablePromise<AlertCountResponseDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: 'userService/alert/decreaseTokenAlertCount',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Unsubscribe from all alerts
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public alertControllerUnsubscribeAllAlerts(requestBody: AlertUnsubscribeAlertsRequestDto): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: 'userService/alert/unsubscribeAllAlerts',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Get public strategies
   * @returns GetPublicStrategyAlertsDto
   * @throws ApiError
   */
  public alertControllerGetAllPublicStrategies(): CancelablePromise<GetPublicStrategyAlertsDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: 'userService/alert/publicStrategyAlerts',
    });
  }
}
