import { UserItemResponseDto } from '@/services/index';

export type TTab = 'chests' | 'quests';

export enum EChestState {
  CLAIMED = 'claimed',
  COMING_SOON = 'coming_soon',
  OWNED = 'owned',
}

export enum EChestRarity {
  EPIC = 'epic',
  RARE = 'rare',
  REGULAR = 'regular',
}

export type ItemsMapType = {
  [category: string]: {
    [id: string]: string;
  };
};

export type TAvatarsList = {
  id: string | number;
  isCurrent?: boolean;
  isLocked?: boolean;
  isSelected?: boolean;
  name?: string;
};

export type TZealyLeaderboard = {
  address: string;
  addresses: Record<string, unknown>;
  avatar: string;
  discord: string;
  discordId: string;
  name: string;
  numberOfQuests: number;
  twitter: string;
  userId: string;
  xp: number;
}[];

export type TZealyUser = {
  avatar: string;
  city: string;
  country: string;
  karma: string;
  level: string;
  name: string;
  numberOfQuests: number;
  rank: string;
  xp: number;
};

export const OG_AVATAR_ID = 2;
export const DEFAULT_AVATAR_ID = -1;

export const ZEALY_LEADERBOARD_ENDPOINT = '/api/zealy/leaderboard';
export const ZEALY_USER_ENDPOINT = '/api/zealy/users?ethAddress=';
export const ZEALY_QUESTIONS_BOARD_URL = 'https://zealy.io/c/moralismoney/questboard';
export const ZEALY_REGISTER_URL = 'https://zealy.io/c/moralismoney/invite/Yz74xg267SQLVsBbiavxa';

export const DEFAULT_AVATAR_NAME = 'Default Avatar';
export const DEFAULT_LOCKED_AVATAR_ID = 'locked_avatar';
export const EPIC_BEAN_NAME = 'Epic Bean';

export enum EItemCategory {
  AVATAR = 'avatar',
  BEAN = 'bean',
}

export const DEFAULT_AVATAR: UserItemResponseDto = {
  id: '000-000-000-000',
  moneyId: '000-000-000-000',
  itemId: DEFAULT_AVATAR_ID,
  name: DEFAULT_AVATAR_NAME,
  description: 'Default Avatar',
  rarity: EChestRarity.REGULAR,
  total: 1,
  category: EItemCategory.AVATAR,
  properties: {},
  createdAt: 0,
  updatedAt: 0,
};
