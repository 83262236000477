import { useTabsContext } from '../context';

const TabPanel = ({
  id,
  children,
}: React.PropsWithChildren<{
  id: string;
}>) => {
  const { activeTab } = useTabsContext();

  if (id !== activeTab) {
    return null;
  }

  return (
    <div aria-labelledby={id} role="tabpanel">
      {children}
    </div>
  );
};

export default TabPanel;
