import create from 'zustand';

type BoolStore<T extends string> = {
  varName: T;
  close: () => void;
  isOpen: boolean;
  open: () => void;
  toggle: () => void;
};

export const createBooleanStore = <T extends string>(varName: T) =>
  create<BoolStore<T>>((set) => {
    return {
      varName,
      isOpen: false,
      close: () => set({ isOpen: false }),
      open: () => set({ isOpen: true }),
      toggle: () => set((state) => ({ isOpen: !state.isOpen })),
    };
  });
