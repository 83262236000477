import { ApiServiceClass } from '@/services/ApiServiceClass';
import useAuthStore from '@/stores/authStore';

export const api = new ApiServiceClass({
  BASE: '/api/',
  HEADERS: async () => {
    const headers = new Headers();

    if (useAuthStore?.getState()?.requireEmailToken) {
      // This will only be true if the user does not have an email linked to his account
      headers.append('Authorization', `Bearer ${useAuthStore?.getState()?.requireEmailToken}`);
    } else if (useAuthStore?.getState()?.authToken) {
      headers.append('Authorization', `Bearer ${useAuthStore?.getState()?.authToken}`);
    }

    return Object.fromEntries(headers);
  },
});
