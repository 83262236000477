/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type AlertDto = {
  /**
   * The unique uuid ID of the alert
   */
  alertId: string;
  /**
   * How often the alert should be triggered
   */
  timeframe: AlertDto.timeframe;
  /**
   * The operator to use when comparing the threshold value to the current value
   */
  operator: AlertDto.operator;
  /**
   * The type of filter used by the alert
   */
  filter: AlertDto.filter;
  /**
   * The value of the alert threshold
   */
  thresholdValue: string;
  /**
   * No use at the moment
   */
  topic: string;
  /**
   * The address of the token to be monitored
   */
  tokenAddress: string;
  /**
   * No use at the moment
   */
  intervalInSeconds: string;
  /**
   * The name of the token to be monitored
   */
  tokenName: string;
  /**
   * The ID of the chain the token is on
   */
  chainId: string;
};

export namespace AlertDto {
  /**
   * How often the alert should be triggered
   */
  export enum timeframe {
    _10 = '10',
    _30 = '30',
    _60 = '60',
    _240 = '240',
    _720 = '720',
    _1440 = '1440',
    _2880 = '2880',
    _5760 = '5760',
    _10080 = '10080',
    _20160 = '20160',
    _30240 = '30240',
    _43200 = '43200',
    _129600 = '129600',
  }

  /**
   * The operator to use when comparing the threshold value to the current value
   */
  export enum operator {
    LT = 'lt',
    GT = 'gt',
  }

  /**
   * The type of filter used by the alert
   */
  export enum filter {
    EXPERIENCED_NET_BUYERS_CHANGE = 'experiencedNetBuyersChange',
    NET_BUYERS_CHANGE = 'netBuyersChange',
    NET_VOLUME_CHANGE_USD = 'netVolumeChangeUSD',
    PRICE_CHANGE = 'priceChange',
  }
}
