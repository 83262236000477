import clsx from 'clsx';
import { ethers } from 'ethers';
import { ButtonOutline, ButtonPrimary, Input, Loader } from 'moralis-ui';
import { faEye, faWallet } from '@fortawesome/pro-solid-svg-icons';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './AddWallet.module.scss';

export type TAddWalletTo = 'watchList' | 'portfolio';

export const AddWalletFormUI = ({
  hasAlreadyAddedThatWallet,
  isLoadingEnsName,
  searchTerm,
  addToList,
  isAdding,
  isWalletConnected,
  onChangeInput,
  onAddToWatchlist,
  onAddWallet,
  onConnectWallet,
}: {
  addToList: TAddWalletTo | null;
  isLoadingEnsName: boolean;
  hasAlreadyAddedThatWallet: boolean;
  searchTerm: string;
  isAdding: boolean;
  isWalletConnected: boolean;
  onChangeInput: (v: string) => void;
  onAddToWatchlist: () => void;
  onAddWallet: () => void;
  onConnectWallet: () => void;
}) => {
  const isButtonDisabled = hasAlreadyAddedThatWallet || !searchTerm || !ethers.utils.isAddress(searchTerm) || isAdding;

  const getSlotsAfter = () => {
    if (hasAlreadyAddedThatWallet) {
      return null;
    }
    if (isLoadingEnsName) {
      return <Loader />;
    }
    if (ethers.utils.isAddress(searchTerm))
      return <FontAwesomeIcon icon={faCheck} style={{ width: '16px', color: '#00AD96' }} />;
  };

  return (
    <div className={styles.content}>
      <p className={clsx('body-14-regular', 'color-headline-secondary')}>
        Enter any valid wallet address to add it to your watch list
      </p>

      <span className={styles.inputContainer}>
        <Input
          error={hasAlreadyAddedThatWallet ? 'Wallet already added' : undefined}
          id="add-wallet-input"
          placeholder={'Type a wallet address or ENS name'}
          size="md"
          slots={{ after: <span>{getSlotsAfter()}</span> }}
          style={{ width: '100%' }}
          value={searchTerm}
          onChange={(e) => onChangeInput(e.target.value)}
        />
      </span>

      <div className={styles.addManuallyButtons}>
        <ButtonOutline
          disabled={isButtonDisabled}
          id="add-to-portfolio-button"
          isLoading={isAdding && addToList === 'portfolio'}
          onClick={onAddWallet}
        >
          <FontAwesomeIcon icon={faWallet} /> Add to Portfolio
        </ButtonOutline>
        <ButtonOutline
          disabled={isButtonDisabled}
          id="add-to-watchlist-button"
          isLoading={isAdding && addToList === 'watchList'}
          onClick={onAddToWatchlist}
        >
          <FontAwesomeIcon icon={faEye} /> Add to Watch List
        </ButtonOutline>
      </div>

      <div className={styles.divider}>
        <hr className={clsx(styles.hr, 'border-input-default')} />
        <p className="body-14-regular color-headline-secondary"> OR </p>
        <hr className={clsx(styles.hr, 'border-input-default')} />
      </div>

      <p className={clsx('body-14-regular', 'color-headline-secondary')}>
        Connect and verify your own wallet to add it to your portfolio
      </p>

      <ButtonPrimary
        className={styles.connectYourWalletButton}
        disabled={isAdding}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onConnectWallet();
        }}
      >
        <FontAwesomeIcon icon={faWallet} />
        {isWalletConnected ? 'Disconnect' : 'Connect'} Wallet
      </ButtonPrimary>
    </div>
  );
};
