export enum EProtocolType {
  EMAIL = 'email',
  TELEGRAM = 'telegram',
  WEBHOOK = 'webhook',
}

export type TValidatedProtocols = {
  data: string[];
  type: EProtocolType;
};
