import useSWR from 'swr';
import { API_REQUEST_SLOW_TIMEOUT } from '@/components/portfolio/const/constants';
import { asyncStorageWithTTL } from '@/utils/asyncStorage';

export const useQueryWithStorage = <T>(
  fetcher: () => Promise<T>,
  {
    key,
    ttl,
    skipIf = false,
    refreshInterval,
    slowTimeout = API_REQUEST_SLOW_TIMEOUT,
    keepPreviousData = false,
    onSuccess,
  }: {
    keepPreviousData?: boolean;
    key: string;
    onSuccess?: (data: T) => void;
    refreshInterval?: number;
    skipIf?: boolean;
    slowTimeout?: number;
    ttl: number;
  },
) => {
  const as = asyncStorageWithTTL.create<T>(ttl);
  const resp = useSWR(skipIf ? null : key, fetcher, {
    onSuccess: (data) => {
      as.setItem(key, data);
      onSuccess?.(data);
    },
    onLoadingSlow: async () => {
      const cachedData = await as.getItem(key);
      if (cachedData !== null) {
        resp.mutate(cachedData);
      }
    },
    loadingTimeout: slowTimeout,
    keepPreviousData,
    revalidateOnReconnect: true,
    refreshInterval,
  });
  return resp;
};
