import { createBooleanStore } from './createBooleanStore';

const useAuthModalZustand = createBooleanStore('auth');
const { open: openAuthModal, close: closeAuthModal, toggle: toggleAuthModal } = useAuthModalZustand.getState();
export const useAuthModal = () => ({
  isAuthModalOpen: useAuthModalZustand((s) => s.isOpen),
  openAuthModal,
  closeAuthModal,
  toggleAuthModal,
});

const useQueryModalZustand = createBooleanStore('query');
const { open: openQueryModal, close: closeQueryModal, toggle: toggleQueryModal } = useQueryModalZustand.getState();
export const useQueryModal = () => ({
  isQueryModalOpen: useQueryModalZustand((s) => s.isOpen),
  openQueryModal,
  closeQueryModal,
  toggleQueryModal,
});
