/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { NotificationConfigDto } from './NotificationConfigDto';

export type NotificationDto = {
  /**
   * The unique uuid ID of the alert
   */
  alertId: string;
  /**
   * Unique uuid ID of the user account
   */
  userId: string;
  /**
   * If the alert should be sent to the user
   */
  enabled: boolean;
  /**
   * The name of the alert submitted by the user
   */
  name: string;
  /**
   * The configuration of the alert
   */
  config: NotificationConfigDto;
  /**
   * The type of alert, "bundle", "token", "strategy"
   */
  alertType: NotificationDto.alertType;
};

export namespace NotificationDto {
  /**
   * The type of alert, "bundle", "token", "strategy"
   */
  export enum alertType {
    TOKEN = 'token',
    BUNDLE = 'bundle',
    STRATEGY = 'strategy',
  }
}
