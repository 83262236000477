import { UserWallet } from '../libs/types';
import { TList } from './organizeWalletsByList';

export const getMergedList = (data: TList) => Object.values(data as TList).flat();

export const aggregateAddresses = (list: Array<UserWallet>) =>
  list
    .filter((data) => data.apiData.settings?.includeInAggregateView)
    .map((wallet) => wallet.apiData.address)
    .join('-');
