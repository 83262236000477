/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { WalletsRequestDto } from './WalletsRequestDto';

export type UserUpdatePortfolioWalletsRequestDto = {
  wallets: Array<WalletsRequestDto>;
  action: UserUpdatePortfolioWalletsRequestDto.action;
};

export namespace UserUpdatePortfolioWalletsRequestDto {
  export enum action {
    ADD = 'add',
    REMOVE = 'remove',
  }
}
