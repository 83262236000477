import React from 'react';
import {
  FloatingFocusManager,
  FloatingOverlay,
  FloatingPortal,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useMergeRefs,
  useRole,
} from '@floating-ui/react';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ButtonBase } from '../Button/ButtonBase';
import styles from './styles.module.scss';

export interface ModalOptions {
  closeOnOutsideClick?: boolean;
  initialOpen?: boolean;
  onOpenChange?: (open: boolean) => void;
  open?: boolean;
}

export function useModal({
  initialOpen = false,
  open: controlledOpen,
  onOpenChange: setControlledOpen,
  closeOnOutsideClick = true,
}: ModalOptions = {}) {
  const [uncontrolledOpen, setUncontrolledOpen] = React.useState(initialOpen);
  const [labelId, setLabelId] = React.useState<string | undefined>();
  const [descriptionId, setDescriptionId] = React.useState<string | undefined>();

  const open = controlledOpen ?? uncontrolledOpen;
  const setOpen = setControlledOpen ?? setUncontrolledOpen;

  const data = useFloating({
    open,
    onOpenChange: setOpen,
  });

  const context = data.context;

  const click = useClick(context, {
    enabled: controlledOpen == null,
  });
  const dismiss = useDismiss(
    context,
    closeOnOutsideClick ? { outsidePressEvent: 'mousedown' } : { outsidePress: false },
  );
  const role = useRole(context);

  const interactions = useInteractions([click, dismiss, role]);

  return React.useMemo(
    () => ({
      open,
      setOpen,
      ...interactions,
      ...data,
      labelId,
      descriptionId,
      setLabelId,
      setDescriptionId,
    }),
    [open, setOpen, interactions, data, labelId, descriptionId],
  );
}

type ContextType =
  | (ReturnType<typeof useModal> & {
      setDescriptionId: React.Dispatch<React.SetStateAction<string | undefined>>;
      setLabelId: React.Dispatch<React.SetStateAction<string | undefined>>;
    })
  | null;

const ModalContext = React.createContext<ContextType>(null);

export const useModalContext = () => {
  const context = React.useContext(ModalContext);

  if (context == null) {
    throw new Error('Modal components must be wrapped in <Modal />');
  }

  return context;
};

export function Modal({
  children,
  ...options
}: {
  children: React.ReactNode;
} & ModalOptions) {
  const modal = useModal(options);
  return <ModalContext.Provider value={modal}>{children}</ModalContext.Provider>;
}

export const ModalContent = React.forwardRef<HTMLDivElement, React.HTMLProps<HTMLDivElement>>(function ModalContent(
  props,
  propRef,
) {
  const { context: floatingContext, ...context } = useModalContext();
  const ref = useMergeRefs([context.refs.setFloating, propRef]);

  if (!floatingContext.open) return null;

  return (
    <FloatingPortal>
      <FloatingOverlay lockScroll className={styles.overlay}>
        <FloatingFocusManager context={floatingContext}>
          <div
            aria-describedby={context.descriptionId}
            aria-labelledby={context.labelId}
            ref={ref}
            {...context.getFloatingProps(props)}
          >
            {props.children}
          </div>
        </FloatingFocusManager>
      </FloatingOverlay>
    </FloatingPortal>
  );
});

ModalContent.displayName = 'ModalContent';

export const ModalClose = React.forwardRef<HTMLButtonElement, React.ButtonHTMLAttributes<HTMLButtonElement>>(
  function ModalClose({ ...props }, ref) {
    const { setOpen } = useModalContext();
    return (
      <ButtonBase
        className={styles.close}
        size="small"
        type="button"
        {...props}
        ref={ref}
        onClick={() => setOpen(false)}
      >
        <FontAwesomeIcon icon={faXmark} style={{ width: `12px` }} />
      </ButtonBase>
    );
  },
);

ModalClose.displayName = 'ModalClose';
