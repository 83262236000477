import { Controller, useFormContext } from 'react-hook-form';
import { Select, TSelectOption } from '@/components/core/Select';
import { IBundleAlertFormData } from '../../organisms/BundleAlert/types';
import { IStrategyAlertFormData } from '../../organisms/StrategyAlert/types';
import { ITokenAlertFormData } from '../../organisms/TokenAlert/types';

type TTokenAlertSelectName = keyof Pick<
  ITokenAlertFormData,
  'selectCoolDown' | 'selectFilter' | 'selectOperator' | 'selectTimeframe' | 'selectToken'
>;
type TStrategyAlertSelectName = keyof Pick<IStrategyAlertFormData, 'selectStrategy'>;

type TBundleAlertSelectName = keyof Pick<IBundleAlertFormData, 'selectBundle'>;
interface IControlledSelectProps {
  name: TTokenAlertSelectName | TStrategyAlertSelectName | TBundleAlertSelectName;
  options: TSelectOption[];
  placeholder: string;
  required?: boolean;
}
const ControlledSelect = ({ name, required = true, options, placeholder }: IControlledSelectProps) => {
  const { control } = useFormContext<ITokenAlertFormData | IStrategyAlertFormData | IBundleAlertFormData>();
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, onBlur, value, name }, fieldState: { invalid } }) => (
        <Select
          hasError={invalid}
          id={name}
          name={name}
          options={options}
          placeholder={placeholder}
          value={value as string | number}
          onBlur={onBlur}
          onChange={onChange}
        />
      )}
      rules={{ required }}
    />
  );
};

export default ControlledSelect;
