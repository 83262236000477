import { formatDistanceStrict } from 'date-fns';

const timestampToDate = (timestamp: number | string) => new Date(Number(timestamp) * 1000);

/**
 * Timestamp in seconds to distance till now
 *
 * TODO: (Maxim) merge with ./timeAgoFromTimeStamp ?
 */
export const getTimestampDistance = (
  timestamp: number | string,
  unit?: 'second' | 'minute' | 'hour' | 'day' | 'month' | 'year',
) => formatDistanceStrict(timestampToDate(timestamp), new Date(), { unit });

/**
 * Timestamp in seconds to distance till now in days
 */
export const getTimestampDistanceInDays = (timestamp: number | string) => getTimestampDistance(timestamp, 'day');
