import { GetAllCuratedWalletListResponse } from '@/services/index';
import { ControlledSelect } from '../../atoms';
import { language } from './values';
import styles from '../styles.module.scss';
import typography from '@/styles/scss/typography.module.scss';

interface IBundleFieldProps {
  bundles?: GetAllCuratedWalletListResponse[];
}
const BundleField = ({ bundles }: IBundleFieldProps) => {
  return (
    <div>
      <div className={styles.col25}>
        <h5 className={typography.bodySmallRegular}>{language.en.title}</h5>
      </div>
      <div className={styles.col75}>
        <ControlledSelect
          required
          name="selectBundle"
          options={
            bundles?.map((bundle) => ({
              label: bundle.name,
              id: bundle.id,
            })) || []
          }
          placeholder={language.en.placeholder}
        />
      </div>
    </div>
  );
};

export default BundleField;
