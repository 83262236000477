import { isAddress } from 'ethers/lib/utils';
import { UserUpdatePortfolioWalletsRequestDto } from '@/services/index';
import { api } from '@/utils/api';
import { EAdditionMethod, EPortfolioList } from './types';

export const addWalletToPortfolio = async (address: string, additionMethod: EAdditionMethod) =>
  addWalletTo(address, { currentListName: EPortfolioList.MY, additionMethod });

export const addWalletToWatchList = async (address: string) =>
  addWalletTo(address, { currentListName: EPortfolioList.WATCHED, additionMethod: EAdditionMethod.WATCHED });

const addWalletTo = async (
  address: string,
  { additionMethod, currentListName }: { currentListName: EPortfolioList; additionMethod: EAdditionMethod },
) => {
  if (!isAddress(address)) {
    return;
  }
  await api.user.userControllerAddOrRemoveUserPortfolioWallets({
    action: UserUpdatePortfolioWalletsRequestDto.action.ADD,
    wallets: [
      {
        address,
        settings: {
          currentListName,
          additionMethod,
          includeInAggregateView: true,
        },
      },
    ],
  });
};
