/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type QuerySortBy = {
  metric: string;
  type: QuerySortBy.type;
};

export namespace QuerySortBy {
  export enum type {
    ASC = 'ASC',
    DESC = 'DESC',
  }
}
