export const decodeJwtToken = (token: string) => {
  const base64UrlToBase64 = (base64Url: string) => {
    return base64Url.replace(/-/g, '+').replace(/_/g, '/');
  };
  const parts = token.split('.');
  const headerInBase64UrlFormat = parts[0];
  const payloadInBase64UrlFormat = parts[1];
  const headerInBase64 = base64UrlToBase64(headerInBase64UrlFormat);
  const payloadInBase64 = base64UrlToBase64(payloadInBase64UrlFormat);
  const decodedHeader = JSON.parse(window.atob(headerInBase64));
  const decodedPayload = JSON.parse(window.atob(payloadInBase64));
  return { decodedHeader, decodedPayload };
};
