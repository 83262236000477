import Image from 'next/image';
import searchMage from '@/assets/img/SearchingMage.png';
import styles from './NotFound.module.scss';

interface INotFoundProps {
  value?: string;
}

export const NotFound = ({ value }: INotFoundProps) => {
  return (
    <div className={styles.wrapper}>
      <Image alt="not-found" height={140} src={searchMage.src} width={150} />
      <span className={styles.text}>
        Sorry, we couldn&apos;t find any matches {value && <span className={styles.value}>“{value}”</span>}
      </span>
    </div>
  );
};
