export const POP_MESSAGE = {
  success: {
    defaultTitle: 'Success',
    defaultMessage: 'Operation completed successfully',
  },
  error: {
    defaultTitle: 'An error occurred',
    defaultMessage: 'An error occurred while processing your request',
  },
};
