import { API } from '@/api';
import { logApp } from '../logApp';

const log = logApp.create('fetchJSON');
const getError = (response: Response, method = 'fetch') =>
  new Error(`Bad response. ${response.status} ${response.statusText}. Failed to ${method} "${response.url}"`);
const throwError = (resp: Response, method: string) => {
  throw getError(resp, method);
};

const dummyType = <T>(resp: unknown): resp is T => true;

export const fetchJSON = async <T>({
  url,
  body,
  authToken,
  headers,
  initParams,
  method = body ? 'POST' : 'GET',
  onBadResponse = throwError,
  response,
  logGroup = null,
}: {
  url: string;
  response: (arg: unknown) => arg is T;
  method?: API.Utils.HTTPMethods;
  onBadResponse?: (response: Response, method: string, json: unknown) => T | Promise<T>;
  authToken?: string;
  headers?: Record<string, string>;
  body?: unknown;
  initParams?: Omit<RequestInit, 'body' | 'method' | 'headers'>;
  logGroup?: string | null;
}): Promise<T> => {
  logGroup && log.debug([logGroup, 'START'], { method, url, body });

  const resp = await fetch(url, {
    ...initParams,
    method,
    headers: {
      ...headers,
      'content-type': 'application/json',
      'Authorization': !authToken ? '' : `Bearer ${authToken}`,
    },
    body: body && method !== 'GET' ? JSON.stringify(body) : undefined,
    window: null,
  });

  if (!resp.ok) {
    logGroup &&
      void Promise.resolve()
        .then(() => (resp.bodyUsed ? 'N/A' : resp.text()))
        .then((responseText) => {
          log.warn([logGroup, 'NOT_OK'], {
            method,
            url,
            status: resp.status,
            statusText: resp.statusText,
            responseText,
            body,
          });
        });

    return await onBadResponse(resp, method, undefined);
  }

  logGroup && log.debug([logGroup, 'resp.ok'], {});
  const json = (await resp.json()) as unknown;

  if (!response(json)) {
    logGroup &&
      log.error([logGroup, 'BAD_TYPE'], {
        method,
        url,
        status: resp.status,
        statusText: resp.statusText,
        resp,
        body,
      });
    return await onBadResponse(resp, method, json);
  }
  logGroup && log.debug([logGroup, 'OK'], { json });
  return json;
};

fetchJSON.error = getError;
fetchJSON.as = dummyType;
