/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApproveAllowanceResponseDto } from '../models/ApproveAllowanceResponseDto';
import type { ApproveSpenderResponseDto } from '../models/ApproveSpenderResponseDto';
import type { ApproveTransactionResponseDto } from '../models/ApproveTransactionResponseDto';
import type { GetPercentNativeSwapAmountRequestDto } from '../models/GetPercentNativeSwapAmountRequestDto';
import type { GetQuoteResponse52Dto } from '../models/GetQuoteResponse52Dto';
import type { GetQuoteResponseDto } from '../models/GetQuoteResponseDto';
import type { GetSwapResponse52Dto } from '../models/GetSwapResponse52Dto';
import type { GetSwapResponseDto } from '../models/GetSwapResponseDto';
import type { LiquiditySourcesResponseDto } from '../models/LiquiditySourcesResponseDto';
import type { PresetsInfoResponseDto } from '../models/PresetsInfoResponseDto';
import type { QuoteRequestDto } from '../models/QuoteRequestDto';
import type { SwapRequestDto } from '../models/SwapRequestDto';
import type { TokensInfoResponseDto } from '../models/TokensInfoResponseDto';
import type { TrackSwapRequestDto } from '../models/TrackSwapRequestDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class InchService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Health check for one inch api by chainId
   * @param chainId
   * @returns any
   * @throws ApiError
   */
  public oneInchControllerHealthCheck(chainId: string): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: 'userService/1inch/healthCheck',
      query: {
        chainId: chainId,
      },
    });
  }

  /**
   * @deprecated
   * Address of the 1inch router that must be trusted to spend funds for the exchange
   * @param chainId
   * @returns ApproveSpenderResponseDto
   * @throws ApiError
   */
  public oneInchControllerGetApproveSpender(chainId: string): CancelablePromise<ApproveSpenderResponseDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: 'userService/1inch/approve/spender',
      query: {
        chainId: chainId,
      },
    });
  }

  /**
   * @deprecated
   * Generate data for calling the contract in order to allow the 1inch router to spend funds
   * @param chainId
   * @param tokenAddress
   * @param amount
   * @returns ApproveTransactionResponseDto
   * @throws ApiError
   */
  public oneInchControllerGetApproveTransaction(
    chainId: string,
    tokenAddress: string,
    amount: string,
  ): CancelablePromise<ApproveTransactionResponseDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: 'userService/1inch/approve/transaction',
      query: {
        chainId: chainId,
        tokenAddress: tokenAddress,
        amount: amount,
      },
    });
  }

  /**
   * @deprecated
   * Get number of tokens that the 1inch router is allowed to spend
   * @param chainId
   * @param tokenAddress
   * @param walletAddress
   * @returns ApproveAllowanceResponseDto
   * @throws ApiError
   */
  public oneInchControllerGetApproveAllowance(
    chainId: string,
    tokenAddress: string,
    walletAddress: string,
  ): CancelablePromise<ApproveAllowanceResponseDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: 'userService/1inch/approve/allowance',
      query: {
        chainId: chainId,
        tokenAddress: tokenAddress,
        walletAddress: walletAddress,
      },
    });
  }

  /**
   * @deprecated
   * Find the best quote to exchange via 1inch router
   * @param requestBody
   * @returns GetQuoteResponseDto
   * @throws ApiError
   */
  public oneInchControllerGetQuote(requestBody: QuoteRequestDto): CancelablePromise<GetQuoteResponseDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: 'userService/1inch/quote',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @deprecated
   * Generate data for calling the 1inch router for exchange
   * @param requestBody
   * @returns GetSwapResponseDto
   * @throws ApiError
   */
  public oneInchControllerGetSwap(requestBody: SwapRequestDto): CancelablePromise<GetSwapResponseDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: 'userService/1inch/swap',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Track swap
   * @param requestBody
   * @returns GetSwapResponseDto
   * @throws ApiError
   */
  public oneInchControllerTrackSwap(requestBody: TrackSwapRequestDto): CancelablePromise<GetSwapResponseDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: 'userService/1inch/trackSwap',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @deprecated
   * List of tokens that are available for swap in the 1 inch aggregation protocol
   * @param chainId
   * @returns TokensInfoResponseDto
   * @throws ApiError
   */
  public oneInchControllerGetTokensInfo(chainId: string): CancelablePromise<TokensInfoResponseDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: 'userService/1inch/tokens',
      query: {
        chainId: chainId,
      },
    });
  }

  /**
   * List of presets configuration for the 1 inch router
   * @param chainId
   * @returns PresetsInfoResponseDto
   * @throws ApiError
   */
  public oneInchControllerGetPresetsInfo(chainId: string): CancelablePromise<PresetsInfoResponseDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: 'userService/1inch/presets',
      query: {
        chainId: chainId,
      },
    });
  }

  /**
   * @deprecated
   * List of liquidity-sources that are available for swap in the 1 inch aggregation protocol
   * @param chainId
   * @returns LiquiditySourcesResponseDto
   * @throws ApiError
   */
  public oneInchControllerGetLiquiditySourcesInfo(chainId: string): CancelablePromise<LiquiditySourcesResponseDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: 'userService/1inch/liquidity-sources',
      query: {
        chainId: chainId,
      },
    });
  }

  /**
   * Get the price of an ERC20 token in USD
   * @param tokenAddress
   * @param chainId
   * @param decimals
   * @returns number
   * @throws ApiError
   */
  public oneInchControllerGetErc20TokenPrice(
    tokenAddress: string,
    chainId: string,
    decimals: number,
  ): CancelablePromise<number> {
    return this.httpRequest.request({
      method: 'GET',
      url: 'userService/1inch/erc20tokenPrice',
      query: {
        tokenAddress: tokenAddress,
        chainId: chainId,
        decimals: decimals,
      },
    });
  }

  /**
   * Calculate the amount of tokens to sell based on a given percentage
   * @param requestBody
   * @returns GetSwapResponseDto
   * @returns any
   * @throws ApiError
   */
  public oneInchControllerGetPercentSwapAmount(
    requestBody: GetPercentNativeSwapAmountRequestDto,
  ): CancelablePromise<GetSwapResponseDto | any> {
    return this.httpRequest.request({
      method: 'POST',
      url: 'userService/1inch/percentNativeSwapAmount',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Address of the 1inch router that must be trusted to spend funds for the exchange
   * @param chainId
   * @param oneinch
   * @returns ApproveSpenderResponseDto
   * @throws ApiError
   */
  public oneInchControllerGetApproveSpender52(
    chainId: string,
    oneinch: boolean,
  ): CancelablePromise<ApproveSpenderResponseDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: 'userService/1inch/5.2/{chainId}/approve/spender',
      path: {
        chainId: chainId,
      },
      query: {
        oneinch: oneinch,
      },
    });
  }

  /**
   * Generate data for calling the contract in order to allow the 1inch router to spend funds
   * @param chainId
   * @param tokenAddress
   * @param amount
   * @param oneinch
   * @returns ApproveTransactionResponseDto
   * @throws ApiError
   */
  public oneInchControllerGetApproveTransaction52(
    chainId: string,
    tokenAddress: string,
    amount?: string,
    oneinch?: boolean,
  ): CancelablePromise<ApproveTransactionResponseDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: 'userService/1inch/5.2/{chainId}/approve/transaction',
      path: {
        chainId: chainId,
      },
      query: {
        tokenAddress: tokenAddress,
        amount: amount,
        oneinch: oneinch,
      },
    });
  }

  /**
   * Get number of tokens that the 1inch router is allowed to spend
   * @param chainId
   * @param tokenAddress
   * @param walletAddress
   * @param oneinch
   * @returns ApproveAllowanceResponseDto
   * @throws ApiError
   */
  public oneInchControllerGetApproveAllowance52(
    chainId: string,
    tokenAddress: string,
    walletAddress: string,
    oneinch?: boolean,
  ): CancelablePromise<ApproveAllowanceResponseDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: 'userService/1inch/5.2/{chainId}/approve/allowance',
      path: {
        chainId: chainId,
      },
      query: {
        tokenAddress: tokenAddress,
        walletAddress: walletAddress,
        oneinch: oneinch,
      },
    });
  }

  /**
   * Find the best quote to exchange via 1inch router
   * @param chainId
   * @param src
   * @param dst
   * @param amount
   * @param protocols
   * @param fee
   * @param gasPrice
   * @param complexityLevel
   * @param parts
   * @param mainRouteParts
   * @param gasLimit
   * @param includeTokensInfo
   * @param includeProtocols
   * @param includeGas
   * @param connectorTokens
   * @param oneinch
   * @returns GetQuoteResponse52Dto
   * @throws ApiError
   */
  public oneInchControllerGetQuote52(
    chainId: string,
    src: string,
    dst: string,
    amount: string,
    protocols?: string,
    fee?: number,
    gasPrice?: string,
    complexityLevel?: number,
    parts?: number,
    mainRouteParts?: number,
    gasLimit?: number,
    includeTokensInfo?: boolean,
    includeProtocols?: boolean,
    includeGas?: boolean,
    connectorTokens?: string,
    oneinch?: boolean,
  ): CancelablePromise<GetQuoteResponse52Dto> {
    return this.httpRequest.request({
      method: 'GET',
      url: 'userService/1inch/5.2/{chainId}/quote',
      path: {
        chainId: chainId,
      },
      query: {
        src: src,
        dst: dst,
        amount: amount,
        protocols: protocols,
        fee: fee,
        gasPrice: gasPrice,
        complexityLevel: complexityLevel,
        parts: parts,
        mainRouteParts: mainRouteParts,
        gasLimit: gasLimit,
        includeTokensInfo: includeTokensInfo,
        includeProtocols: includeProtocols,
        includeGas: includeGas,
        connectorTokens: connectorTokens,
        oneinch: oneinch,
      },
    });
  }

  /**
   * Generate data for calling the 1inch router for exchange
   * @param chainId
   * @param src
   * @param dst
   * @param amount
   * @param from
   * @param slippage
   * @param protocols
   * @param gasPrice
   * @param complexityLevel
   * @param parts
   * @param mainRouteParts
   * @param gasLimit
   * @param includeTokensInfo
   * @param includeProtocols
   * @param includeGas
   * @param connectorTokens
   * @param permit
   * @param receiver
   * @param allowPartialFill
   * @param disableEstimate
   * @param oneinch
   * @returns GetSwapResponse52Dto
   * @throws ApiError
   */
  public oneInchControllerGetSwap52(
    chainId: string,
    src: string,
    dst: string,
    amount: string,
    from: string,
    slippage: number,
    protocols?: string,
    gasPrice?: string,
    complexityLevel?: number,
    parts?: number,
    mainRouteParts?: number,
    gasLimit?: number,
    includeTokensInfo?: boolean,
    includeProtocols?: boolean,
    includeGas?: boolean,
    connectorTokens?: string,
    permit?: string,
    receiver?: string,
    allowPartialFill?: boolean,
    disableEstimate?: boolean,
    oneinch?: boolean,
  ): CancelablePromise<GetSwapResponse52Dto> {
    return this.httpRequest.request({
      method: 'GET',
      url: 'userService/1inch/5.2/{chainId}/swap',
      path: {
        chainId: chainId,
      },
      query: {
        src: src,
        dst: dst,
        amount: amount,
        from: from,
        slippage: slippage,
        protocols: protocols,
        gasPrice: gasPrice,
        complexityLevel: complexityLevel,
        parts: parts,
        mainRouteParts: mainRouteParts,
        gasLimit: gasLimit,
        includeTokensInfo: includeTokensInfo,
        includeProtocols: includeProtocols,
        includeGas: includeGas,
        connectorTokens: connectorTokens,
        permit: permit,
        receiver: receiver,
        allowPartialFill: allowPartialFill,
        disableEstimate: disableEstimate,
        oneinch: oneinch,
      },
    });
  }
}
