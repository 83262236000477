/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { FetchHttpRequest } from './core/FetchHttpRequest';

import { AlertService } from './services/AlertService';
import { CategoryService } from './services/CategoryService';
import { CheckoutService } from './services/CheckoutService';
import { CoinFilterService } from './services/CoinFilterService';
import { CoinsService } from './services/CoinsService';
import { CoinSearchService } from './services/CoinSearchService';
import { DefaultService } from './services/DefaultService';
import { InchService } from './services/InchService';
import { InsightsService } from './services/InsightsService';
import { InvoicesService } from './services/InvoicesService';
import { JiffyscanService } from './services/JiffyscanService';
import { OrganizationsService } from './services/OrganizationsService';
import { PaymasterService } from './services/PaymasterService';
import { PaymentMethodsService } from './services/PaymentMethodsService';
import { PlansService } from './services/PlansService';
import { PortalService } from './services/PortalService';
import { SetupIntentsService } from './services/SetupIntentsService';
import { SubscriptionsService } from './services/SubscriptionsService';
import { TimelineService } from './services/TimelineService';
import { TrackService } from './services/TrackService';
import { UsagesService } from './services/UsagesService';
import { UserService } from './services/UserService';
import { Web3Service } from './services/Web3Service';

type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;

export class ApiServiceClass {
  public readonly alert: AlertService;
  public readonly category: CategoryService;
  public readonly checkout: CheckoutService;
  public readonly coinFilter: CoinFilterService;
  public readonly coins: CoinsService;
  public readonly coinSearch: CoinSearchService;
  public readonly default: DefaultService;
  public readonly inch: InchService;
  public readonly insights: InsightsService;
  public readonly invoices: InvoicesService;
  public readonly jiffyscan: JiffyscanService;
  public readonly organizations: OrganizationsService;
  public readonly paymaster: PaymasterService;
  public readonly paymentMethods: PaymentMethodsService;
  public readonly plans: PlansService;
  public readonly portal: PortalService;
  public readonly setupIntents: SetupIntentsService;
  public readonly subscriptions: SubscriptionsService;
  public readonly timeline: TimelineService;
  public readonly track: TrackService;
  public readonly usages: UsagesService;
  public readonly user: UserService;
  public readonly web3: Web3Service;

  public readonly request: BaseHttpRequest;

  constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = FetchHttpRequest) {
    this.request = new HttpRequest({
      BASE: config?.BASE ?? '',
      VERSION: config?.VERSION ?? '1.0.0',
      WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
      CREDENTIALS: config?.CREDENTIALS ?? 'include',
      TOKEN: config?.TOKEN,
      USERNAME: config?.USERNAME,
      PASSWORD: config?.PASSWORD,
      HEADERS: config?.HEADERS,
      ENCODE_PATH: config?.ENCODE_PATH,
    });

    this.alert = new AlertService(this.request);
    this.category = new CategoryService(this.request);
    this.checkout = new CheckoutService(this.request);
    this.coinFilter = new CoinFilterService(this.request);
    this.coins = new CoinsService(this.request);
    this.coinSearch = new CoinSearchService(this.request);
    this.default = new DefaultService(this.request);
    this.inch = new InchService(this.request);
    this.insights = new InsightsService(this.request);
    this.invoices = new InvoicesService(this.request);
    this.jiffyscan = new JiffyscanService(this.request);
    this.organizations = new OrganizationsService(this.request);
    this.paymaster = new PaymasterService(this.request);
    this.paymentMethods = new PaymentMethodsService(this.request);
    this.plans = new PlansService(this.request);
    this.portal = new PortalService(this.request);
    this.setupIntents = new SetupIntentsService(this.request);
    this.subscriptions = new SubscriptionsService(this.request);
    this.timeline = new TimelineService(this.request);
    this.track = new TrackService(this.request);
    this.usages = new UsagesService(this.request);
    this.user = new UserService(this.request);
    this.web3 = new Web3Service(this.request);
  }
}
