import merge from 'lodash.merge';
import color, { modalGradient } from '@/styles/color';
import styleHelpers from '@/styles/styleHelpers';
import { midnightTheme, Theme } from '@rainbow-me/rainbowkit';

export const moneyTheme = merge(midnightTheme(), {
  colors: {
    accentColor: color.moneyMint50,
    accentColorForeground: color.moneyWhite,
    actionButtonSecondaryBackground: color.moneyWhite,
    closeButton: color.moneyAero90,
    closeButtonBackground: color.moneyGrey50,
    menuItemBackground: color.moneyNavy40,
    modalBackdrop: styleHelpers.modalOverlay,
    modalBackground: modalGradient,
    modalText: color.moneyWhite,
    modalTextSecondary: color.moneyWhite,
    selectedOptionBorder: color.moneyMint50,
  },
  radii: {
    actionButton: styleHelpers.borderRadiusSmall,
    connectButton: styleHelpers.borderRadius,
    menuButton: styleHelpers.borderRadius,
    modal: styleHelpers.borderRadius,
    modalMobile: styleHelpers.borderRadius,
  },
  shadows: {
    connectButton: 'none',
    dialog: 'none',
    profileDetailsAction: 'none',
    selectedOption: 'none',
    selectedWallet: 'none',
    walletLogo: 'none',
  },
} as Theme);
