/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { StripePortalSessionDTO } from '../models/StripePortalSessionDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class PortalService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @returns any
   * @returns StripePortalSessionDTO
   * @throws ApiError
   */
  public portalControllerCreate(): CancelablePromise<any | StripePortalSessionDTO> {
    return this.httpRequest.request({
      method: 'GET',
      url: 'billingCoordinator/portal',
    });
  }
}
