'use client';
import { FC } from 'react';
import { ButtonBase, IButtonProps } from '../ButtonBase';
import styles from './styles.module.scss';

const ButtonPrimary: FC<IButtonProps> = ({ className = '', ...props }: IButtonProps) => {
  return <ButtonBase className={`${styles.primary} ${className}`} {...props} />;
};

export default ButtonPrimary;
