import Script from 'next/script';
import { isDevDomain, isDevEnv, isDevModeEnabled } from '@/utils/devMode';

export const CookieScript = () => {
  const isIframe = typeof window !== 'undefined' && window.self !== window.top;
  const shouldBypass = isIframe || isDevEnv() || isDevDomain() || isDevModeEnabled();

  if (shouldBypass) {
    return null;
  }

  return <Script src="//cdn.cookie-script.com/s/816d8f567d2e5375195076e98af215e3.js" type="text/javascript" async />;
};
