export const NATIVE_TOKEN_ADDRESS = '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE';

export const isNative = (tokenAddress?: string, tokenSymbol?: string): boolean => {
  if (tokenAddress && tokenAddress.toLowerCase() === NATIVE_TOKEN_ADDRESS.toLowerCase()) {
    return true;
  }
  if (tokenAddress || !tokenSymbol) {
    return false;
  }
  return Object.values(CHAIN_ID_TO_NAME).includes(tokenSymbol);
};

export const CHAIN_ID_TO_NAME = {
  1: 'ETH',
  137: 'MATIC',
  56: 'BNB',
};

export const tokenDataQueryKey = (tokenAddress: string | null, chainId: number | null | undefined) => [
  'token-data',
  tokenAddress,
  chainId,
];

export const TOKEN_DECIMALS_FALLBACK = 18;
