/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CheckoutCreateDto } from '../models/CheckoutCreateDto';
import type { StripeCheckoutSessionDto } from '../models/StripeCheckoutSessionDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class CheckoutService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @param requestBody
   * @returns StripeCheckoutSessionDto
   * @throws ApiError
   */
  public checkoutControllerCreate(requestBody: CheckoutCreateDto): CancelablePromise<StripeCheckoutSessionDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: 'billingCoordinator/checkout',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param requestBody
   * @returns StripeCheckoutSessionDto
   * @throws ApiError
   */
  public checkoutControllerCreateAnonymous(
    requestBody: CheckoutCreateDto,
  ): CancelablePromise<StripeCheckoutSessionDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: 'billingCoordinator/checkout/anonymous',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
}
