export const RX_EMAIL = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
export const RX_WALLET_ADDRESS = /^0x[a-fA-F0-9]{40}$/;
export const RX_URL = new RegExp(
  '^(https?:\\/\\/)?' + // Protocol (optional)
    '((([a-zA-Z0-9$._%+-]+)\\.)+[a-zA-Z]{2,})' + // Domain name
    '(\\:[0-9]{1,5})?' + // Port (optional)
    '(\\/[-a-zA-Z0-9@:%._+~#=]*)*' + // Path (optional)
    '(\\?[;&a-zA-Z0-9%_.,~+=-]*)?' + // Query string (optional)
    '(\\#[-a-zA-Z0-9_]*)?$', // Fragment (optional)
  'i', // Case insensitive
);
