import { sha } from '@/utils/sha';
import { TWalletProfitabilityDays } from './types';

export const cacheKey = {
  addressData: (slug: string) => `addressData-${slug}`,
  chains: (addresses: Array<string>) => `chainsWithData-${sha(addresses.join())}`,
  walletHistory: (addresses: Array<string>, chainId: string | null) =>
    ['walletHistory', chainId, sha(addresses.join())].join('-'),
  nftCollectionsData: (addresses: string[], chainId: string) =>
    `nftCollectionsData-${sha(addresses.join())}-${chainId}`,
  nftCollection: (address: string, chainId: string, collection: string) =>
    `nftCollection-${address}-${chainId}-${collection}`,
  tokenBalancesData: (addresses: string[], chainId: string) => `tokenBalancesData-${sha(addresses.join())}-${chainId}`,
  topSixTokensData: (addresses: string[]) => `topSixTokensData-${sha(addresses.join())}`,
  topTokensDataByValue: (addresses: string[]) => `topTokensDataByValue-${sha(addresses.join())}`,
  categoriesTopTokensDataByValue: (addresses: string[]) => `categoriesTopTokensDataByValue-${sha(addresses.join())}`,
  suggestionPortfolioTokens: (addresses: string[]) => `suggestionPortfolioTokens-${sha(addresses.join())}`,
  topSixChainsData: (addresses: string[]) => `topSixChainsData-${sha(addresses.join())}`,
  tokenSecurity: (address: string) => `tokenSecurity-${address}`,
  walletNetWorthData: (addresses: string[]) => `walletNetWorthData-${sha(addresses.join())}`,
  multiWalletWalletsAndNetWorth: (moneyId: string) => `multiWalletWalletsAndNetWorth-${moneyId}`,
  walletProfitabilityData: (address: string) => `walletProfitabilityData-${sha(address)}`,
  walletProfitabilityDataMulti: (walletAddresses: string[]) =>
    `walletProfitabilityDataMulti-${sha(walletAddresses.sort().join('-'))}`,
  walletProfitabilityDataMultiChain: (walletAddresses: string[], chainIds: string[], days: TWalletProfitabilityDays) =>
    `walletProfitabilityDataMultiChain-${sha(walletAddresses.sort().join('-'))}-${sha(
      chainIds.sort().join('-'),
    )}-${days}`,
  walletDefiPositionsData: (address: string, chainId: string) => `walletDefiPositionsData-${sha(address)}-${chainId}`,
};
