import { isDate, isNumber, isString } from './isType';
import { isChainId } from './supportedChains';

export type AsType<T = unknown, V = unknown> = (data: V, k: string) => T;
export type TestFnResp = string | undefined | void | null;
export type TestFn<T> = AsType<TestFnResp, T>;

const invalid = (varName: string, msg: TestFnResp) => new Error(['Invalid "', varName, '" - ', msg].join(''));

const assertByTestFn = <T>(data: T, varName: string, fn: TestFn<T> | undefined) => {
  const msg = fn?.(data, varName);
  if (msg) {
    throw invalid(varName, msg);
  }
};

export const asString =
  (fn?: TestFn<string>): AsType<string> =>
  (data, varName) => {
    if (!isString(data)) {
      throw invalid(varName, `must be a string, got ${typeof data}`);
    }
    assertByTestFn(data, varName, fn);
    return data;
  };

export const asStringEnum = <V extends string>(vals: Set<V> | V[] | Record<V, unknown>): AsType<V> => {
  const set = Array.isArray(vals) ? new Set(vals) : vals instanceof Set ? vals : new Set(Object.keys(vals));
  const valid = Array.from(set).join(', ');
  return asString((s) => (set.has(s) ? undefined : `must be one of ${valid}, got ${s}`)) as AsType<V>;
};

export const asNumber =
  (fn?: TestFn<number>): AsType<number> =>
  (data, varName) => {
    const n = Number(data);
    if (!isNumber(n)) {
      throw invalid(varName, 'must be a number');
    }
    assertByTestFn(n, varName, fn);
    return n;
  };

export const asNonNegativeNumber = asNumber((n) => (n < 0 ? 'must NOT be a negative number' : undefined));

export const asDate =
  (fn?: TestFn<Date>): AsType<Date> =>
  (data, varName): Date => {
    const n = Number(data);
    const dn = isNumber(n) ? n : isString(data) ? Date.parse(data) : NaN;
    const d = new Date(dn);
    if (!isDate(d)) {
      throw invalid(varName, 'must be a valid date');
    }

    assertByTestFn(d, varName, fn);
    return d;
  };

export const asChainIdNumber: AsType<number> = (data, varName) => {
  let chainIdNumber: number;

  if (isString(data)) {
    if (isChainId(data)) {
      chainIdNumber = Number(data);
    } else {
      throw invalid(varName, `must be a valid chainId address`);
    }
  } else if (isNumber(data)) {
    chainIdNumber = data;
  } else {
    throw invalid(varName, `must be a string or number, got ${typeof data}`);
  }

  if (isNaN(chainIdNumber)) {
    throw invalid(varName, 'must be a valid number');
  }

  return chainIdNumber;
};

// TODO: Solana address?
// export const asTokenAddressOfETH = asString((s) => {
//   if (s.length !== 42) {
//     return `must be 42 characters long, got ${s.length}`;
//   }
//   if (!s.startsWith('0x')) {
//     return `must start with 0x, got ${s}`;
//   }
// });
