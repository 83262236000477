/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type UserBeanTransactionsResponseDto = {
  amount: number;
  createdAt: number;
  type: UserBeanTransactionsResponseDto.type;
  title: number;
};

export namespace UserBeanTransactionsResponseDto {
  export enum type {
    SPENT = 'spent',
    RECEIVED = 'received',
  }
}
