export const language = {
  en: {
    button: (isEditing: boolean) => (isEditing ? 'Save Changes' : 'Save Token Alert'),
  },
};

export const tokenNotificationPeriods = Object.entries({
  '0': 'Only One Time',
  '5': '5 Seconds',
  '30': '30 Seconds',
  '60': '1 Minute',
  '300': '5 Minutes',
  '3600': '1 Hour',
  '14400': '4 Hours',
  '86400': '1 Day',
}).map(([id, label]) => ({
  id,
  label,
}));
