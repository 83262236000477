import { ISvgProps } from './types';

const Ethereum = ({ width = '120', height = '120', ...props }: ISvgProps) => {
  return (
    <svg
      fill="currentColor"
      height={height}
      viewBox="0 0 120 160"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_30_8567)">
        <g clipPath="url(#clip1_30_8567)">
          <path d="M59.4844 14.2109L59.5026 63.2303L100.185 81.7291L59.4844 14.2109Z" fillOpacity="0.65" />
          <path d="M59.4802 14.2256L18.7969 81.7291L59.4802 63.2371V14.2256Z" />
          <path
            d="M59.4778 113.48L58.9766 114.091V145.311L59.4778 146.774L100.185 89.4443L59.4778 113.48Z"
            fillOpacity="0.65"
          />
          <path d="M59.4802 146.774V113.48L18.7969 89.4443L59.4802 146.774Z" />
          <path d="M59.4922 105.772L100.174 81.7244L59.4922 63.2324V105.772Z" fillOpacity="0.45" />
          <path d="M18.7969 81.7244L59.4802 105.772V63.2324L18.7969 81.7244Z" fillOpacity="0.65" />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_30_8567">
          <rect height="160" width="120" />
        </clipPath>
        <clipPath id="clip1_30_8567">
          <rect height="132.549" transform="translate(-6.77539 14.2256)" width="132.549" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Ethereum;
