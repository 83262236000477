import { AppProps } from 'next/app';
import { Providers } from '@/components/Providers';
import '@rainbow-me/rainbowkit/styles.css';
import '@/wordpress/styles/post/styles.css';
import '@/styles/scss/global.scss';
import '@/styles/styles.css';

const Dapp = ({ Component, pageProps }: AppProps) => {
  return (
    <Providers>
      <Component {...pageProps} />
    </Providers>
  );
};

export default Dapp;
