import { useEffect, useState } from 'react';
import { TabsContext } from '../context';

const Tabs = ({
  defaultTab,
  children,
}: React.PropsWithChildren<{
  defaultTab: string;
}>) => {
  const [activeTab, setActiveTab] = useState(defaultTab);

  useEffect(() => {
    setActiveTab(defaultTab);
  }, [defaultTab]);

  return <TabsContext.Provider value={{ activeTab, setActiveTab }}>{children}</TabsContext.Provider>;
};

export default Tabs;
