/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { StripePaymentMethodDto } from '../models/StripePaymentMethodDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class PaymentMethodsService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @param paymentMethodId
   * @returns StripePaymentMethodDto
   * @throws ApiError
   */
  public paymentMethodControllerAttach(paymentMethodId: string): CancelablePromise<StripePaymentMethodDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: 'billingCoordinator/payment-methods/{paymentMethodId}/attach',
      path: {
        paymentMethodId: paymentMethodId,
      },
    });
  }

  /**
   * @param paymentMethodId
   * @returns StripePaymentMethodDto
   * @throws ApiError
   */
  public paymentMethodControllerDetach(paymentMethodId: string): CancelablePromise<StripePaymentMethodDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: 'billingCoordinator/payment-methods/{paymentMethodId}/detach',
      path: {
        paymentMethodId: paymentMethodId,
      },
    });
  }

  /**
   * @returns StripePaymentMethodDto
   * @throws ApiError
   */
  public paymentMethodControllerGetAllPaymentMethods(): CancelablePromise<Array<StripePaymentMethodDto>> {
    return this.httpRequest.request({
      method: 'GET',
      url: 'billingCoordinator/payment-methods',
    });
  }
}
