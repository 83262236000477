export const isRecord = (data: unknown): data is Record<string, unknown> => typeof data === 'object' && data !== null;

export const isKeyof = <O extends Record<string, unknown>>(k: string, obj: O): k is keyof O & string => k in obj;

export const isString = (data: unknown): data is string => typeof data === 'string';

export const isNumber = (data: unknown): data is number => typeof data === 'number' && !Number.isNaN(data);

export const isDate = (data: unknown): data is Date => data instanceof Date && !Number.isNaN(data.getTime());

/**
 * Checks if a value is one of the values of an object.
 */
export const isValueOf = <O extends Record<string, unknown>>(value: unknown, obj: O): value is O[keyof O] =>
  Object.values(obj).includes(value);
