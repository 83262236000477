import { getCookie } from 'cookies-next';
import { ICoinInfoSerialized } from '@/helpers/utils/coinInfoSerialize';
import { AnalyticsBrowser } from '@segment/analytics-next';
import { CoinFilterDto, getCoinsByFilteringOptions, ITokenExplorerQuery } from '../services';
import { EnvSegment } from './env/segment';
import { isDevEnv } from './devMode';
import { logApp } from './logApp';

const consentCookieName = 'CookieScriptConsent';
type TConsentCookieCategories = 'performance' | 'targeting' | 'unclassified';

// TODO: (Maxim) remove, we don't use VWO anymore
const vwoUuid = getCookie('_vwo_uuid_v2');

const waitFor = (isReady: () => boolean, { pollingMs }: { pollingMs: number }) => {
  if (isReady()) {
    return Promise.resolve();
  }
  return new Promise<void>((resolve) => {
    const interval = setInterval(() => {
      if (isReady()) {
        clearInterval(interval);
        resolve();
      }
    }, pollingMs);
  });
};

class AnalyticsTracker {
  private analytics = new AnalyticsBrowser();
  private serviceVersion = '1';
  private source = 'moralis-money-frontend';
  private shouldNotLoad = isDevEnv();
  private log = logApp.create('AnalyticsTracker');

  constructor() {
    // Hot reload can re-initialise the tracker and add to polling
    if (this.shouldNotLoad) {
      return;
    }
    // Start polling / loading only once
    this.shouldNotLoad = true;

    // load the analytics library only after the consent cookie is set for GDPR compliance
    void waitFor(() => this.getAcceptedCategoriesFromConsentCookie().targeting, { pollingMs: 1000 }).then(() =>
      this.loadAnalytics(),
    );
  }

  private loadAnalytics() {
    this.analytics.load(EnvSegment.cfg, {
      integrations: { 'Segment.io': EnvSegment.intergationCfg },
    });
  }

  private getAcceptedCategoriesFromConsentCookie() {
    const cookie = getCookie(consentCookieName);
    const categories: Record<TConsentCookieCategories, boolean> = {
      performance: false,
      targeting: false,
      unclassified: false,
    };
    try {
      const parsedCookie = cookie && typeof cookie == 'string' ? JSON.parse(cookie) : null;
      if (parsedCookie?.categories) {
        const acceptedCategories: TConsentCookieCategories[] =
          (typeof parsedCookie.categories === 'string'
            ? JSON.parse(parsedCookie.categories)
            : parsedCookie.categories) ?? [];
        for (const category of acceptedCategories) {
          categories[category] = true;
        }
      }
    } catch (error) {
      this.log.warnError('[getAcceptedCategoriesFromConsentCookie] Error parsing consent cookie', error, { cookie });
      // Do nothing
    }
    return categories;
  }

  async trackIdentify(userId?: string) {
    try {
      await Promise.all([
        this.analytics.identify(userId),
        this.analytics.identify({
          userId: userId,
          vwo_uuid: vwoUuid,
        }),
      ]);
    } catch (error) {
      this.log.warnError('[trackIdentify] Error identifying user', error, { userId });
    }
  }

  async trackButtonClick(name: string, plan?: string, billingPeriod?: string) {
    try {
      this.analytics.track('buttonClicked', {
        button_name: name,
        plan: plan,
        billing_period: billingPeriod,
        service_version: this.serviceVersion,
        source: this.source,
        vwo_uuid: vwoUuid,
      });
    } catch (error) {
      this.log.warnError('[trackButtonClick] Error tracking button click', error, { name, plan, billingPeriod });
    }
  }

  async trackLinkClick(text: string, href?: string, location?: string) {
    try {
      this.analytics.track('linkClicked', {
        link_label: text,
        destination: href,
        link_location: location,
        service_version: this.serviceVersion,
        source: this.source,
        vwo_uuid: vwoUuid,
      });
    } catch (error) {
      this.log.warnError('[trackLinkClick] Error tracking link click', error, { text, href, location });
    }
  }

  async trackLinkEbookClick(text: string, href?: string, location?: string) {
    try {
      this.analytics.track('buttonClicked', {
        link_label: text,
        destination: href,
        link_location: location,
        service_version: this.serviceVersion,
        source: this.source,
        vwo_uuid: vwoUuid,
      });
    } catch (error) {
      this.log.warnError('[trackLinkEbookClick] Error tracking link click', error, { text, href, location });
    }
  }

  async trackMonitorToken(
    interval: number,
    emailNotification: boolean,
    webhookNotification: boolean,
    telegramNotification: boolean,
    query?: ITokenExplorerQuery[],
    chainId?: string,
  ) {
    try {
      this.analytics.track('monitoringActivated', {
        notificationInterval: interval,
        query: query,
        chainId: chainId,
        webhookNotification: webhookNotification,
        emailNotification: emailNotification,
        telegramNotification: telegramNotification,
        service_version: this.serviceVersion,
        source: this.source,
        vwo_uuid: vwoUuid,
      });
    } catch (error) {
      this.log.warnError('[trackMonitorToken] Error tracking monitor token', error, {
        interval,
        emailNotification,
        webhookNotification,
        telegramNotification,
        query,
        chainId,
      });
    }
  }

  async trackTokenSaved(tokenData: ICoinInfoSerialized) {
    const { filters, metadata } = tokenData;
    try {
      this.analytics.track('tokenSaved', {
        BuyersChange_1W: filters?.timeframe?.buyersChange?.OneWeek,
        ExperiencedBuyersChange_1W: filters?.timeframe?.experiencedBuyersChange?.OneWeek,
        ExperiencedNetBuyersChange_1W: filters?.timeframe?.experiencedNetBuyersChange?.OneWeek,
        ExperiencedSellersChange_1W: filters?.timeframe?.experiencedSellersChange?.OneWeek,
        FullyDilutedValuation: filters?.noTimeframe?.fullyDilutedValuation,
        HoldersChange_1W: filters?.timeframe?.holdersChange?.OneWeek,
        LiquidityChangeUSD_1W: filters?.timeframe?.liquidityChangeUSD?.OneWeek,
        LiquidityChange_1W: filters?.timeframe?.liquidityChange?.OneWeek,
        MarketCap: filters?.noTimeframe?.marketCap,
        NetBuyersChange_1W: filters?.timeframe?.netBuyersChange?.OneWeek,
        SellersChange_1W: filters?.timeframe?.sellersChange?.OneWeek,
        USD24H: filters?.timeframe?.pricePercentChangeUSD?.OneDay,
        PercentageChangeUSD_1W: filters?.timeframe?.pricePercentChangeUSD?.OneWeek,
        TwitterFollowers: filters?.noTimeframe?.twitterFollower,
        chain: metadata?.chainId,
        coinName: metadata?.name,
        coinRank: filters?.noTimeframe?.rating,
        coinPrice: metadata?.priceUSD || metadata?.dexTools?.reprPair?.price,
        service_version: this.serviceVersion,
        source: this.source,
        vwo_uuid: vwoUuid,
      });
    } catch (error) {
      this.log.warnError('[trackTokenSaved] Error tracking token saved', error, { metadata });
    }
  }

  async trackTokenBuy(tokenData: ICoinInfoSerialized) {
    const { filters, metadata } = tokenData;
    try {
      this.analytics.track('tokenBuy', {
        BuyersChange_1W: filters?.timeframe?.buyersChange?.OneWeek,
        ExperiencedBuyersChange_1W: filters?.timeframe?.experiencedBuyersChange?.OneWeek,
        ExperiencedNetBuyersChange_1W: filters?.timeframe?.experiencedNetBuyersChange?.OneWeek,
        ExperiencedSellersChange_1W: filters?.timeframe?.experiencedSellersChange?.OneWeek,
        FullyDilutedValuation: filters?.noTimeframe?.fullyDilutedValuation,
        HoldersChange_1W: filters?.timeframe?.holdersChange?.OneWeek,
        LiquidityChangeUSD_1W: filters?.timeframe?.liquidityChangeUSD?.OneWeek,
        LiquidityChange_1W: filters?.timeframe?.liquidityChange?.OneWeek,
        MarketCap: filters?.noTimeframe?.marketCap,
        NetBuyersChange_1W: filters?.timeframe?.netBuyersChange?.OneWeek,
        SellersChange_1W: filters?.timeframe?.sellersChange?.OneWeek,
        USD24H: filters?.timeframe?.pricePercentChangeUSD?.OneDay,
        PercentageChangeUSD_1W: filters?.timeframe?.pricePercentChangeUSD?.OneWeek,
        TwitterFollowers: filters?.noTimeframe?.twitterFollower,
        chain: metadata?.chainId,
        coinName: metadata?.name,
        coinRank: filters?.noTimeframe?.rating,
        coinPrice: metadata?.priceUSD || metadata?.dexTools?.reprPair?.price,
        service_version: this.serviceVersion,
        source: this.source,
        vwo_uuid: vwoUuid,
      });
    } catch (error) {
      this.log.warnError('[trackTokenBuy] Error tracking token buy', error, { metadata });
    }
  }

  async trackSaveQuery(
    name: string,
    chainId: string,
    query: {
      filter: string;
      metric: string;
      value: number;
    }[],
  ) {
    try {
      this.analytics.track('querySaved', {
        queryName: name,
        chainId: chainId,
        query: query,
        service_version: this.serviceVersion,
        source: this.source,
        vwo_uuid: vwoUuid,
      });
    } catch (error) {
      this.log.warnError('[trackSaveQuery] Error tracking save query', error, { name, chainId, query });
    }
  }

  async trackTokenExplorerSearch(
    chainId: string,
    query: getCoinsByFilteringOptions | CoinFilterDto[],
    filterSource: string,
    totalResultsCount?: number,
  ) {
    try {
      this.analytics.track('tokenExplorerSearch', {
        filter_source: filterSource,
        totalResultsCount: totalResultsCount,
        chainId: chainId,
        query: query,
        service_version: this.serviceVersion,
        source: this.source,
        vwo_uuid: vwoUuid,
      });
    } catch (error) {
      this.log.warnError('[trackTokenExplorerSearch] Error tracking token explorer search', error, {
        chainId,
        query,
        filterSource,
        totalResultsCount,
      });
    }
  }

  async trackPageView(hash?: string) {
    try {
      this.analytics.page({
        slug: hash,
        service_version: this.serviceVersion,
        source: this.source,
        vwo_uuid: vwoUuid,
      });
    } catch (error) {
      this.log.warnError('[trackPageView] Error tracking page view', error, { hash });
    }
  }

  async trackCoinPageView(tokenData: ICoinInfoSerialized) {
    const { filters, metadata } = tokenData;
    try {
      this.analytics.page({
        BuyersChange_1W: filters?.timeframe?.buyersChange?.OneWeek,
        ExperiencedBuyersChange_1W: filters?.timeframe?.experiencedBuyersChange?.OneWeek,
        ExperiencedNetBuyersChange_1W: filters?.timeframe?.experiencedNetBuyersChange?.OneWeek,
        ExperiencedSellersChange_1W: filters?.timeframe?.experiencedSellersChange?.OneWeek,
        FullyDilutedValuation: filters?.noTimeframe?.fullyDilutedValuation,
        HoldersChange_1W: filters?.timeframe?.holdersChange?.OneWeek,
        LiquidityChangeUSD_1W: filters?.timeframe?.liquidityChangeUSD?.OneWeek,
        LiquidityChange_1W: filters?.timeframe?.liquidityChange?.OneWeek,
        MarketCap: filters?.noTimeframe?.marketCap,
        NetBuyersChange_1W: filters?.timeframe?.netBuyersChange?.OneWeek,
        SellersChange_1W: filters?.timeframe?.sellersChange?.OneWeek,
        USD24H: filters?.timeframe?.pricePercentChangeUSD?.OneDay,
        PercentageChangeUSD_1W: filters?.timeframe?.pricePercentChangeUSD?.OneWeek,
        TwitterFollowers: filters?.noTimeframe?.twitterFollower,
        chain: metadata?.chainId,
        coinName: metadata?.name,
        coinRank: filters?.noTimeframe?.rating,
        coinPrice: metadata?.priceUSD || metadata?.dexTools?.reprPair?.price,
        service_version: this.serviceVersion,
        source: this.source,
        vwo_uuid: vwoUuid,
      });
    } catch (error) {
      this.log.warnError('[trackCoinPageView] Error tracking coin page view', error, { metadata });
    }
  }
}

export default AnalyticsTracker;
