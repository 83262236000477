import { ChainId, NonEvmChain } from '@/services';
import { asStringEnum } from './asType';
import { isString } from './isType';

const CHAIN_ID_LIST_MAP: Record<ChainIdString, boolean> = {
  'solana': true,
  '0x1': true,
  '0x38': true,
  '0x2105': true,
  '0xa4b1': true,
  '0x89': true,
  '0xa86a': true,
  '0xa': true,
  '0x171': true,
  '0x19': true,
  '0xe708': true,
  '0xfa': true,
};

const CHAIN_ID_MAP_CANONICAL = {
  solana: 'solana',
  ethereum: '0x1',
  binance: '0x38',
  base: '0x2105',
  arbitrum: '0xa4b1',
  polygon: '0x89',
  avalanche: '0xa86a',
  optimism: '0xa',
  linea: '0xe708',
  fantom: '0xfa',
  pulse: '0x171',
  // @ts-expect-error - temporary fix
  // TODO: add cronos chain is added to the list
  cronos: '0x19',
} as const satisfies Record<string, `${ChainId | NonEvmChain}`>;

export const CHAIN_ID_MAP = {
  ...CHAIN_ID_MAP_CANONICAL,
  eth: CHAIN_ID_MAP_CANONICAL.ethereum,
  bsc: CHAIN_ID_MAP_CANONICAL.binance,
  avax: CHAIN_ID_MAP_CANONICAL.avalanche,
  ftm: CHAIN_ID_MAP_CANONICAL.fantom,
  poly: CHAIN_ID_MAP_CANONICAL.polygon,
  op: CHAIN_ID_MAP_CANONICAL.optimism,
  linea: CHAIN_ID_MAP_CANONICAL.linea,
  arb: CHAIN_ID_MAP_CANONICAL.arbitrum,
} as const satisfies Record<string, ChainIdString>;

export const CHAIN_ID_LIST = Object.values(CHAIN_ID_MAP_CANONICAL);

export const USE_ETH_TOKEN_CHAIN_IDS: ChainIdString[] = [
  CHAIN_ID_MAP_CANONICAL.optimism,
  CHAIN_ID_MAP_CANONICAL.base,
  CHAIN_ID_MAP_CANONICAL.arbitrum,
  CHAIN_ID_MAP_CANONICAL.linea,
];

export const SUPPORTED_TOKEN_ALERT_CHAIN_IDS: ChainIdString[] = [
  CHAIN_ID_MAP_CANONICAL.binance,
  CHAIN_ID_MAP_CANONICAL.ethereum,
  CHAIN_ID_MAP_CANONICAL.polygon,
  CHAIN_ID_MAP_CANONICAL.arbitrum,
];

export const EXCLUDED_FROM_TRENDING_CHAIN_IDS: ChainIdString[] = [CHAIN_ID_MAP_CANONICAL.cronos];

const UNSUPPORTED_CHAIN_IDS: ChainIdString[] = [
  CHAIN_ID_MAP_CANONICAL.pulse,
  CHAIN_ID_MAP_CANONICAL.linea,
  CHAIN_ID_MAP_CANONICAL.solana,
  // Chains not supported due to incidents or any other issues
  // @note - added fantom chain because of incident https://moralisweb3.slack.com/archives/C07BPBFKML5
  CHAIN_ID_MAP_CANONICAL.fantom,
];

// docs: https://docs.moralis.com/web3-data-api/evm/reference/get-top-profitable-wallet-per-token
export const TOP_GAINERS_UNSUPPORTED_CHAIN_IDS: ChainIdString[] = [
  CHAIN_ID_MAP_CANONICAL.solana,
  CHAIN_ID_MAP_CANONICAL.binance,
  CHAIN_ID_MAP_CANONICAL.fantom,
  CHAIN_ID_MAP_CANONICAL.arbitrum,
  CHAIN_ID_MAP_CANONICAL.optimism,
  CHAIN_ID_MAP_CANONICAL.linea,
  CHAIN_ID_MAP_CANONICAL.avalanche,
  CHAIN_ID_MAP_CANONICAL.pulse,
];

type CI = typeof CHAIN_ID_MAP_CANONICAL;
export type ChainIdString = CI[keyof CI];
export type ChainNameCanonical = keyof CI;
export type ChainName = keyof typeof CHAIN_ID_MAP;

export const SUPPORTED_CHAINS = Object.values(CHAIN_ID_MAP_CANONICAL).filter(
  (id) => !UNSUPPORTED_CHAIN_IDS.includes(id),
);
export const isChainSupported = (chain: string) => SUPPORTED_CHAINS.includes(chain as ChainIdString);
export const isChainId = (chain: string | undefined): chain is ChainIdString =>
  CHAIN_ID_LIST.includes(chain as ChainIdString);
export const isChainName = (chain: string | undefined): chain is ChainName =>
  chain !== undefined && chain in CHAIN_ID_MAP;
export const isChainNameCanonical = (chain: string | undefined): chain is ChainName =>
  chain !== undefined && chain in CHAIN_ID_MAP;

const INVERT_MAP = Object.fromEntries(Object.entries(CHAIN_ID_MAP_CANONICAL).map(([key, value]) => [value, key]));
export const getChainNameByChainId = <D extends string>(chainId: string | undefined, defaultName: D = 'unknown' as D) =>
  chainId && chainId in INVERT_MAP ? (INVERT_MAP[chainId] as ChainName) : defaultName;

export const getChainIdByChainName = ((chainName): ChainIdString | undefined => {
  if (!isString(chainName)) {
    return undefined;
  }
  if (isChainName(chainName)) {
    return CHAIN_ID_MAP[chainName];
  }
  return undefined;
}) as {
  (chainName: ChainName): ChainIdString;
  (chainName: unknown): ChainIdString | undefined;
};

/**
 * @deprecated
 */
export const chainIdToChainNameMappingDexScreener = (chainId: string) => {
  switch (chainId) {
    case CHAIN_ID_MAP_CANONICAL.binance:
      return 'bsc';
    case CHAIN_ID_MAP_CANONICAL.pulse:
      return 'pulsechain';
    default:
      return getChainNameByChainId(chainId);
  }
};

/**
 * @deprecated
 */
export const LEGACY_SUPPORTED_CHAINS = ['1', '56', '137'];

export const asChainName = asStringEnum(CHAIN_ID_MAP);
export const asChainId = asStringEnum(CHAIN_ID_LIST_MAP);
