import { mutate } from 'swr';
import create from 'zustand';
import { ICoinInfoResponse } from '@/types/tokenExplorer';
import { isNative, TOKEN_DECIMALS_FALLBACK, tokenDataQueryKey } from '@/utils/token';

type TCoinInfoStore = {
  clearCoinData: () => void;
  coinData?: ICoinInfoResponse;
  isFetching: boolean;
  setCoinData: (coinData: ICoinInfoResponse) => void;
  setIsFetching: (isFetching: boolean) => void;
};

const useCoinInfoStore = create<TCoinInfoStore>((set) => {
  return {
    coinData: undefined,
    isFetching: false,

    clearCoinData: () => {
      set(() => {
        return {
          coinData: undefined,
        };
      });
    },
    setCoinData: (coinData: ICoinInfoResponse) => {
      coinData.data.forEach((coin) => {
        if (coin.metadata.name && coin.metadata.symbol) {
          const tokenData = {
            address: coin.metadata.coinAddress,
            decimals: isNative(coin.metadata.coinAddress)
              ? TOKEN_DECIMALS_FALLBACK
              : Number(coin.metadata.decimals ?? TOKEN_DECIMALS_FALLBACK),
            name: coin.metadata.name,
            symbol: coin.metadata.symbol,
            isSpam: false,
            logo: coin.metadata.logo,
          };

          mutate(tokenDataQueryKey(coin.metadata.coinAddress, parseInt(coin.metadata.chainId)), tokenData, false);
        }
      });

      set({
        coinData: coinData,
      });
    },
    setIsFetching: (isFetching: boolean) => {
      set({ isFetching: isFetching });
    },
  };
});

export default useCoinInfoStore;
