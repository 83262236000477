type SubscriptDigitsKeys = '0' | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9';

class NumberConverter {
  private readonly subscriptDigits: Record<SubscriptDigitsKeys, string> = {
    '0': '₀',
    '1': '₁',
    '2': '₂',
    '3': '₃',
    '4': '₄',
    '5': '₅',
    '6': '₆',
    '7': '₇',
    '8': '₈',
    '9': '₉',
  };

  public getZerosAmountAfterPoint = (num: number): number => {
    const numStr = num.toString();
    let leadingZerosCount: number;

    if (numStr.includes('e')) {
      const expStr = numStr.split('e')[1];
      leadingZerosCount = Math.abs(parseInt(expStr)) - 1;
    } else {
      const decimalPart = numStr.split('.')[1];
      leadingZerosCount = decimalPart?.search(/[^0]/);
    }
    return leadingZerosCount;
  };

  private getSubscript = (count: number) => {
    return Array.from(String(count))
      .map((digit) => {
        return this.subscriptDigits[digit as SubscriptDigitsKeys];
      })
      .join('');
  };

  /**
   * Responsible for converting number with
   * big amount of zeros after decimal point to subscript
   */
  public convertToSubscript = (num: number, leadingZerosCount: number, maximumFractionDigits = 18): string => {
    const numStr = num.toString();

    let restOfNum: string;
    let decimalPart: string;
    let integerPart: string;

    if (numStr.includes('e')) {
      const coeffStr = numStr.split('e')[0];
      restOfNum = coeffStr.replace('.', '');
      integerPart = '0';
    } else {
      [integerPart, decimalPart] = numStr.split('.');
      restOfNum = decimalPart.slice(leadingZerosCount);
    }

    const leadingZerosSubscript = this.getSubscript(leadingZerosCount);
    const digitsAfterSubscript = Math.max(maximumFractionDigits - 1, 2);
    restOfNum = restOfNum.slice(0, digitsAfterSubscript);

    return `${integerPart}.0${leadingZerosSubscript}${restOfNum}`;
  };
}

export const numberConverter = new NumberConverter();
