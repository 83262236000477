/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type AddonResUsage = {
  addon: AddonResUsage.addon;
  total: number;
  limit: number;
  extra: number;
  overage: number;
};

export namespace AddonResUsage {
  export enum addon {
    SUBSCRIPTION = 'subscription',
    SUBSCRIPTION_ANNUAL = 'subscriptionAnnual',
    TEAM_MEMBERS = 'teamMembers',
    NUMBER_OF_PLUGINS = 'numberOfPlugins',
    NUMBER_OF_DAPPS = 'numberOfDapps',
    API_TOTAL_COMPUTE_UNITS = 'apiTotalComputeUnits',
    API_TOTAL_REQUESTS = 'apiTotalRequests',
    API_REQUESTS_PER_SECOND = 'apiRequestsPerSecond',
    STREAM_MAX_NUMBER_OF_ADDRESSES = 'streamMaxNumberOfAddresses',
    STREAM_LISTEN_ALL_ADDRESSES = 'streamListenAllAddresses',
    STREAM_NUMBER_OF_RECORDS = 'streamNumberOfRecords',
    STREAM_WEBHOOK_RETRIES = 'streamWebhookRetries',
    STREAM_FAILED_PAYLOAD_RETENTION = 'streamFailedPayloadRetention',
    STREAM_INCLUDE_ALL_TX_LOGS = 'streamIncludeAllTxLogs',
    NODE_NUMBER_OF_NODES = 'nodeNumberOfNodes',
    NODE_REQUESTS_PER_SECOND = 'nodeRequestsPerSecond',
    OPTIMUM_SERVER = 'optimumServer',
    MONEY_SUBSCRIPTION = 'moneySubscription',
    SUBSCRIPTION_LEVEL = 'subscriptionLevel',
    INSIGHTS_API_SUBSCRIPTION = 'insightsApiSubscription',
    IOT_SPONSORSHIP = 'iotSponsorship',
    MONEY_API = 'moneyApi',
    WHY_DID_IT_MOVE_API = 'whyDidItMoveApi',
    STREAM_FETCH_TOKEN_PRICES = 'streamFetchTokenPrices',
  }
}
