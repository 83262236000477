'use client';
import { useEffect } from 'react';
import NextProgress from 'nextjs-progressbar';
import { PopMessageProvider } from 'moralis-ui';
import { SWRConfig } from 'swr';
import { WagmiProvider } from 'wagmi';
import { FormResetProvider } from '@/helpers/hooks/formResetContext';
import useAnalyticsStore from '@/stores/analyticsStore';
import useCoinInfoStore from '@/stores/coinInfoStore';
import AnalyticsTracker from '@/utils/tracking';
import { RAINBOW_CONFIG } from '@/utils/wagmi';
import { getApolloClient } from '@/wordpress/utils/apolloClient';
import { ApolloProvider } from '@apollo/client/react';
import { RainbowKitProvider } from '@rainbow-me/rainbowkit';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { moneyTheme } from './authModal/raindbowKitTheme';
import { AlertModalProvider } from './common/CreateAlertModal/context';
import { AddWalletModalProvider } from './manageWallets/context/AddWalletContext';
import { CookieScript } from './vendorScripts/CookieScript';
import { GTag } from './vendorScripts/GTag';
import { Hotjar } from './vendorScripts/Hotjar';
import { Tapfiliate } from './vendorScripts/Tapfiliate';

const apolloClient = getApolloClient();

// Global swr cache settings
const swrCacheSettings = {
  refreshInterval: 300000,
  revalidateIfStale: false,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
  keepPreviousData: true,
};

export const Providers = ({ children }: React.PropsWithChildren) => {
  const setTracker = useAnalyticsStore(({ setTracker }) => setTracker);
  const isFetching = useCoinInfoStore(({ isFetching }) => isFetching);

  useEffect(() => {
    setTracker(new AnalyticsTracker());
  }, []);

  useEffect(() => {
    document.body.classList.toggle('fetching', isFetching);
  }, [isFetching]);

  const queryClient = new QueryClient();

  return (
    <>
      <WagmiProvider config={RAINBOW_CONFIG}>
        <QueryClientProvider client={queryClient}>
          <ApolloProvider client={apolloClient}>
            <SWRConfig value={swrCacheSettings}>
              <AlertModalProvider>
                <FormResetProvider>
                  <AddWalletModalProvider>
                    <RainbowKitProvider theme={moneyTheme}>
                      <PopMessageProvider>
                        <NextProgress />
                        {children}
                      </PopMessageProvider>
                    </RainbowKitProvider>
                  </AddWalletModalProvider>
                </FormResetProvider>
              </AlertModalProvider>
            </SWRConfig>
          </ApolloProvider>
          <Tapfiliate />
          <CookieScript />
          <Hotjar />
          <GTag />
        </QueryClientProvider>
      </WagmiProvider>
    </>
  );
};
