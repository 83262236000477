import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useAccount, useDisconnect } from 'wagmi';
import { portfolioURL } from '@/components/portfolio/lib/portfolioURL';
import { logApp } from '@/utils/logApp';
import { useConnectModal } from '@rainbow-me/rainbowkit';

const log = logApp.create('useConnectWallet');

export const useConnectWallet = () => {
  const { openConnectModal } = useConnectModal();
  const { disconnectAsync } = useDisconnect();
  const { address, isConnected } = useAccount();
  const router = useRouter();

  const [shouldClickButton, setShouldClickButton] = useState(false);

  useEffect(() => {
    if (router.pathname === portfolioURL.root && address) {
      router.push(portfolioURL.tokens(address));
    }
    if (address || !shouldClickButton) {
      return;
    }
    const btn = document.querySelector('#connect-button');
    if (btn instanceof HTMLButtonElement) {
      btn.click();
      setShouldClickButton(false);
      return;
    }

    log.warn('Could not find button with id "#connect-button"', { path: router.pathname, address });
  }, [address, shouldClickButton]);

  return {
    handleWalletConnection: async () => {
      await disconnectAsync();
      openConnectModal?.();
      setShouldClickButton(true);
    },
    isConnected,
  };
};
