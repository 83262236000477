import { fetchMultiPortfolioData } from '@/components/manageWallets/libs/fetchMultiPortfolioData';
import { CACHE_DATA_ONE_DAY_TTL, SWR_DATA_REFRESH_INTERVAL } from '@/components/portfolio/const/constants';
import { cacheKey } from '@/components/portfolio/lib/cacheKey';
import { useQueryWithStorage } from '@/helpers/hooks/useQueryWithStorage';
import { getMoneyIdFromToken } from '@/helpers/utils/getMoneyIdFromToken';
import useAuthStore from '@/stores/authStore';
import { api } from '@/utils/api';
import { useUserWallets } from './useUserWallets';

export const useMultiWalletData = () => {
  const authToken = useAuthStore((state) => state.authToken);
  const userWallets = useUserWallets();
  return useQueryWithStorage(() => fetchMultiPortfolioData(api.user, userWallets), {
    key: cacheKey.multiWalletWalletsAndNetWorth(getMoneyIdFromToken(authToken)),
    ttl: CACHE_DATA_ONE_DAY_TTL,
    keepPreviousData: true,
    skipIf: !authToken,
    refreshInterval: SWR_DATA_REFRESH_INTERVAL,
  });
};
