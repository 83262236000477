/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type EOADto = {
  /**
   * wallet address or email address if authMethod is email related
   */
  address: string;
  authMethod: EOADto.authMethod;
};

export namespace EOADto {
  export enum authMethod {
    EMAIL_AND_PASSWORD = 'emailAndPassword',
    GOOGLE = 'google',
    TWITTER = 'twitter',
    DISCORD = 'discord',
    WEB3AUTH = 'web3Auth',
  }
}
