import { GetTokenExplorerResponseDto } from '@/services/index';
import { ControlledSelect } from '../../atoms';
import { language } from './values';
import styles from '../styles.module.scss';
import typography from '@/styles/scss/typography.module.scss';

interface IStrategyFieldProps {
  strategies?: GetTokenExplorerResponseDto[];
}
const StrategyField = ({ strategies }: IStrategyFieldProps) => {
  return (
    <div>
      <div className={styles.col25}>
        <h5 className={typography.bodySmallRegular}>{language.en.title}</h5>
      </div>
      <div className={styles.col75}>
        <ControlledSelect
          required
          name="selectStrategy"
          options={
            strategies?.map((strategy) => ({
              label: strategy.name,
              id: strategy.id,
            })) || []
          }
          placeholder={language.en.placeholder}
        />
      </div>
    </div>
  );
};

export default StrategyField;
