/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { UserOperationDto } from '../models/UserOperationDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class PaymasterService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Attaches paymaster and data to user operation
   * @param requestBody
   * @returns UserOperationDto
   * @throws ApiError
   */
  public paymasterControllerAttachPaymaster(requestBody: UserOperationDto): CancelablePromise<UserOperationDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: 'userService/paymaster',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
}
