/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Invoice } from '../models/Invoice';
import type { InvoiceMapUpcomingDto } from '../models/InvoiceMapUpcomingDto';
import type { StripeInvoiceDto } from '../models/StripeInvoiceDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class InvoicesService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @param id
   * @returns any
   * @returns StripeInvoiceDto
   * @throws ApiError
   */
  public invoiceControllerPayInvoice(id: string): CancelablePromise<any | StripeInvoiceDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: 'billingCoordinator/invoices/pay/{id}',
      path: {
        id: id,
      },
    });
  }

  /**
   * @returns any
   * @returns Invoice
   * @throws ApiError
   */
  public invoiceControllerRetriveInvoices(): CancelablePromise<any | Array<Invoice>> {
    return this.httpRequest.request({
      method: 'GET',
      url: 'billingCoordinator/invoices',
    });
  }

  /**
   * @param priceId
   * @returns InvoiceMapUpcomingDto
   * @throws ApiError
   */
  public invoiceControllerRetrieveUpcoming(priceId: string): CancelablePromise<InvoiceMapUpcomingDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: 'billingCoordinator/invoices/upcoming',
      query: {
        price_id: priceId,
      },
    });
  }
}
