import styles from './TabList.module.scss';

const TabList = ({
  children,
  hasBottomBorder,
  className = '',
}: React.PropsWithChildren<{
  className?: string;
  hasBottomBorder?: boolean;
}>) => {
  return (
    <div
      aria-orientation="horizontal"
      className={`${styles.tablist} ${hasBottomBorder ? styles.hasBottomBorder : ''} ${className}`}
      role="tablist"
    >
      {children}
    </div>
  );
};

export default TabList;
