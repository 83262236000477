'use client';
import { FC } from 'react';
import { ISelectProps } from './types';
import styles from './SelectStyles.module.scss';

const Select: FC<ISelectProps> = ({
  autoFocus = false,
  className = '',
  disabled = false,
  hasError,
  height,
  id,
  label,
  name,
  onBlur,
  onChange,
  onFocus,
  options,
  placeholder,
  required,
  style,
  testid,
  value,
  width,
  ...props
}) => {
  return (
    <label className={`datalist-label ${styles.label} ${hasError && styles.error}`}>
      {label && <span className={`datalist-label-text ${styles.span}`}>{label}</span>}
      <select
        autoFocus={autoFocus}
        className={`select ${styles.select}  ${className}`}
        data-testid={testid}
        defaultValue={value}
        disabled={disabled}
        id={id}
        name={name || id + ' select'}
        required={required}
        style={{ ...style, height, width }}
        onBlur={(event: React.FocusEvent<HTMLSelectElement>) => onBlur && onBlur(event)}
        onChange={(event: React.ChangeEvent<HTMLSelectElement>) => onChange && onChange(event)}
        onFocus={(event: React.FocusEvent<HTMLSelectElement>) => onFocus && onFocus(event)}
        {...props}
      >
        {placeholder && (
          <option disabled selected value="">
            {placeholder}
          </option>
        )}
        {options?.map((option) => {
          return (
            <option
              disabled={option.disabled}
              id={option.id}
              key={option.id}
              selected={value === option.id}
              value={option.id}
            >
              {option.label}
            </option>
          );
        })}
      </select>
    </label>
  );
};

export default Select;
