/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type TapfiliateAffiliateDto = {
  affiliateProgram: TapfiliateAffiliateDto.affiliateProgram;
  affiliateId: string;
  affiliateReferral: string;
  trackingId: string;
};

export namespace TapfiliateAffiliateDto {
  export enum affiliateProgram {
    MORALIS_MONEY = 'moralis-money',
  }
}
