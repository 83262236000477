export const retrieveCookie = (cookieName: string) => {
  return document.cookie
    .split(';')
    .map((c) => {
      return c.trim();
    })
    .find((c) => {
      return c.startsWith(cookieName + '=');
    })
    ?.split('=')[1];
};

export const writeCookieValue = (cookieName: string, cookieValue: string | null, ttl: number) => {
  if (!cookieValue) {
    return;
  }
  const dateUTC = new Date(Date.now() + ttl).toUTCString();
  const encVal = encodeURIComponent(cookieValue);
  document.cookie = `${cookieName}=${encVal};path=/;expires=${dateUTC}`;
};
