import { AlertDto, CreateTokenAlertRequestDto, NotificationDto, UpdateTokenAlertRequestDto } from '@/services/index';
import useUserStore from '@/stores/userStore';
import { EProtocolType } from '@/types/validatedProtocol';
import { normalizeTelegramUsername } from '../../utils';
import { ITokenAlertCoin, ITokenAlertFormData } from './types';

interface IGetFormDefaultValues {
  alert?: AlertDto;
  notification?: NotificationDto;
  tokenDataInitial?: ITokenAlertCoin;
}

export const getFormDefaultValues = ({
  alert,
  notification,
  tokenDataInitial,
}: IGetFormDefaultValues): Partial<ITokenAlertFormData> => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { userEmail, userValidatedProtocolsTelegram } = useUserStore((state) => {
    return {
      userValidatedProtocolsTelegram: state.user?.validatedProtocols?.filter(
        (protocol) => protocol.type === EProtocolType.TELEGRAM,
      )[0],
      userEmail: state.user?.email,
    };
  });

  return {
    inputName: notification?.name || '',
    inputProtocolEmail: notification?.config?.email || userEmail || '',
    inputProtocolTelegram:
      normalizeTelegramUsername(notification?.config?.telegram || userValidatedProtocolsTelegram?.data[0]) || '',
    inputProtocolWebhook: notification?.config?.webhookUrl || '',
    inputValue: Number(alert?.thresholdValue),
    priceChangePercentage: Number((notification as { percentualChange?: number })?.percentualChange) || 0,
    selectCoolDown: alert?.intervalInSeconds || '0',
    selectFilter: alert?.filter || 'priceChange',
    selectOperator: alert?.operator || 'gt',
    selectTimeframe: alert?.timeframe || '1440',
    selectToken:
      tokenDataInitial?.address && tokenDataInitial?.chainId
        ? {
            address: tokenDataInitial?.address,
            chainId: tokenDataInitial?.chainId,
          }
        : undefined,
    toggleProtocolEmail: Boolean(notification?.config?.email),
    toggleProtocolTelegram: Boolean(notification?.config?.telegram),
    toggleProtocolWebhook: Boolean(notification?.config?.webhookUrl),
  };
};

interface IGetPayload {
  alert?: AlertDto;
  formData: ITokenAlertFormData;
  tokenData?: ITokenAlertCoin;
}

export const getPayload = ({
  alert,
  formData,
  tokenData,
}: IGetPayload): UpdateTokenAlertRequestDto | CreateTokenAlertRequestDto => {
  if (formData.selectFilter === 'priceChange') {
    if (tokenData?.price === undefined) {
      throw new Error('Was unable to fetch latest token data. Please try again.');
    }
    formData.selectTimeframe = '1440';
  }

  const config = {
    email: formData.toggleProtocolEmail ? formData.inputProtocolEmail : undefined,
    webhookUrl: formData.toggleProtocolWebhook ? formData.inputProtocolWebhook : undefined,
    telegram: formData.toggleProtocolTelegram ? formData.inputProtocolTelegram : undefined,
    serviceIssuer: 'MoralisMoney',
  };

  const commonPayload = {
    chainId: formData.selectToken.chainId,
    config,
    filter: formData.selectFilter,
    intervalInSeconds: formData.selectCoolDown,
    name: formData.inputName,
    operator: formData.selectOperator,
    thresholdValue: formData.inputValue.toString(),
    timeframe: formData.selectTimeframe,
    percentualChange: formData?.priceChangePercentage ? formData.priceChangePercentage.toString() : undefined,
    tokenName: tokenData?.name,
    topic: 'tempTopic',
    enabled: true,
    tokenAddress: formData.selectToken.address,
  };

  if (alert?.alertId) {
    return {
      ...commonPayload,
      alertId: alert.alertId,
    } as UpdateTokenAlertRequestDto;
  }
  return commonPayload as CreateTokenAlertRequestDto;
};
