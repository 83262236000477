import { TSelectOption } from '@/components/core/Select';

/**
 *
 * @param allNotificationPeriods All available notification periods for all plans
 * @param allowedNotificationPeriods Allowed notification periods for the user e.g. according to the user's plan
 */
export const getNotificationPeriodOptions = (
  allNotificationPeriods: TSelectOption[],
  allowedNotificationPeriods?: number[],
): TSelectOption[] => {
  return allNotificationPeriods.map((period) => {
    if (allowedNotificationPeriods && !allowedNotificationPeriods?.includes(Number(period.id))) {
      return {
        ...period,
        disabled: true,
      };
    }
    return period;
  });
};
