/**
 * Custom error class for errors
 * that can be displayed to the user
 * via `useNotify` hook
 */
export class DisplayableError extends Error {
  constructor(public title: string, public message: string) {
    super(message);
    this.name = 'DisplayableError';
  }
}
