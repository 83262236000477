import localforage from 'localforage';

let state: 'init' | 'ok' | 'fail' = 'init';
const init = async () => {
  await localforage.ready().catch(() => {
    // (Maxim) TODO: fallback (WeakMap) for SSR?
    state = 'fail';
  });
  state = 'ok';
};

const ready = async <T>(fn: () => Promise<T | null>): Promise<T | null> => {
  if (state === 'fail') {
    return null;
  }
  if (state === 'ok') {
    return fn();
  }
  await init();
  return ready(fn);
};

const isStale = (staleAt: number) => Date.now() >= staleAt;
const isTempItem = <T>(item: unknown): item is TempItem<T> => {
  if (typeof item !== 'object' || item === null) {
    return false;
  }
  return 'staleAt' in item && 'ttl' in item && 'value' in item;
};
export interface TempItem<T> {
  staleAt: number;
  ttl: number;
  value: T;
}

export const asyncStorage = {
  getItem: <T>(key: string) => ready(() => localforage.getItem<T>(key)),
  setItem: <T>(key: string, value: T) => ready(() => localforage.setItem<T>(key, value)),
  removeItem: (key: string) => ready(() => localforage.removeItem(key)),
  keys: () => ready(() => localforage.keys()),
};

const removeKeyIfStale = async (key: string, item: unknown): Promise<void> => {
  if (isTempItem(item) && isStale(item.staleAt)) {
    asyncStorage.removeItem(key);
  }
};

const cleanUpKey = async <T>(key: string): Promise<void> => {
  const item = await asyncStorage.getItem<T>(key);
  removeKeyIfStale(key, item);
};

export const asyncStorageWithTTL = {
  isTempItem,
  cleanUp: async (): Promise<void> => {
    const keys = await asyncStorage.keys();
    if (!Array.isArray(keys)) {
      return;
    }
    await Promise.all(keys.map(cleanUpKey));
  },
  create: <T>(ttl: number) => ({
    setItem: (key: string, value: T) =>
      asyncStorage.setItem(key, {
        value,
        ttl,
        staleAt: Date.now() + ttl,
      }),
    getItem: async (key: string) => {
      const cItem = await asyncStorage.getItem<TempItem<T>>(key);
      removeKeyIfStale(key, cItem);
      return isTempItem(cItem) && !isStale(cItem.staleAt) ? cItem.value : null;
    },
  }),
};
