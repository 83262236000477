/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { StripeSetupIntentDto } from '../models/StripeSetupIntentDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class SetupIntentsService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @returns StripeSetupIntentDto
   * @throws ApiError
   */
  public setupIntentControllerCreate(): CancelablePromise<StripeSetupIntentDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: 'billingCoordinator/setup-intents',
    });
  }
}
