/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { TimeFrameDto } from './TimeFrameDto';

export type SortingDto = {
  filterName: SortingDto.filterName;
  timeFrame?: TimeFrameDto;
  orderBy: SortingDto.orderBy;
  limit: number;
};

export namespace SortingDto {
  export enum filterName {
    PRICE_PERCENT_CHANGE_USD = 'pricePercentChangeUSD',
    VOLUME_CHANGE_USD = 'volumeChangeUSD',
    BUYERS_VOLUME_CHANGE_USD = 'buyersVolumeChangeUSD',
    SELLERS_VOLUME_CHANGE_USD = 'sellersVolumeChangeUSD',
    NET_VOLUME_CHANGE_USD = 'netVolumeChangeUSD',
    EXPERIENCED_BUYERS_CHANGE = 'experiencedBuyersChange',
    HOLDERS_CHANGE = 'holdersChange',
    LIQUIDITY_CHANGE = 'liquidityChange',
    LIQUIDITY_CHANGE_USD = 'liquidityChangeUSD',
    BUYERS_CHANGE = 'buyersChange',
    SELLERS_CHANGE = 'sellersChange',
    EXPERIENCED_SELLERS_CHANGE = 'experiencedSellersChange',
    NET_BUYERS_CHANGE = 'netBuyersChange',
    EXPERIENCED_NET_BUYERS_CHANGE = 'experiencedNetBuyersChange',
    MARKET_CAP = 'marketCap',
    FULLY_DILUTED_VALUATION = 'fullyDilutedValuation',
    TOTAL_LIQUIDITY_LOCKED_IN_PERCENT = 'totalLiquidityLockedInPercent',
    TOTAL_SUPPLY_LOCKED_IN_PERCENT = 'totalSupplyLockedInPercent',
    BLOCK_TIMESTAMP = 'blockTimestamp',
    DEXT_SCORE = 'dextScore',
    TWITTER_FOLLOWER = 'twitterFollower',
    RATING = 'rating',
    TOTAL_NUMBER_OF_RATING = 'totalNumberOfRating',
    TWITTER_CREATED_AT = 'twitterCreatedAt',
    MORALIS_SCORE = 'moralisScore',
    VERIFICATION_EXPIRES_AT = 'verificationExpiresAt',
    NUMBER_OF_RATINGS_CHANGE = 'numberOfRatingsChange',
    ON_CHAIN_STRENGTH_INDEX = 'onChainStrengthIndex',
  }

  export enum orderBy {
    ASC = 'ASC',
    DESC = 'DESC',
  }
}
