import { useEffect } from 'react';
import { isNullish } from '@/utils/isNullish';

const useOnClickOutside = (
  ref: React.RefObject<HTMLDivElement>,
  handler?: (event: Event) => void,
  excludedId?: string,
) => {
  useEffect(() => {
    const listener = (event: Event) => {
      const target = event.target as HTMLElement;
      if (isNullish(handler)) {
        return;
      }
      // Do nothing if clicking inside ref's element or its descendant elements
      if (ref.current && ref.current.contains(target)) {
        return;
      }

      // Do nothing if clicking the excluded element or its descendants
      const excludedElement = document.getElementById(excludedId || '');
      if (excludedElement && excludedElement.contains(target)) {
        return;
      }

      // Call the handler if click is outside both the ref and excluded element
      handler(event);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler, excludedId]);
};

export default useOnClickOutside;
